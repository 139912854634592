import React from "react";
import ReactPlayer from "react-player/lazy";
import CVPUserGuide from "../../document/CVP_2.0_User_Guide.pdf";
import CustomVendorPortal from "../../videos/Custom_Vendor_Portal.mp4";

const TourPage: React.FC = () => {
  return (
    <div
      id="tourContainer"
      className="container"
      style={{ paddingTop: 40, paddingBottom: 40 }}
    >
      <div id="videoCVP">
        <h2>Custom Vendor Portal</h2>
        <ReactPlayer url={CustomVendorPortal} playing controls={true} />
        <br />
      </div>

      <div id="userGuideCVP">
        <h2>CVP 2.0 User Guide</h2>
        <a href={CVPUserGuide} target="_blank">
          User Guide
        </a>
      </div>
    </div>
  );
};
export default TourPage;
