import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Box, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import {
  deleteService,
  getJsonResult,
  getResult,
  post,
  put,
} from "../../services/FetchServices";
import AdditionalContactDetails from "../../types/AdditionalContactDetails";
import Location from "../../types/Location";
import SelectedAddressDetails from "../../types/SelectedAddressDetails";
import AddressPrompt from "../AddressPrompt";
import { DropDown } from "../dropDown/DropDown";
import AdditionalContacts from "../newLocation/AdditionalContacts";
import LocationAvailability from "./LocationAvailability";
import { FlagableTextField, focusOnField } from "../purchaseOrderRelease/FlagableTextField";
import { hasInvalidEmail, hasInvalidName, hasInvalidPhone, hasTooManyActiveWarehouseContacts, hasValidFieldsInAdditionalContacts, isActiveWarehouseContact, isValidEmail } from "../../helper/ContactValidationUtilities";
import { FlagableButton, focusOnButton } from "../purchaseOrderRelease/FlagableButton";

interface IState {
  location?: Location;
  searchLocation?: Location;
}

const NewLocationPage: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  let contactTypes = ["RELEASE_SUMMARY", "TENDER", "WAREHOUSE"];
  const loc = (location?.state as IState)?.location;
  const searchLocation = (location?.state as IState)?.searchLocation;
  var selectedAddressDetails = new SelectedAddressDetails("", "", "", "");
  const [addressDetails, setAddressDetails] = useState<SelectedAddressDetails>(
    new SelectedAddressDetails("", "", "", "")
  );
  const [additionalContactsList, setAdditionalContactsList] = useState<
    AdditionalContactDetails[]
  >([]);
  var isValidateButtonEnabled = false;
  var isCreateLocationButtonEnabled = false;
  var isUpdateLocationButtonEnabled = false;
  const [isAllContactTypesAreSelected, setIsAllContactTypesAreSelected] =
    useState(false);
  const [isAllContactTypesAreActive, setIsAllContactTypesAreActive] =
    useState(false);
  const [locationName, setLocationName] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [contactName, setContactName] = useState("");
  const [open, setOpen] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [remark, setRemark] = useState("");
  const [refNumVal, setRefNumVal] = useState<string>("");
  const [locAvailability, setLocAvailability] = useState<string>(
    loc ? loc.locationAvailability : ""
  );
  const [additionalAddress, setAdditionalAddress] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [countryCodes, setCountryCodes] = useState([]);
  const [calenderIds, setCalenderIds] = useState([]);
  const [countryCode, setCountryCode] = useState("USA");
  const [calenderId, setCalenderId] = useState("VENDOR_WEEKDAY_8TO5");
  const [validatedAddrResult, setValidatedAddrResult] = useState("");
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [showValidatedAddressPrompt, setShowValidatedAddressPrompt] =
    useState(false);
  const [showInputText, setShowInputText] = useState(true);

  const [isFetchingData, setIsFetchingData] = useState(false);
  const [isAddressValidated, setAddressValidation] = useState(true);
  const [isAdditionalAddress, setIsAdditionalAddress] = useState(true);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  const [isValidRemarks, setIsValidRemarks] = useState(true);
  const [openAlert, setAlertOpen] = useState(false);
  const [openFailureAlert, setFailureAlertOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");
  const [validationAlert, setValidationAlert] = useState(false);

  var username = localStorage.getItem("userName");
  var authHeader = localStorage.getItem("authHeader");
  var calenderIdUrl =
    `${process.env.REACT_APP_BACKEND_URL}/newLocation/getCalenderIds?authHeader=` +
    authHeader;
  var countryCodesUrl =
    `${process.env.REACT_APP_BACKEND_URL}/newLocation/getCountryCodes?authHeader=` +
    authHeader;

  const validateAddress = () => {
    setIsFetchingData(true);

    var validateAddressUrl = `${
      process.env.REACT_APP_BACKEND_URL
    }/validate-address?address=${
      address + "+" + postalCode + "+" + countryCode
    }`;
    getJsonResult(validateAddressUrl)
      .then((response) => response.json())
      .then((data) => {
        const results = data.results;
        setValidatedAddrResult(
          data.status === "ZERO_RESULTS"
            ? "No Results"
            : results[0]?.formatted_address
        );
        var isValidAddress = false;
        if (data.status !== "ZERO_RESULTS") {
          var addressComponent = results[0]?.address_components;
          var addressComponentSize = addressComponent.length;
          for (let index = 0; index < addressComponentSize; index++) {
            if (addressComponent[index].types[0] === "street_number") {
              isValidAddress = true;
              selectedAddressDetails.streetAddress =
                addressComponent[index].short_name;
            }
            if (addressComponent[index].types[0] === "route") {
              selectedAddressDetails.streetAddress +=
                " " + addressComponent[index].short_name;
            }
            if (addressComponent[index].types[0] === "locality") {
              selectedAddressDetails.city = addressComponent[index].short_name;
            }
            if (
              addressComponent[index].types[0] === "administrative_area_level_1"
            ) {
              selectedAddressDetails.provinceCode =
                addressComponent[index].short_name;
            }
            if (addressComponent[index].types[0] === "postal_code") {
              selectedAddressDetails.postalCode =
                addressComponent[index].short_name;
            }
            if (addressComponent[index].types[0] === "country") {
              var country = addressComponent[index].short_name;
              if (country === "CA") {
                setCountryCode("CAN");
              }
            }
          }
        }
        if (isValidAddress) {
          setAddressDetails(selectedAddressDetails);

          setShowValidatedAddressPrompt(true);
          setTimeout(() => setIsFetchingData(false), 1000);
        } else {
          setIsFetchingData(false);
          setDialogContent("Please enter the valid address!!!");
          setDialogTitle("Validation error!");
          setValidationAlert(true);
        }
      })
      .catch((err) => console.log(err));
  };

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const closeAlertDialog = () => {
    setAlertOpen(false);
    history.push("/findLocation");
  };

  const closeValidationAlert = () => {
    setValidationAlert(false);
  };

  const createOrUpdateLocation = (flag: string) => {
    let domain = username?.substring(0, 2);
    var locationXid = "";
    var locationGid = "";
    if (flag === "create") {
      let locationId = hashCode(vendorId + postalCode + address).toString();
      locationXid = locationId.toString();
      locationGid = domain + "." + locationId;
    } else if (flag === "update") {
      locationXid = loc?.locationXId!;
      locationGid = loc?.locationGId!;
    }

    let operation = "IU";
    let deleteOperation = "CD";
    let primaryContactNames = contactName.split(" ");
    let primaryContact = {
      transactionCode: operation,
      primaryContactBeanData: {
        contactGid: locationGid,
        contactXid: locationXid,
        firstName: primaryContactNames[0].toUpperCase(),
        lastName: primaryContactNames[1]
          ? primaryContactNames[1].toUpperCase()
          : "",
        emailAddress: emailAddress.toUpperCase(),
        phone1: phoneNumber,
        isPrimaryContact: true,
        contactType: "PERSON",
        locationGid: locationGid,
        domainName: domain,
        contactComMethod: [
          {
            transactionCode: operation,
            contactComMethodBeanData: {
              contactGid: locationGid,
              comMethodRank: 1,
              comMethodGid: "EMAIL",
              domainName: domain,
            },
          },
        ],
      },
    };

    let contacts = [];
    contacts.push(primaryContact);
    additionalContactsList.forEach((contact) => {
      let contactNames = contact.contactName.split(" ");
      contacts.push({
        transactionCode: contact.operation,
        primaryContactBeanData: {
          contactGid: domain + "." + contact.contactXid,
          contactXid: contact.contactXid,
          firstName: contactNames[0].toUpperCase(),
          lastName: contactNames[1] ? contactNames[1].toUpperCase() : "",
          jobTitle: contact.jobTitle.toUpperCase(),
          emailAddress: contact.emailAddress.toUpperCase(),
          phone1: contact.phone1,
          isPrimaryContact: false,
          contactType: "PERSON",
          locationGid: locationGid,
          isNotificationOn: contact.active,
          domainName: domain,
        },
      });
    });

    let primaryAddress = {
      transactionCode: operation,
      locationAddressBeanData: {
        locationGid: locationGid,
        lineSequence: 1,
        addressLine: address.toUpperCase(),
        domainName: domain,
      },
    };

    let locationAddress = [];
    locationAddress.push(primaryAddress);
    if (additionalAddress !== "") {
      let secondaryAddress = {
        transactionCode: operation,
        locationAddressBeanData: {
          locationGid: locationGid,
          lineSequence: 2,
          addressLine: additionalAddress ? additionalAddress.toUpperCase() : "",
          domainName: domain,
        },
      };
      locationAddress.push(secondaryAddress);
    }

    let locationRoleProfile = [
      {
        transactionCode: operation,
        locationRoleProfileBeanData: {
          locationGid: locationGid,
          locationRoleGid: "SHIPFROM/SHIPTO",
          calendarGid: domain + "." + calenderId,
          domainName: domain,
        },
      },
    ];

    let locationRemark = [
      {
        transactionCode: operation,
        locationRemarkBeanData: {
          locationGid: locationGid,
          remarkSequence: 1,
          remarkQualGid: domain + ".REMARK",
          remarkText: remark ? remark.toUpperCase() : "",
          domainName: domain,
        },
      },
    ];

    let locationRefnum = [];
    if (locAvailability) {
      let previousAvailability = {
        transactionCode: deleteOperation,
        locationRefnumBeanData: {
          locationGid: locationGid,
          locationRefnumQualGid: domain + ".VENDOR_HOURS",
          locationRefnumValue: locAvailability,
          domainName: domain,
        },
      };
      locationRefnum.push(previousAvailability);
    }
    let currentAvailability = {
      transactionCode: operation,
      locationRefnumBeanData: {
        locationGid: locationGid,
        locationRefnumQualGid: domain + ".VENDOR_HOURS",
        locationRefnumValue: refNumVal ? refNumVal : "00:00-00:00",
        domainName: domain,
      },
    };
    locationRefnum.push(currentAvailability);

    const updateDate = {
      date: loc?.updateDate,
    };

    let createLocationJson = {
      Location: {
        locationGid: locationGid,
        locationXid: locationXid,
        locationName: locationName.toUpperCase(),
        city: selectedCity.toUpperCase(),
        provinceCode: selectedState,
        postalCode: postalCode,
        countryCode3Gid: countryCode,
        attribute1: vendorId,
        primaryAddressLineSeq: 1,
        isActive: isActive,
        domainName: domain,
        primaryContact: contacts,
        locationRefnum: locationRefnum,
        locationAddress: address ? locationAddress : [],
        locationRoleProfile: calenderId ? locationRoleProfile : [],
        locationRemark: remark ? locationRemark : [],
      },
    };

    let updateLocationJson = {
      Location: {
        locationGid: locationGid,
        locationXid: locationXid,
        locationName: locationName.toUpperCase(),
        city: selectedCity.toUpperCase(),
        provinceCode: selectedState,
        postalCode: postalCode,
        countryCode3Gid: countryCode,
        attribute1: vendorId,
        primaryAddressLineSeq: 1,
        isActive: isActive,
        domainName: domain,
        primaryContact: contacts,
        locationRefnum: locationRefnum,
        locationAddress: address ? locationAddress : [],
        locationRoleProfile: calenderId ? locationRoleProfile : [],
        locationRemark: remark ? locationRemark : [],
        updateDate: updateDate,
      },
    };

    validateContactTypesSelected();
    validateContactTypesActive();
    if (!isAllContactTypesAreSelected || !isAllContactTypesAreActive) return;
    if (flag === "create") {
      var createLocationUrl =
        `${process.env.REACT_APP_BACKEND_URL}/findLocation/save?authHeader=` +
        authHeader;
      put(createLocationUrl, createLocationJson)
        .then((response) => {
          if (response.status >= 200 && response.status <= 299) {
            return response.text();
          } else {
            throw response;
          }
        })
        .then((data) => {
          setDialogContent("Location successfully created");
          setDialogTitle("Success!");
          setAlertOpen(true);
        })
        .catch((error) => {
          error.text().then((errMessage: any) => {
            setDialogContent("Error when creating location:\n" + errMessage);
          });
          setDialogTitle("Error!");
          setAlertOpen(true);
        });
    } else if (flag === "update") {
      var updateLocationUrl =
        `${process.env.REACT_APP_BACKEND_URL}/findLocation/update?authHeader=` +
        authHeader +
        "&locationGId=" +
        locationGid;
      post(
        updateLocationUrl,
        loc?.updateDate ? updateLocationJson : createLocationJson
      )
        .then((response) => {
          if (response.status >= 200 && response.status <= 299) {
            return response.text();
          } else {
            throw response;
          }
        })
        .then((data) => {
          setDialogContent("Location successfully updated");
          setDialogTitle("Success!");
          setAlertOpen(true);
        })
        .catch((error) => {
          error.text().then((errMessage: any) => {
            setDialogContent("Error when updating location:\n" + errMessage);
          });
          setDialogTitle("Error!");
          setAlertOpen(true);
        });
    }
  };

  const deleteLocation = () => {
    closeDialog();
    var deleteLocationUrl =
      `${process.env.REACT_APP_BACKEND_URL}/findLocation/deleteLocation?authHeader=` +
      authHeader +
      "&locationGId=" +
      loc?.locationGId!;
    deleteService(deleteLocationUrl)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          return response.text();
        } else {
          throw response;
        }
      })
      .then((data) => {
        setDialogContent("Location successfully deleted");
        setDialogTitle("Success!");
        setAlertOpen(true);
      })
      .catch((error) => {
        error.text().then((errMessage: any) => {
          setAlertOpen(true);
          setDialogContent(
            "This location is tied to an order release and cannot be deleted."
          );
          setDialogTitle("Error!");
        });
      });
  };

  function hashCode(myString: string) {
    var encode = require("hashcode").hashCode;
    var hash = encode().value(myString);
    if (hash < 0) {
      hash = hash * -1;
    }
    return hash;
  }

  const handleStreetAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newAddress = e.target.value.replace(
      /[^a-zA-Z0-9-,.;_=+()!@#$%{}[\]|/\\^\s)]/g,
      ""
    );
    setAddress(newAddress);
    if (newAddress.length > 55) {
      setAddressValidation(false);
    } else {
      setAddressValidation(true);
    }
  };

  const handlePostalCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPostalCode(
      e.target.value.replace(/[^a-zA-Z0-9-,.;_=+()!@#$%{}[\]|/\\^\s]/g, "")
    );
  };

  const handleLocationName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocationName(
      e.target.value.replace(/[^a-zA-Z0-9-,.;_=+()!@#$%{}[\]|/\\^\s]/g, "")
    );
  };

  const handleIsActive = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsActive(e.target.checked);
  };

  const handleContactName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContactName(
      e.target.value.replace(/[^a-zA-Z0-9-,.;_=+()!@#$%{'}[\]|/\\^\s]/g, "")
    );
  };

  const handleEmailAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailAddress(e.target.value);
  };

  const handlePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPhoneNumber = e.target.value.replace(/[^0-9]/g, "");
    setPhoneNumber(newPhoneNumber);
    if (
      newPhoneNumber.trim().length === 0 ||
      newPhoneNumber.trim().length === 10
    ) {
      setIsValidPhoneNumber(true);
    } else {
      setIsValidPhoneNumber(false);
    }
  };

  const handleCalenderId = (e: any) => {
    setCalenderId(e.target.value);
  };

  const handleRemark = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newRemark = e.target.value.replace(
      /[^a-zA-Z0-9-,.;_=+()!@#$%{'}[\]|/\\^\s]/g,
      ""
    );
    setRemark(newRemark);
    if (newRemark.length > 80) {
      setIsValidRemarks(false);
    } else {
      setIsValidRemarks(true);
    }
  };

  const handleAdditionalAddress = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newAdditionalAddress = e.target.value.replace(
      /[^a-zA-Z0-9-,.;_=+()!@#$%{'}[\]|/\\^\s]/g,
      ""
    );
    setAdditionalAddress(newAdditionalAddress);
    if (newAdditionalAddress.length > 55) {
      setIsAdditionalAddress(false);
    } else {
      setIsAdditionalAddress(true);
    }
  };

  const togglingPrompt = () => {
    setShowValidatedAddressPrompt(!showValidatedAddressPrompt);
  };

  const onSelectedAddress = () => {
    setShowValidatedAddressPrompt(!showValidatedAddressPrompt);
    setAddress(addressDetails.streetAddress);
    setShowInputText(addressDetails.city.length > 0 ? false : true);
    setPostalCode(addressDetails.postalCode);
    setSelectedState(addressDetails.provinceCode);
    setSelectedCity(addressDetails.city);
    setAddressValidation(true);
  };

  const updateAdditionalContactList = (list: AdditionalContactDetails[]) => {
    setAdditionalContactsList(list);
    validateContactTypesSelected();
    validateContactTypesActive();
  };

  const updateRefNumVal = (refNumVal: string) => {
    setRefNumVal(refNumVal);
  };

  useEffect(() => {
    setVendorId(localStorage.getItem("vendorId")!);
    getResult(countryCodesUrl)
      .then((response) => response.json())
      .then((data) => setCountryCodes(data));
    getResult(calenderIdUrl)
      .then((response) => response.json())
      .then((data) => setCalenderIds(data.map((e: string) => e.split(".")[1])));
  }, []);

  useEffect(() => {
    if (loc !== undefined) {
      setAddress(loc?.streetAddress);
      setPostalCode(loc?.postalCode);
      setSelectedState(loc?.provinceCode);
      setSelectedCity(loc?.city);
      setLocationName(loc?.locationName);
      setIsActive(loc?.isActive);
      setContactName(loc?.contactName);
      setPhoneNumber(loc?.phoneNumber);
      setEmailAddress(loc?.emailAddress);
      setRemark(loc?.remark);
      setAdditionalAddress(loc?.additionalAddress);
      setAdditionalContactsList(loc?.primaryContact);
      setLocAvailability(loc?.locationAvailability);
      setCountryCode(loc?.countryCode3GId ? loc?.countryCode3GId : "USA");
    }
  }, [loc]);

  isValidateButtonEnabled = validateMandatoryFields();
  function validateMandatoryFields() {
    if (address && postalCode && isAddressValidated && showInputText) {
      return true;
    }
    return false;
  }

  isCreateLocationButtonEnabled = validateAllMandatoryFields();
  function validateAllMandatoryFields() {
    if (
      address &&
      locationName &&
      contactName &&
      emailAddress &&
      isValidEmail(emailAddress) &&
      phoneNumber &&
      isValidPhoneNumber &&
      isAllContactTypesAreSelected &&
      isAllContactTypesAreActive &&
      isValidRemarks &&
      selectedCity &&
      selectedState &&
      
      isAddressValidated &&
      isAdditionalAddress &&
      
      hasValidFieldsInAdditionalContacts(additionalContactsList)
    ) {
      return true;
    }
    return false;
  }

  isUpdateLocationButtonEnabled = validateUpdateAllMandatoryFields();
  function validateUpdateAllMandatoryFields() {
    if (
      address &&
      locationName &&
      contactName &&
      emailAddress &&
      isValidEmail(emailAddress) &&
      phoneNumber &&
      isValidPhoneNumber &&
      isAllContactTypesAreSelected &&
      isAllContactTypesAreActive &&
      isValidRemarks &&
      selectedCity &&
      selectedState &&
      hasValidFieldsInAdditionalContacts(additionalContactsList)
    ) {
      return true;
    }
    return false;
  }

  const validateContactTypesSelected = () => {
    let contactTypesMatched = false;
    if (
      additionalContactsList != null &&
      additionalContactsList.some(
        (c) => c.jobTitle === contactTypes[0] && c.operation !== "CD"
      ) &&
      additionalContactsList.some(
        (c) => c.jobTitle === contactTypes[1] && c.operation !== "CD"
      ) &&
      additionalContactsList.some(
        (c) => c.jobTitle === contactTypes[2] && c.operation !== "CD"
      )
    ) {
      contactTypesMatched = true;
    }
    setIsAllContactTypesAreSelected(contactTypesMatched);
  };

  const validateContactTypesActive = () => {
    let contactTypesMatched = false;
    if (
      additionalContactsList != null &&
      additionalContactsList.some(
        (c) =>
          c.jobTitle === contactTypes[0] && c.operation !== "CD" && c.active
      ) &&
      additionalContactsList.some(
        (c) =>
          c.jobTitle === contactTypes[1] && c.operation !== "CD" && c.active
      ) &&
      additionalContactsList.some(
        (c) =>
          c.jobTitle === contactTypes[2] && c.operation !== "CD" && c.active
      )
    ) {
      contactTypesMatched = true;
    }
    setIsAllContactTypesAreActive(contactTypesMatched);
  };

  const cancel = () => {
    history.push({
      pathname: "/findLocation",
      state: { searchLocation: searchLocation },
    });
  };

  const goToNextInvalidField = () => {
    if ( ! (locationName)) {
      focusOnField("locationNameInput");
    }
    else if ( !(contactName) ) {
      focusOnField("contactNameInput");
    }
    else if ( !(emailAddress) ) {
      focusOnField("emailAddressInput");
    }
    else if ( !(phoneNumber) ) {
      focusOnField("phoneNumberInput");
    }
    else if ( !(address) ) {
      focusOnField("streetAddressInput");
    }
    else if ( !(postalCode) ) {
      focusOnField("postalcodeInput");
    }
    else if ( isValidateButtonEnabled ) {
      focusOnButton("validateAddressButton");
    }
    else if ( additionalContactsList?.some(hasInvalidName) ) {
      focusOnField("contactName" + additionalContactsList?.find(hasInvalidName)?.contactXid);
    }
    else if ( additionalContactsList?.some(hasInvalidEmail) ) {
      focusOnField("emailAddress" + additionalContactsList?.find(hasInvalidEmail)?.contactXid);
    }
    else if ( additionalContactsList?.some(hasInvalidPhone) ) {
      focusOnField("phoneNumber" + additionalContactsList?.find(hasInvalidPhone)?.contactXid);
    }
    else if ( hasTooManyActiveWarehouseContacts(additionalContactsList)) {
        additionalContactsList.filter(isActiveWarehouseContact).forEach(additionalContact => {
            focusOnField("contactName" + additionalContact.contactXid, true);
        })
    }
  }

  return (
    <div id="newLocationPage">
      <section className="page-section clearfix">
        <div className="container">
          <div
            className="intro"
            style={{ width: "80%", marginRight: "auto", marginLeft: "auto" }}
          >
            <div id="locationManager" className="section-heading mb-4">
              <span
                className="section-heading-lower"
                style={{
                  fontSize: "2rem",
                  textAlign: "center",
                  color: "#6b3a06",
                  fontWeight: 500,
                  paddingBottom: "50px",
                }}
              >
                LOCATION MANAGER
              </span>
              <form>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "50%" }}>
                    <div className="form-group py-2">
                      <label id="vendoridLabel" className="form-label">
                        Corporation ID
                      </label>
                      <TextField
                        className="form-control"
                        id="vendorIdLabel"
                        value={vendorId}
                        variant="outlined"
                        disabled
                      />
                    </div>
                    <div className="form-group py-2">
                      <label id="locationIdLabel" className="form-label">
                        Location ID
                      </label>
                      <TextField
                        className="form-control"
                        value={loc?.locationXId}
                        variant="outlined"
                        disabled
                      />
                    </div>
                    <div className="form-group py-2">
                      <label id="locationNameLabel" className="form-label">
                        * Location Name
                      </label>
                      <FlagableTextField
                        id="locationNameInput"
                        variant="outlined"
                        className="form-control"
                        onChange={handleLocationName}
                        value={locationName}
                        required
                      />
                      {loc !== undefined && (
                        <span
                          className="form-check form-switch"
                          style={{ color: isActive ? "green" : "gray" }}
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="isActive"
                            checked={isActive}
                            onChange={handleIsActive}
                          />
                          {isActive ? "Active" : "Inactive"}
                        </span>
                      )}
                    </div>
                    <div className="form-group py-2">
                      <label id="whContactNameLabel" className="form-label">
                        * Warehouse Contact Name
                      </label>
                      <FlagableTextField
                        id="contactNameInput"
                        variant="outlined"
                        className="form-control"
                        onChange={handleContactName}
                        value={contactName}
                        required
                      />
                    </div>
                    <div className="form-group py-2">
                      <label id="emailAddressLabel" className="form-label">
                        * Email Address
                      </label>
                      <FlagableTextField
                        id="emailAddressInput"
                        variant="outlined"
                        className="form-control"
                        onChange={handleEmailAddress}
                        value={emailAddress}
                        required
                      />
                      {emailAddress.trim().length > 0 && !isValidEmail(emailAddress) && (
                        <span
                          id="validateEmailMessage"
                          style={{ fontWeight: "bold", color: "red" }}
                        >
                          Email is not valid.
                        </span>
                      )}
                    </div>
                    <div className="form-group py-2">
                      <label id="phoneNumberLabel" className="form-label">
                        * Phone Number
                      </label>
                      <FlagableTextField
                        id="phoneNumberInput"
                        variant="outlined"
                        className="form-control"
                        onChange={handlePhoneNumber}
                        value={phoneNumber}
                        required
                      />
                      {!isValidPhoneNumber && (
                        <span style={{ fontWeight: "bold", color: "red" }}>
                          Phone number is 10 digits.
                        </span>
                      )}
                    </div>
                    <div className="form-group py-2">
                      <label id="remarksLabel" className="form-label">
                        Default Shipping Origin Remarks
                      </label>
                      <TextField
                        id="remarksInput"
                        className="form-control"
                        value={remark}
                        onChange={handleRemark}
                        minRows={3}
                        multiline
                      />
                      {!isValidRemarks && (
                        <span style={{ fontWeight: "bold", color: "red" }}>
                          Maximum is 80 characters in remarks field.
                        </span>
                      )}
                    </div>
                  </div>
                  <div style={{ width: "50%", marginLeft: "75px" }}>
                    <div className="form-group py-2">
                      <label id="calenderIdLabel" className="form-label">
                        Calender Id
                      </label>
                      <DropDown
                        codes={calenderIds}
                        className="form-control"
                        elementId="calenderId"
                        value={calenderId}
                        onChange={handleCalenderId}
                      />
                    </div>
                    <div className="form-group py-2">
                      <label id="streetAddressLabel" className="form-label">
                        * Street Address
                      </label>
                      {showInputText && loc === undefined ? (
                        <FlagableTextField
                          id="streetAddressInput"
                          className="form-control"
                          onChange={handleStreetAddress}
                          value={address}
                          variant="outlined"
                          required
                        />
                      ) : (
                        <FlagableTextField
                          id="streetAddressInput"
                          className="form-control"
                          onChange={handleStreetAddress}
                          value={address}
                          variant="outlined"
                          disabled
                        />
                      )}
                      {!isAddressValidated && (
                        <span style={{ fontWeight: "bold", color: "red" }}>
                          Maximum is 55 characters in Street Address field.
                        </span>
                      )}
                    </div>
                    <div className="form-group py-2">
                      <label id="cityLabel" className="form-label">
                        * City
                      </label>
                      <TextField
                        id="cityValue"
                        className="form-control"
                        value={selectedCity}
                        variant="outlined"
                        disabled
                      />
                    </div>
                    <div className="form-group py-2">
                      <label id="stateLabel" className="form-label">
                        * State/Province
                      </label>
                      <TextField
                        id="stateValue"
                        className="form-control"
                        value={selectedState}
                        variant="outlined"
                        disabled
                      />
                    </div>
                    <div className="form-group py-2">
                      <label id="postalcodeLabel" className="form-label">
                        * Postal Code
                      </label>
                      {showInputText && loc === undefined ? (
                        <FlagableTextField
                          id="postalcodeInput"
                          className="form-control"
                          onChange={handlePostalCode}
                          value={postalCode}
                          variant="outlined"
                          required
                        />
                      ) : (
                        <FlagableTextField
                          id="postalcodeInput"
                          className="form-control"
                          onChange={handlePostalCode}
                          value={postalCode}
                          variant="outlined"
                          disabled
                        />
                      )}
                    </div>
                    <div className="form-group py-2">
                      <label id="countyCodeLabel" className="form-label">
                        Country Code
                      </label>
                      <DropDown
                        codes={countryCodes}
                        elementId="countryCodeId"
                        value={countryCode}
                        disable={true}
                      />
                    </div>
                    <div className="form-group py-2">
                      <label id="additionalAddressLabel" className="form-label">
                        Additional Address Lines
                      </label>
                      <TextField
                        className="form-control"
                        id="additionalAddressInput"
                        value={additionalAddress}
                        onChange={handleAdditionalAddress}
                        minRows={3}
                        multiline
                      />
                      {!isAdditionalAddress && (
                        <span style={{ fontWeight: "bold", color: "red" }}>
                          Maximum is 55 characters in Additional Address Lines
                          field.
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <br />
          {isFetchingData && <progress max="100" id="progressBar" />}
          {showValidatedAddressPrompt ? (
            <AddressPrompt
              validatedAddress={validatedAddrResult}
              togglingPrompt={togglingPrompt}
              onSelectedAddress={onSelectedAddress}
            />
          ) : (
            <React.Fragment />
          )}
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              margin: "auto",
              alignItems: "center",
              padding: "10px",
            }}
          >
            {!(loc !== undefined) ? (
              <span>
                <Tooltip
                  title={
                    !isValidateButtonEnabled ? (
                      <h5>Both Street address and Postal code are required</h5>
                    ) : (
                      ""
                    )
                  }
                >
                  <span>
                    <FlagableButton
                      className="btn btn-primary"
                      onClick={validateAddress}
                      disabled={!isValidateButtonEnabled}
                      id="validateAddressButton"
                    >
                      Validate Address
                    </FlagableButton>
                  </span>
                </Tooltip>
              </span>
            ) : (
              ""
            )}
          </Box>

          <br />
          <LocationAvailability
            updateRefNumVal={updateRefNumVal}
            locAvailabilityFromApi={locAvailability}
          />
          <br />
          <div id="additionalContactsDiv">
            <Accordion defaultExpanded={true}>
              <div className="pageContent-style">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="pannel1a-content"
                >
                  <h5>Additional Contacts</h5>
                </AccordionSummary>
              </div>
              <div className="pageContent-style">
                <AccordionDetails>
                  <AdditionalContacts
                    showContactDetails={!showInputText}
                    defaultContactName={contactName}
                    defaultEmailAddress={emailAddress}
                    defaultPhoneNumber={phoneNumber}
                    additionalContactsList={loc?.primaryContact!}
                    updateContactList={updateAdditionalContactList}
                  ></AdditionalContacts>
                </AccordionDetails>
              </div>
              <div className="validation-error">
                {!isAllContactTypesAreSelected && (
                  <span>
                    &nbsp;&nbsp;&nbsp; There should be at least one contact of
                    each contact type
                  </span>
                )}
                <br />
                {!isAllContactTypesAreActive && (
                  <span>
                    &nbsp;&nbsp;&nbsp; There should be at least one active
                    contact of each contact type
                  </span>
                )}
                <br />
                {hasTooManyActiveWarehouseContacts(additionalContactsList) && (
                  <span>
                    &nbsp;&nbsp;&nbsp; You cannot have more than three <b>active</b> warehouse contacts.
                  </span>
                )}
              </div>
            </Accordion>
          </div>
          <br />
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              margin: "auto",
              alignItems: "center",
              padding: "10px",
            }}
          >
            {!(loc !== undefined) ? (
              <span>
                <Tooltip
                  title={
                    !isCreateLocationButtonEnabled ? (
                      <h5>
                        Must fill mandatory fields, validate address, and
                        resolve any errors in Additional Contacts.
                      </h5>
                    ) : (
                      ""
                    )
                  }
                >
                  <span>
                    <div className="row justify-content-center m-xl-2">
                    <button
                      id="goToErrorsButton"
                      type="button"
                      className="btn"
                      style={{
                        width: "auto"
                      }}
                      disabled={isCreateLocationButtonEnabled}
                      onClick={ () => goToNextInvalidField()}
                    >
                      {(!isCreateLocationButtonEnabled?"Go to errors":"No errors")}
                    </button>
                  </div>
                    <button
                      className="btn btn-primary"
                      onClick={() => createOrUpdateLocation("create")}
                      disabled={!isCreateLocationButtonEnabled}
                      id="createLocationButton"
                    >
                      Create Location
                    </button>
                  </span>
                </Tooltip>
              </span>
            ) : (
              <span>
                <Tooltip
                  title={
                    !isUpdateLocationButtonEnabled ? (
                      <h5>
                        Must fill mandatory fields and resolve any errors in
                        Additional Contacts.
                      </h5>
                    ) : (
                      ""
                    )
                  }
                >
                  <span>
                    <button
                      className="btn btn-primary"
                      disabled={!isUpdateLocationButtonEnabled}
                      onClick={() => createOrUpdateLocation("update")}
                      id="updateLocationButton"
                    >
                      Update Location
                    </button>
                  </span>
                </Tooltip>
                &nbsp;
                <button
                  id="cancelButton"
                  className="btn btn-dark"
                  onClick={() => cancel()}
                >
                  Back
                </button>
                &nbsp;
                <button
                  id="deleteLocationButton"
                  className="btn btn-danger"
                  disabled={!isUpdateLocationButtonEnabled}
                  onClick={() => openDialog()}
                >
                  Delete Location
                </button>
              </span>
            )}
          </Box>
        </div>
        <div id="deleteDialogLocation">
          <Dialog open={open} onClose={closeDialog}>
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                id="searchLocationButton"
                onClick={closeDialog}
                color="primary"
                autoFocus
              >
                No
              </Button>
              <Button
                id="searchLocationButton"
                onClick={() => deleteLocation()}
                color="primary"
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div id="alertDialog">
          <Dialog open={openAlert} id="statusDialog">
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
              <DialogContentText>{dialogContent}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                id="statusDialogButton"
                onClick={closeAlertDialog}
                color="primary"
                autoFocus
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div id="validationAlert">
          <Dialog open={validationAlert} id="validationAlertDialog">
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
              <DialogContentText>{dialogContent}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                id="validationAlertButton"
                onClick={closeValidationAlert}
                color="primary"
                autoFocus
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </section>
    </div>
  );
};

export default NewLocationPage;
