import { TextField } from "@mui/material";
import React from "react";
import {
  isValidFloorLoad,
} from "../../helper/HelperFunctions";
import {
  isGreaterThanMaxVolume,
  isGreaterThanMaxWeight,
  isInvalidVolume,
  isInvalidWeight,
  standardVolume
} from "../../helper/ShipUnitUtilities";
import {
  isInvalidPalletSlipSheetsMin,
  isInvalidPalletSlipSheetsMax,
  isInvalidPalletSpacesMin,
  isInvalidPalletSpacesMax
} from "../../helper/ShipUnitUtilities";
import { collectInvalidTotalErrorMessages } from "../../helper/ShipUnitUtilities";
import { hasInvalidTotals } from "../../helper/ShipUnitUtilities";
import PoItemShipUnit from "../../types/PoItemShipUnit";
import { DropDown } from "../dropDown/DropDown";
import { FlagableTextField } from "./FlagableTextField";

interface IProps {
  poItemShipUnitsProps: PoItemShipUnit[];
  validationHandler: (hasValidationError: boolean) => void;
}

export interface IState {
  poItemShipUnits: PoItemShipUnit[];
}

export default class PoItemShipUnitPage extends React.Component<
  IProps,
  IState
> {
  handleOnBlur(shipUnitId: string, event: any) {
    var rowsArray = this.state.poItemShipUnits;
    var newRow = rowsArray.map((row) => {
      if (row.shipUnitId === shipUnitId) {
        const targetName = event.target.name;

        if ("volume" === targetName) {
          row.volumeWithCommas = true;
        }
        if ("weight" === targetName) {
          row.weightWithCommans = true;
        }
      }
      return row;
    });
    this.setState({ poItemShipUnits: newRow });
  }

  handleOnFocus(shipUnitId: string, event: any) {
    var rowsArray = this.state.poItemShipUnits;
    var newRow = rowsArray.map((row) => {
      if (row.shipUnitId === shipUnitId) {
        const targetName = event.target.name;

        if ("volume" === targetName) {
          row.volumeWithCommas = false;
        }
        if ("weight" === targetName) {
          row.weightWithCommans = false;
        }
      }
      return row;
    });
    this.setState({ poItemShipUnits: newRow });
  }

  handleChange(shipUnitId: string, event: any) {
    var targetValue: any;
    var targetName: any;
    var updatedShipUnit = this.state.poItemShipUnits.map((shipUnit) => {
      if (shipUnit.shipUnitId === shipUnitId) {
        targetValue = event.target.value;
        targetName = event.target.name;
        if ("loadMethod" === targetName) {
          shipUnit.loadMethod = targetValue;
          if (targetValue === "FLOOR_LOAD") {
            shipUnit.oversized=false;
            shipUnit.palletSlipSheets = 0;
            shipUnit.palletSpaces = 0;
            shipUnit.volume = standardVolume(shipUnit);
          }
          if (targetValue === "SLIP_SHEETS") {
            shipUnit.oversized=false;
            shipUnit.palletSlipSheets = 0;
            shipUnit.palletSpaces = 0;
            shipUnit.volume = standardVolume(shipUnit);
          }
        }
        if ("volume" === targetName) {
          shipUnit.volume = +targetValue;
        }
        if ("weight" === targetName) {
          shipUnit.weight = +targetValue;
        }
        if ("palletSlipSheets" === targetName) {
          shipUnit.palletSlipSheets = +targetValue.replace(/[^0-9]/g, "");
        }
        if ("palletSpaces" === targetName) {
          var val = targetValue.replace(/[^0-9]/g, "");
          shipUnit.palletSpaces = +val;
          shipUnit.volume = standardVolume(shipUnit);
          
        }
        if ("oversize" === targetName) {
          if (targetValue === "YES") {
            shipUnit.oversized=true;
          }
          else {
            shipUnit.oversized=false;
            shipUnit.volume = standardVolume(shipUnit);
          }
        }
      }
      return shipUnit;
    });
    this.setState({ poItemShipUnits: updatedShipUnit });
    this.handleValidation();
  }

  handleValidation() {
    var hasErrors = this.state.poItemShipUnits?.some(
      (shipUnit: PoItemShipUnit) =>
        isInvalidPalletSlipSheetsMin(
          shipUnit.loadMethod,
          shipUnit.palletSlipSheets
        ) ||
        isInvalidPalletSpacesMin(shipUnit.loadMethod, shipUnit.palletSpaces) ||
        isInvalidPalletSlipSheetsMax(
          shipUnit.loadMethod,
          shipUnit.palletSlipSheets
        ) ||
        isInvalidPalletSpacesMax(
          shipUnit.loadMethod,
          shipUnit.palletSpaces
        ) ||
        isInvalidVolume(shipUnit) ||
        isGreaterThanMaxVolume(shipUnit.volume) ||
        isInvalidWeight(shipUnit.weight) ||
        isGreaterThanMaxWeight(shipUnit.weight)) ||
        hasInvalidTotals(this.state.poItemShipUnits);
    this.props.validationHandler(hasErrors);
  }

  render() {
    this.state = {
      poItemShipUnits: this.props.poItemShipUnitsProps,
    };
    this.handleValidation();
    return (
      <>
        <section className="sectionHeadingPadding">
          <div id="poItemShipUnitPage" className="container">
            <div
              id="poItemShipUnitsTable"
              style={{
                overflow: "auto",
                whiteSpace: "nowrap",
                paddingLeft: "75px",
              }}
            >
              <table className="table">
                <thead>
                  <tr>
                    <th>NMFC Class</th>
                    <th>Load Method</th>
                    <th>Pallets/SlipSheets</th>
                    <th>Pallets Spaces</th>
                    <th>Volume (cu ft)</th>
                    <th>Weight (lbs)</th>
                    <th>Cartons</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.poItemShipUnits.map(
                    (shipUnit: PoItemShipUnit) => {
                      let rows : JSX.Element[] = [
                        <tr>
                          <td>{shipUnit.nfmcClass}</td>
                          <td>
                              <DropDown
                                  codes={[
                                      "PALLET",
                                      "SLIP_SHEETS",
                                      "FLOOR_LOAD",
                                  ]}
                                  elementId={
                                      "loadMethod" + shipUnit.shipUnitId
                                  }
                                  value={shipUnit.loadMethod}
                                  onChange={this.handleChange.bind(
                                      this,
                                      shipUnit.shipUnitId
                                  )}
                                  name="loadMethod"
                              />
                              <br />
                              {isValidFloorLoad(shipUnit.loadMethod) && (
                                  <span
                                      id={"FloorLoad" + shipUnit.shipUnitId}
                                      style={{
                                          fontWeight: "bold",
                                          color: "black",
                                      }}
                                  >
                                      Floor loaded shipments that <br />
                                      are less than truckload may <br />
                                      be requested to palletize
                                  </span>
                              )}
                          </td>
                          <td>
                              <FlagableTextField
                                  id={
                                      "palletSlipSheets" +
                                      shipUnit.shipUnitId
                                  }
                                  name="palletSlipSheets"
                                  className="form-control"
                                  value={shipUnit.palletSlipSheets}
                                  disabled={
                                      shipUnit.loadMethod === "FLOOR_LOAD"
                                  }
                                  onChange={this.handleChange.bind(
                                      this,
                                      shipUnit.shipUnitId
                                  )}
                              />
                              <br />
                              {isInvalidPalletSlipSheetsMin(
                                  shipUnit.loadMethod,
                                  shipUnit.palletSlipSheets
                              ) && (
                                  <span
                                      id={
                                          "palletSlipSheetsError" +
                                          shipUnit.shipUnitId
                                      }
                                      style={{
                                          fontWeight: "bold",
                                          color: "red",
                                      }}
                                  >
                                      Should be non-zero.
                                  </span>
                              )}
                              {isInvalidPalletSlipSheetsMax(
                                  shipUnit.loadMethod,
                                  shipUnit.palletSlipSheets
                              ) && (
                                  <span
                                      id={
                                          "palletSlipSheetsError" +
                                          shipUnit.shipUnitId
                                      }
                                      style={{
                                          fontWeight: "bold",
                                          color: "red",
                                      }}
                                  >
                                      Should be less or equals to 90.
                                  </span>
                              )}
                          </td>
                          <td>
                              <FlagableTextField
                                  id={"palletSpaces" + shipUnit.shipUnitId}
                                  name="palletSpaces"
                                  className="form-control"
                                  value={shipUnit.palletSpaces}
                                  disabled={
                                      shipUnit.loadMethod ===
                                          "FLOOR_LOAD" ||
                                      shipUnit.loadMethod === "SLIP_SHEETS"
                                  }
                                  onChange={this.handleChange.bind(
                                      this,
                                      shipUnit.shipUnitId
                                  )}
                              />
                              <br />
                              {isInvalidPalletSpacesMin(
                                  shipUnit.loadMethod,
                                  shipUnit.palletSpaces
                              ) && (
                                  <span
                                      id={
                                          "palletSpacesError" +
                                          shipUnit.shipUnitId
                                      }
                                      style={{
                                          fontWeight: "bold",
                                          color: "red",
                                      }}
                                  >
                                      Should be non-zero.
                                  </span>
                              )}
                              {isInvalidPalletSpacesMax(
                                  shipUnit.loadMethod,
                                  shipUnit.palletSpaces
                              ) && (
                                  <span
                                      id={
                                          "palletSpacesError" +
                                          shipUnit.shipUnitId
                                      }
                                      style={{
                                          fontWeight: "bold",
                                          color: "red",
                                      }}
                                  >
                                      Should be less or equals to 30.
                                  </span>
                              )}
                          </td>
                          <td>
                              <TextField
                                  id={"volume" + shipUnit.shipUnitId}
                                  name="volume"
                                  className="form-control"
                                  value={
                                      shipUnit.volumeWithCommas
                                          ? Math.round(shipUnit.volume)
                                                .toString()
                                                .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                )
                                          : Math.round(shipUnit.volume)
                                                .toString()
                                                .replace(/,/g, "")
                                  }
                                  onFocus={this.handleOnFocus.bind(
                                      this,
                                      shipUnit.shipUnitId
                                  )}
                                  onBlur={this.handleOnBlur.bind(
                                      this,
                                      shipUnit.shipUnitId
                                  )}
                                  onChange={this.handleChange.bind(
                                      this,
                                      shipUnit.shipUnitId
                                  )}
                                  disabled={
                                      shipUnit.loadMethod === "PALLET" && !shipUnit.oversized
                                  }
                              />
                              <br />
                              {shipUnit.volume <= standardVolume(shipUnit) && shipUnit.oversized && (
                                      <span
                                          id={
                                              "volumeError" +
                                              shipUnit.shipUnitId
                                          }
                                          style={{
                                              fontWeight: "bold",
                                              color: "red",
                                          }}
                                      >
                                          Please enter increased volume
                                      </span>
                                  )}
                              {shipUnit.volume <= standardVolume(shipUnit) && shipUnit.oversized && shipUnit.volume === 0 && (
                                  <br></br>
                              )}
                              { shipUnit.volume === 0 && (
                                      <span
                                          id={
                                              "volumeError" +
                                              shipUnit.shipUnitId
                                          }
                                          style={{
                                              fontWeight: "bold",
                                              color: "red",
                                          }}
                                      >
                                          Should be non-zero.
                                      </span>
                                  )}
                                  {isGreaterThanMaxVolume(shipUnit.volume) && (
                                      <span
                                          id={
                                              "volumeMaxError" +
                                              shipUnit.shipUnitId
                                          }
                                          style={{
                                              fontWeight: "bold",
                                              color: "red",
                                          }}
                                      >
                                          Can not exceed
                                          <br /> 3,300 cu ft.
                                      </span>
                                  )}
                          </td>
                          <td>
                              <TextField
                                  id={"weight" + shipUnit.shipUnitId}
                                  name="weight"
                                  className="form-control"
                                  value={
                                      shipUnit.weightWithCommans
                                          ? Math.round(shipUnit.weight)
                                                .toString()
                                                .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                )
                                          : Math.round(shipUnit.weight)
                                                .toString()
                                                .replace(/,/g, "")
                                  }
                                  onFocus={this.handleOnFocus.bind(
                                      this,
                                      shipUnit.shipUnitId
                                  )}
                                  onBlur={this.handleOnBlur.bind(
                                      this,
                                      shipUnit.shipUnitId
                                  )}
                                  onChange={this.handleChange.bind(
                                      this,
                                      shipUnit.shipUnitId
                                  )}
                              />
                              <br />
                              {isInvalidWeight(shipUnit.weight) && (
                                  <span
                                      id={
                                          "weightError" +
                                          shipUnit.shipUnitId
                                      }
                                      style={{
                                          fontWeight: "bold",
                                          color: "red",
                                      }}
                                  >
                                      Should be non-zero.
                                  </span>
                              )}
                              {isGreaterThanMaxWeight(shipUnit.weight) && (
                                  <span
                                      id={
                                          "weightMaxError" +
                                          shipUnit.shipUnitId
                                      }
                                      style={{
                                          fontWeight: "bold",
                                          color: "red",
                                      }}
                                  >
                                      Can not exceed
                                      <br /> 45,000 lbs.
                                  </span>
                              )}
                          </td>
                          <td>
                              {Math.floor(shipUnit.cartons)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </td>{" "}
                      </tr>
                    ];
                    if (shipUnit.loadMethod === "PALLET") {
                        rows.push(
                            <tr>
                                <td></td>
                                <td colSpan={5}>
                                    <div className="d-flex justify-content-start align-items-center">
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                                color: "black",
                                            }}
                                        >
                                            Select "YES" only if you are shipping on larger than standard pallets (40” x 48”)
                                        </span>
                                        <div
                                            style={{
                                                width: "5em",
                                            }}
                                        >&nbsp;&nbsp;&nbsp;<DropDown
                                                codes={["YES", "NO"]}
                                                elementId={
                                                    "oversize" +
                                                    shipUnit.shipUnitId
                                                }
                                                value={
                                                    shipUnit.oversized
                                                        ? "YES"
                                                        : "NO"
                                                }
                                                onChange={this.handleChange.bind(
                                                    this,
                                                    shipUnit.shipUnitId
                                                )}
                                                name="oversize"
                                            />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        );
                    }
                    return rows;
                    })
                  }
                </tbody>
              </table>
              {
                collectInvalidTotalErrorMessages(this.state.poItemShipUnits)?.map(
                  (invalidTotalErrorMessage:string) => (
                    <span
                    id="totalErrors"
                    style={{
                      fontWeight: "bold",
                      color: "red",
                    }}
                    >{invalidTotalErrorMessage}<br /></span>
                  )
                )
              }
            </div>
          </div>
        </section>
      </>
    );
  }
}
