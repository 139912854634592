import { Link } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { getJsonResult, post } from "../../services/FetchServices";
import Location from "../../types/Location";
import { DropDown } from "../dropDown/DropDown";

interface FindLocationProps {
  searchLocation?: Location;
}

const FindLocationPage: React.FC = () => {
  const location = useLocation();
  const searchLocation = (location?.state as FindLocationProps)?.searchLocation;
  const [locations, setLocations] = useState<Location[]>([]);
  const [locationXid, setLocationXid] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [activeStatus, setActiveStatus] = useState("");
  const [locationName, setLocationName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openAlert, setAlertOpen] = useState(false);
  var authHeader = localStorage.getItem("authHeader");
  var username = localStorage.getItem("userName");

  let locationJson = {
    locationXId: locationXid,
    city: city,
    provinceCode: state,
    postalCode: postalCode,
    countryCode3GId: countryCode,
    locationName: locationName,
    activeStatus: activeStatus,
  };

  const closeAlertDialog = () => {
    setAlertOpen(false);
  };

  useEffect(() => {
    if (searchLocation !== undefined) {
      locationJson = searchLocation;
      setLocationXid(locationJson.locationXId);
      setCity(locationJson.city);
      setState(locationJson.provinceCode);
      setPostalCode(locationJson.postalCode);
      setCountryCode(locationJson.countryCode3GId);
      setLocationName(locationJson.locationName);
      findLocation();
    }
  }, []);

  const history = useHistory();
  const routeToNewLocation = (locationGId: string) => {
    var locationUrl =
      `${process.env.REACT_APP_BACKEND_URL}/findLocation/getLocation?authHeader=` +
      authHeader +
      "&locationGId=" +
      locationGId;
    getJsonResult(locationUrl)
      .then((response) => response.json())
      .then((data: Location) => {
        history.push({
          pathname: "/newLocation",
          state: { location: data, searchLocation: locationJson },
        });
      });
  };

  const handleLocationId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocationXid(e.target.value.replace(/[^a-zA-Z0-9,._'\s]/g, ""));
  };
  const handleCity = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCity(e.target.value.replace(/[^a-zA-Z0-9,.'\s]/g, ""));
  };
  const handleState = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState(e.target.value.replace(/[^a-zA-Z0-9,._\s]/g, ""));
  };
  const handlePostalCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPostalCode(e.target.value.replace(/[^a-zA-Z0-9,._'\s]/g, ""));
  };
  const handleCountryCode = (e: any) => {
    setCountryCode(e.target.value);
  };
  const handleActiveStatus = (e: any) => {
    setActiveStatus(e.target.value);
  };
  const handleLocationName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocationName(
      e.target.value.replace(/[^a-zA-Z0-9-,.;_=+()!@#$%{}[\]|/\\^\s]/g, "")
    );
  };

  const findLocation = () => {
    setIsLoading(true);
    var findLocationUrl =
      `${process.env.REACT_APP_BACKEND_URL}/findLocation/search?authHeader=` +
      authHeader +
      "&vendorId=" +
      localStorage.getItem("vendorId");
    post(findLocationUrl, locationJson)
      .then((response) => response.json())
      .then((data) => {
        if (data == null || data.length === 0) {
          setAlertOpen(true);
        }
        setLocations(data);
        setIsLoading(false);
      });
  };
  const onKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      findLocation();
      event.preventDefault();
    }
  };

  return (
    <section
      className="sectionHeadingPadding"
      onKeyDown={onKeyDown}
      tabIndex={-1}
      style={{ outline: "none" }}
    >
      <div className="container">
        <div id="findLocationPage" className="intro">
          <div id="locationFinder" className="section-heading mb-4">
            <span
              className="section-heading-lower"
              style={{
                fontSize: "1.9rem",
                marginLeft: "531px",
                color: "#6b3a06",
              }}
            >
              LOCATION FINDER
            </span>
          </div>

          <div className="row mb-5">
            <div className="col-lg-8 mx-auto">
              <div
                className="bg-grey p-5 rounded "
                style={{ boxShadow: " 0px 5px 10px #91d475" }}
              >
                <div className="row">
                  <div className="col-md">
                    <div className="form-group py-2" style={{ width: "z" }}>
                      <div className="input-group">
                        <TextField
                          id="locationName"
                          value={locationName}
                          onChange={handleLocationName}
                          label="Location Name"
                          variant="outlined"
                          aria-describedby="button-addon1"
                          className="form-control border-0 bg-light"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-group py-2" style={{ width: "z" }}>
                      <div className="input-group">
                        <TextField
                          id="city"
                          label="City"
                          variant="outlined"
                          aria-describedby="button-addon1"
                          className="form-control border-0 bg-light"
                          value={city}
                          onChange={handleCity}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-group py-2" style={{ width: "z" }}>
                      <div className="input-group">
                        <TextField
                          id="state"
                          label="State or Province"
                          variant="outlined"
                          aria-describedby="button-addon1"
                          className="form-control border-0 bg-light"
                          value={state}
                          onChange={handleState}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md">
                    <div className="form-group py-2" style={{ width: "z" }}>
                      <div className="input-group">
                        <TextField
                          id="postalCode"
                          label="Postal Code"
                          variant="outlined"
                          aria-describedby="button-addon1"
                          className="form-control border-0 bg-light"
                          value={postalCode}
                          onChange={handlePostalCode}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-group py-2" style={{ width: "z" }}>
                      <div className="input-group">
                        <DropDown
                          codes={["USA", "CAN", "MEX"]}
                          className="form-control border-0 bg-light"
                          elementId={"countryCode"}
                          value={countryCode}
                          onChange={handleCountryCode}
                          label="Country Code"
                          defaultOption="None"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-group py-2" style={{ width: "z" }}>
                      <div className="input-group">
                        <TextField
                          id="locationXid"
                          label="Location ID"
                          variant="outlined"
                          aria-describedby="button-addon1"
                          className="form-control border-0 bg-light"
                          value={locationXid}
                          onChange={handleLocationId}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md">
                    <div className="form-group py-2" style={{ width: "z" }}>
                      <div className="input-group" style={{ width: "31%" }}>
                        <TextField
                          id="activeStatus"
                          select
                          value={activeStatus}
                          label="Is Active"
                          className="form-control border-0 bg-light"
                          onChange={handleActiveStatus}
                          SelectProps={{
                            native: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        >
                          <option key={""} value={""}>
                            All
                          </option>
                          <option key={"Yes"} value={"Y"}>
                            Yes
                          </option>
                          <option key={"No"} value={"N"}>
                            No
                          </option>
                        </TextField>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div style={{ textAlign: "center" }}>
                    <span>
                      <button
                        type="button"
                        className="btn btn-success"
                        id="searchLocationButton"
                        onClick={findLocation}
                        style={{
                          width: 100,
                          marginBottom: "10px",
                        }}
                      >
                        Find
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="locationsTable">
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <div
                className="spinner-border text-primary"
                id="locationsSpinner"
                role="status"
              ></div>
            </div>
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th>Location Name</th>
                  <th>Street Address</th>
                  <th>City</th>
                  <th>State or Province</th>
                  <th>Postal Code</th>
                  <th>Country Code</th>
                  <th>Location ID</th>
                  <th>Is Active</th>
                </tr>
              </thead>
              <tbody>
                {locations.map((location: Location) => (
                  <tr>
                    <td>
                      <Link
                        onClick={() => routeToNewLocation(location.locationGId)}
                      >
                        {location.locationName}
                      </Link>
                    </td>
                    <td title={location.streetAddress}>
                      {location.streetAddress}
                    </td>
                    <td>{location.city}</td>
                    <td>{location.provinceCode}</td>
                    <td>{location.postalCode}</td>
                    <td>{location.countryCode3GId}</td>
                    <td>{location.locationXId}</td>
                    <td>{location.isActive ? "Yes" : "No"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div id="alertDialog">
        <Dialog open={openAlert}>
          <DialogTitle>Warning!</DialogTitle>
          <DialogContent>
            <DialogContentText>
              No results returned from the search criteria.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAlertDialog} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </section>
  );
};

export default FindLocationPage;
