import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Footer from "./components/containers/Footer";
import Header from "./components/containers/Header";
import HomePage from "./components/containers/HomePage";
import LoginInPage from "./components/containers/LoginPage";

function App() {
  return (
    <div id="completePage" className="completePage-style">
      <div id="Header">
        <Header />
      </div>
      <Router>
        <div id="App">
          <Switch>
            <Route path="/" exact component={LoginInPage} />
            <Route path="/home" component={HomePage} />
            <Route component={HomePage} />
          </Switch>
        </div>
      </Router>
      <div id="Footer">
        <Footer />
      </div>
    </div>
  );
}

export default App;
