import React, { MouseEvent, KeyboardEvent, useEffect, useRef, useState } from "react";
import { Button, ButtonProps } from "@mui/material";

export const FlagableButton: React.FunctionComponent<
    ButtonProps
> = (props) => {
    const eventListenerRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [showNotice, setShowNotice] = useState(false);

    useEffect(() => {
        const eventListenerElement: Element | null = eventListenerRef.current;
        if (eventListenerElement) {
            eventListenerElement.addEventListener("showNotice", () => {
                setShowNotice(true);
            });
        }
        const buttonElement: Element | null = buttonRef.current;
        if (buttonElement) {
            buttonElement.addEventListener("click", () => {
                setShowNotice(false);
            });
        }
    });
    return (
        <div className="position-relative d-flex align-items-stretch">
            <div id={props.id + "-event-listener"} ref={eventListenerRef} />
            <button
                {...props}
                className= { (props.className?? "") + ( showNotice ? " outline-danger":"") }
                ref={buttonRef}
            >
                {props.children}
            </button>
            {showNotice && (
                <div className="position-relative d-flex align-items-stretch user-select-none">
                    <div
                        className="position-absolute d-flex align-items-center h-100"
                        style={{
                            zIndex: 1000,
                        }}
                    >
                        <div className="bg-white bg-gradient p-2 rounded-3 border-danger fw-bolder text-nowrap text-danger shadow ms-2">
                            Click Here to Continue.
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export const focusOnButton = (buttonId: string) => {
    const button = document.getElementById(buttonId);
    if (button) {
        button.scrollTo({behavior: "smooth"});
        button.focus();
        const buttonEventListener = document.getElementById(
            buttonId + "-event-listener"
        );
        buttonEventListener &&
            buttonEventListener.dispatchEvent(new Event("showNotice"));
    }
};
