import PoItem from "../types/PoItem";
import PoItemShipUnit from "../types/PoItemShipUnit";

export function isInvalidWeight(weight: number):boolean {
  return weight === 0;
}

export function isInvalidVolume(shipUnit:PoItemShipUnit):boolean {
  if ( shipUnit.oversized) {
    return shipUnit.volume <= standardVolume(shipUnit)
  } else {
    return shipUnit.volume === 0;
  }
}

export function isGreaterThanMaxWeight(weight: number):boolean {
  return weight > 45000;
}

export function isGreaterThanMaxVolume(volume: number):boolean {
  return volume > 3300;
}

export function hasInvalidTotals(poItemShipUnits: Array<PoItemShipUnit>) {
  let palletSlipSheetsTotal = 0;
  let palletSpacesTotal = 0;
  let volumeTotal = 0;
  let weightTotal = 0;

  poItemShipUnits?.some(
    (shipUnit: PoItemShipUnit) => {
      palletSlipSheetsTotal += shipUnit.palletSlipSheets;
      palletSpacesTotal += shipUnit.palletSpaces;
      volumeTotal += shipUnit.volume;
      weightTotal += shipUnit.weight;
    }
  );
  return isInvalidPalletSlipSheetsMax("", palletSlipSheetsTotal) ||
    isInvalidPalletSpacesMax("", palletSpacesTotal) ||
    isGreaterThanMaxVolume(volumeTotal) ||
    isGreaterThanMaxWeight(weightTotal);
}

export function collectInvalidTotalErrorMessages(poItemShipUnits: Array<PoItemShipUnit>) {
  let invalidTotalErrors: Array<string> = new Array<string>();
  let palletSlipSheetsTotal: number = 0;
  let palletSpacesTotal: number = 0;
  let volumeTotal: number = 0;
  let weightTotal: number = 0;

  poItemShipUnits?.some(
    (shipUnit: PoItemShipUnit) => {
      palletSlipSheetsTotal = palletSlipSheetsTotal + shipUnit.palletSlipSheets;
      palletSpacesTotal = palletSpacesTotal + shipUnit.palletSpaces;
      volumeTotal = volumeTotal + shipUnit.volume;
      weightTotal = weightTotal + shipUnit.weight;
    }
  );

  if (isInvalidPalletSlipSheetsMax("", palletSlipSheetsTotal)) {
    invalidTotalErrors.push("Total pallet slip sheets should be less than or equal to 90.");
  }

  if (isInvalidPalletSpacesMax("", palletSpacesTotal)) {
    invalidTotalErrors.push("Total pallet spaces should be less than or equal to 30.");
  }

  if (isGreaterThanMaxVolume(volumeTotal)) {
    invalidTotalErrors.push("Total volume can not exceed 3,300 cu ft.");
  }

  if (isGreaterThanMaxWeight(weightTotal)) {
    invalidTotalErrors.push("Total weight can not exceed 45,000 lbs.");
  }

  return invalidTotalErrors;
}
export function isInvalidPalletSlipSheetsMin(
  loadMethod: string,
  palletSlipSheets: number
): boolean {
  return (
    (loadMethod === "SLIP_SHEETS" ||
      loadMethod === "PALLET" ||
      loadMethod === "") &&
    palletSlipSheets === 0
  );
}

export function isInvalidPalletSlipSheetsMax(
  loadMethod: string,
  palletSlipSheets: number
): boolean {
  return (
    (loadMethod === "PALLET" || loadMethod === "") && palletSlipSheets > 90
  );
}

export function isInvalidPalletSpacesMin(
  loadMethod: string,
  palletSpaces: number
): boolean {
  return (loadMethod === "PALLET" || loadMethod === "") && palletSpaces === 0;
}

export function isInvalidPalletSpacesMax(
  loadMethod: string,
  palletSpaces: number
): boolean {
  return (loadMethod === "PALLET" || loadMethod === "") && palletSpaces > 30;
}

export function isInvalidSlipSheetsValue(shipUnit: PoItemShipUnit): boolean {
  return (
    isInvalidPalletSlipSheetsMin(shipUnit.loadMethod, shipUnit.palletSlipSheets) ||
    isInvalidPalletSlipSheetsMax(shipUnit.loadMethod, shipUnit.palletSlipSheets)
  );
}

export function hasInvalidSlipSheets(poItemShipUnits: Array<PoItemShipUnit>): boolean {
  return poItemShipUnits?.some(isInvalidSlipSheetsValue);
}

export function isInvalidPalletSpacesValue(shipUnit: PoItemShipUnit): boolean {
  return (
    isInvalidPalletSpacesMin(shipUnit.loadMethod, shipUnit.palletSpaces) ||
    isInvalidPalletSpacesMax(shipUnit.loadMethod, shipUnit.palletSpaces)
  );
}

export function hasInvalidPalletSpaces(poItemShipUnits: Array<PoItemShipUnit>): boolean {
  return poItemShipUnits?.some(isInvalidPalletSpacesValue);
}
export function hasInvalidShipUnits(poItemShipUnits: Array<PoItemShipUnit>): boolean {
  return poItemShipUnits?.some((shipUnit: PoItemShipUnit) => {
    isInvalidSlipSheetsValue(shipUnit) ||
      isInvalidPalletSpacesValue(shipUnit) ||
      isInvalidVolume(shipUnit) ||
      isGreaterThanMaxVolume(shipUnit.volume) ||
      isInvalidWeight(shipUnit.weight) ||
      isGreaterThanMaxWeight(shipUnit.weight);
  });
}
;

export function isInvalidShipQuantityMin(poItem: PoItem): boolean {
  return poItem.shipQuantity <= 0;
}

export function isInvalidShipQuantityValue(poItem: PoItem): boolean {
  if (poItem.quantityPerCarton === 0) {
    return false;
  }

  return isDivisibleShipQuantity(poItem) || isLessThanRemainingQuantity(poItem);
}

export function isDivisibleShipQuantity( poItem: PoItem ): boolean {
  return poItem.shipQuantity > 0 && poItem.shipQuantity % poItem.quantityPerCarton !== 0;
}

export function isLessThanRemainingQuantity( poItem: PoItem ): boolean {
  return poItem.shipQuantity > poItem.remainingQuantity && poItem.remainingQuantity > 0;
}

export function hasInvalidShipQuantities(poItems: Array<PoItem>): boolean {
  return poItems?.some(isInvalidShipQuantityValue);
}

export function standardVolume(shipUnit: PoItemShipUnit) {
  return shipUnit.palletSpaces > 0 ? shipUnit.palletSpaces * 110 : shipUnit.volumeBkp;
}
