import InfoIcon from "@mui/icons-material/Info";
import { NativeSelect, TextField } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import AdditionalContactDetails from "../../types/AdditionalContactDetails";
import { FlagableTextField } from "../purchaseOrderRelease/FlagableTextField";
import { isValidEmail, isValidPhone } from "../../helper/ContactValidationUtilities";

interface Iprops {
  showContactDetails: boolean;
  defaultContactName: string;
  defaultEmailAddress: string;
  defaultPhoneNumber: string;
  additionalContactsList: AdditionalContactDetails[];
  updateContactList(additionaContactDet: AdditionalContactDetails[]): void;
}

export interface IState {
  tableHeaders: string[];
  rows: AdditionalContactDetails[];
  showContactDetails: boolean;
  defaultValuesLoaded: boolean;
}

export default class AdditionalContacts extends React.Component<
  Iprops,
  IState
> {
  constructor(props: Iprops) {
    super(props);
    this.state = {
      tableHeaders: [
        "",
        "Contact ID",
        "Contact Name",
        "Contact Type",
        "Email Address",
        "Phone Number",
        "",
      ],
      rows: this.props.additionalContactsList
        ? this.props.additionalContactsList
        : [],
      showContactDetails: this.props.additionalContactsList ? true : false,
      defaultValuesLoaded: false,
    };
  }

  handleChange(contactXid: string, event: any) {
    var rowsArray = this.state.rows;
    var newRow = rowsArray.map((row) => {
      if (row.contactXid === contactXid) {
        if ("ContactName" === event.target.name) {
          row.contactName = event.target.value;
        }
        if ("EmailAddress" === event.target.name) {
          row.emailAddress = event.target.value;
        }
        if ("PhoneNumber" === event.target.name) {
          row.phone1 = event.target.value.replace(/[^0-9]/g, "");
        }
        if ("ContactType" === event.target.name) {
          row.jobTitle = event.target.value;
        }
        if ("ActiveStatus" === event.target.name) {
          row.active = event.target.checked;
        }
        row.operation = "IU";
      }
      return row;
    });
    this.setState({ rows: newRow });
  }

  handleAddRow = () => {
    try {
      const tableColProps = new AdditionalContactDetails(
        "RE" + Date.now(),
        "",
        "RELEASE_SUMMARY",
        "",
        "",
        "IU",
        true
      );

      if (this.state.rows.length === 0) {
        this.setState({ rows: [tableColProps], showContactDetails: true });
      } else {
        this.state.rows.push(tableColProps);
        this.setState({ rows: this.state.rows });
      }
    } catch (error) {
      console.log("Error in React Table handle Add Row : " + error);
    }
  };

  handleRemoveSpecificRow = (contactXid: string) => () => {
    var rowsArray = this.state.rows;
    var newRow = rowsArray.map((row: AdditionalContactDetails) => {
      if (row.contactXid === contactXid) {
        row.operation = "CD";
      }
      return row;
    });
    this.setState({ rows: newRow });
  };

  render() {
    this.props.updateContactList(this.state.rows);
    if (this.props.showContactDetails && !this.state.defaultValuesLoaded) {
      var types: any = [];
      this.state.rows.forEach((contact: AdditionalContactDetails) => {
        types.push(contact.jobTitle);
      });
      if (!types.includes("RELEASE_SUMMARY")) {
        this.state.rows.push(
          new AdditionalContactDetails(
            "RE" + Date.now(),
            this.props.defaultContactName,
            "RELEASE_SUMMARY",
            this.props.defaultEmailAddress,
            this.props.defaultPhoneNumber,
            "IU",
            true
          )
        );
      }
      if (!types.includes("TENDER")) {
        this.state.rows.push(
          new AdditionalContactDetails(
            "TE" + Date.now(),
            this.props.defaultContactName,
            "TENDER",
            this.props.defaultEmailAddress,
            this.props.defaultPhoneNumber,
            "IU",
            true
          )
        );
      }
      if (!types.includes("WAREHOUSE")) {
        this.state.rows.push(
          new AdditionalContactDetails(
            "WH" + Date.now(),
            this.props.defaultContactName,
            "WAREHOUSE",
            this.props.defaultEmailAddress,
            this.props.defaultPhoneNumber,
            "IU",
            true
          )
        );
      }
      this.setState({
        rows: this.state.rows,
        defaultValuesLoaded: true,
        showContactDetails: true,
      });
    }
    let list = this.state.rows.map((contact: AdditionalContactDetails) => {
      let hoverText = "";
      if ("RELEASE_SUMMARY" === contact.jobTitle) {
        hoverText =
          "Person that will receive a summary report on order release creation";
      } else if ("TENDER" === contact.jobTitle) {
        hoverText =
          "Person that will receive bill of lading and instructions when shipment is assigned to carrier";
      } else if ("WAREHOUSE" === contact.jobTitle) {
        hoverText =
          "Person that a carrier will contact when scheduling pick up appointments";
      }

      return contact.operation !== "CD" ? (
        <tr key={contact.contactXid}>
          <td>
            <span
              className="form-check form-switch"
              style={{ color: contact.active ? "green" : "gray" }}
            >
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                name="ActiveStatus"
                id={"activeStatus" + contact.contactXid}
                checked={contact.active}
                onChange={this.handleChange.bind(this, contact.contactXid)}
              />
              {contact.active ? "Active" : "Inactive"}
            </span>
          </td>

          <td className="col-md" style={{ width: "12%" }}>
            <label id={"contactId" + contact.contactXid} className="form-label">
              {contact.contactXid}
            </label>
          </td>

          <td className="col-md" style={{ width: "20%" }}>
            <FlagableTextField
              name="ContactName"
              value={contact.contactName}
              id={"contactName" + contact.contactXid}
              onChange={this.handleChange.bind(this, contact.contactXid)}
              className="form-control"
              variant="outlined"
            />
          </td>

          <td className="col-md" style={{ width: "18%" }}>
            <NativeSelect
              id={"contactType" + contact.contactXid}
              className="form-control"
              name="ContactType"
              title={hoverText}
              defaultValue={contact.jobTitle}
              onChange={this.handleChange.bind(this, contact.contactXid)}
            >
              <option
                value="RELEASE_SUMMARY"
                selected={"RELEASE_SUMMARY" === contact.jobTitle ? true : false}
              >
                RELEASE_SUMMARY
              </option>
              <option
                value="TENDER"
                selected={"TENDER" === contact.jobTitle ? true : false}
              >
                TENDER
              </option>
              <option
                value="WAREHOUSE"
                selected={"WAREHOUSE" === contact.jobTitle ? true : false}
              >
                WAREHOUSE
              </option>
            </NativeSelect>
          </td>

          <td className="col-md">
            <table style={{ width: "100%" }}>
              <tr>
                <td>
                  <FlagableTextField
                    name="EmailAddress"
                    className="form-control"
                    value={contact.emailAddress}
                    id={"emailAddress" + contact.contactXid}
                    onChange={this.handleChange.bind(this, contact.contactXid)}
                    variant="outlined"
                    style={{ width: "100%" }}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  {!isValidEmail(contact.emailAddress) && (
                    <span
                      id="contactEmailError"
                      style={{ fontWeight: "bold", color: "red" }}
                    >
                      Email is not valid.
                    </span>
                  )}
                </td>
              </tr>
            </table>
          </td>

          <td className="col-md" style={{ width: "12%" }}>
            <table>
              <tr>
                <td>
                  <FlagableTextField
                    name="PhoneNumber"
                    className="form-control"
                    value={contact.phone1}
                    id={"phoneNumber" + contact.contactXid}
                    onChange={this.handleChange.bind(this, contact.contactXid)}
                    variant="outlined"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  {!isValidPhone(contact.phone1) && (
                    <span
                      id="contactPhoneNumberError"
                      style={{ fontWeight: "bold", color: "red" }}
                    >
                      Phone Number <br />
                      is 10 digits.
                    </span>
                  )}
                </td>
              </tr>
            </table>
          </td>

          <td className="col-md" style={{ width: "7%" }}>
            <button
              type="button"
              id={"deleteRow" + contact.contactXid}
              onClick={this.handleRemoveSpecificRow(contact.contactXid)}
              name="DeleteContact"
              className="btn btn-danger"
            >
              Delete
            </button>
          </td>
        </tr>
      ) : (
        ""
      );
    });

    return (
      <div className="pageContent-style MuiPaper-root MuiAccordion-root Mui-expanded MuiAccordion-rounded MuiPaper-elevation1 MuiPaper-rounded">
        <div
          className="MuiCollapse-container MuiCollapse-entered"
          style={{
            height: "auto",
            minHeight: "0px",
            transitionDuration: "327ms",
          }}
        >
          <div className="pageContent-style MuiCollapse-wrapper">
            <div className="MuiCollapse-wrapperInner pageContent-style">
              <div id="pannel1a-content" role="region">
                <div className="MuiAccordionDetails-root">
                  <div>
                    <table className="table">
                      <thead>
                        <tr>
                          {this.state.tableHeaders.map(function (
                            headerText: any
                          ) {
                            return (
                              <th>
                                {" "}
                                {headerText}
                                {headerText === "Contact Type" ? (
                                  <Tooltip
                                    disableFocusListener
                                    disableTouchListener
                                    title={
                                      <div style={{ whiteSpace: "pre-line" }}>
                                        {
                                          "RELEASE_SUMMARY : Person that will receive a summary report on order release creation \n\n TENDER: Person that will receive bill of lading and instructions when shipment is assigned to carrier \n\n WAREHOUSE: Person that a carrier will contact when scheduling pick up appointments"
                                        }
                                      </div>
                                    }
                                  >
                                    <InfoIcon />
                                  </Tooltip>
                                ) : (
                                  ""
                                )}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.showContactDetails
                          ? list
                          : "No data to display "}
                      </tbody>
                    </table>
                    <div>
                      <button
                        type="button"
                        id="addContact"
                        onClick={this.handleAddRow}
                        className="btn btn-primary"
                      >
                        Add Contact
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
