import { DialogContentText, DialogTitle, Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import {
  isInvalidMaxPickupNumber,
  isInvalidMaxWareHouseRemarks} from "../../helper/HelperFunctions";
import { hasInvalidShipQuantities, isDivisibleShipQuantity, isLessThanRemainingQuantity } from "../../helper/ShipUnitUtilities";
import {
  isInvalidShipQuantityValue,
  isInvalidSlipSheetsValue,
  hasInvalidShipUnits,
  hasInvalidSlipSheets,
  hasInvalidPalletSpaces
} from "../../helper/ShipUnitUtilities";
import { hasInvalidTotals } from "../../helper/ShipUnitUtilities";
import { getJsonResult, put } from "../../services/FetchServices";
import Location from "../../types/Location";
import PoItem from "../../types/PoItem";
import PoItemShipUnit from "../../types/PoItemShipUnit";
import PurchaseOrderRelease from "../../types/PurchaseOrderRelease";
import SearchPurchaseOrderRelease from "../../types/SearchPurchaseOrderRelease";
import { DropDown } from "../dropDown/DropDown";
import PoItemShipUnitPage from "./PoItemShipUnitPage";
import { FlagableTextField, focusOnField } from "./FlagableTextField";
import { isInvalidPalletSpacesValue } from "../../helper/ShipUnitUtilities";

interface IState {
  purchaseOrderRelease?: PurchaseOrderRelease;
  searchPurchaseOrder?: SearchPurchaseOrderRelease;
}

const PurchaseOrderReleasePage: React.FC = () => {
  const history = useHistory();
  const purchaseOrderRelease = useLocation();
  const purchaseOrder = (purchaseOrderRelease?.state as IState)
    ?.purchaseOrderRelease;
  const searchPurchaseOrder = (purchaseOrderRelease?.state as IState)
    ?.searchPurchaseOrder;
  const [vendorId, setVendorId] = useState("");
  const [orderBaseGid, setOrderBaseGid] = useState("");
  const [orderReleaseGid, setOrderReleaseGid] = useState("");
  const [shipFrom, setShipFrom] = useState("");
  const [shipTo, setShipTo] = useState("");
  const [shipToAddress, setShipToAddress] = useState("");
  const [freightTerms, setFreightTerms] = useState("");
  const [earlyProductReadyDate, setEarlyProductReadyDate] = useState<Date | null>(null);
  const [earlyProductReadyDateMinimum, setEarlyProductReadyDateMinimum] = useState<Date | null>(null);
  const [lateProductReadyDate, setLateProductReadyDate] = useState<Date | null>(
    null
  );
  const [earlyShipPoDate, setEarlyShipPoDate] = useState<Date | null>(null);
  const [earlyShipDate, setEarlyShipDate] = useState<Date | null>(null);
  const [earlyShipPoDateDup, setEarlyShipPoDateDup] = useState<Date | null>(null);
  const [earlyProductReadyDateStart, setEarlyProductReadyDateStart] =
    useState<Date>(new Date());
  const [earlyProductReadyDateEnd, setEarlyProductReadyDateEnd] =
    useState<Date>(new Date());
  const [lateShipPoDate, setLateShipPoDate] = useState<Date | null>(null);
  const [lateShipPoDateMax, setLateShipPoDateMax] = useState<Date | null>(null);
  const [shipFromOverride, setShipFromOverride] = useState("");
  const [shipFromOverrides, setShipFromOverrides] = useState([]);
  const [pickupNumber, setPickupNumber] = useState("");
  const [poItems, setPoItems] = useState<PoItem[]>([]);
  const [planToLocationGid, setPlanToLocationGid] = useState("");
  const nfmcClasses = [
    "50.0",
    "55.0",
    "60.0",
    "65.0",
    "70.0",
    "77.5",
    "85.0",
    "92.5",
    "100.0",
    "110.0",
    "125.0",
    "150.0",
    "175.0",
    "200.0",
    "250.0",
    "300.0",
    "400.0",
    "500.0",
  ];
  const hazmats = ["No", "Yes"];
  const [poItemShipUnits, setPoItemShipUnits] = useState<PoItemShipUnit[]>([]);
  const [shippingOriginRemarks, setShippingOriginRemarks] = useState("");
  const [wareHouseRemarks, setWareHouseRemarks] = useState("");
  const [lateReleaseDateOffset, setLateReleaseDateOffset] = useState(2);
  const [hasValidationErrors, setHasValidationErrors] = useState(false);
  const [hasShipQuantityValidationErrors, setHasShipQuantityValidationErrors] =
    useState(false);
  const [isEarlyProductReadyDateNotValid, setEarlyProductReadyDateNotValid] =
    useState(false);
  const [sourceLocationTimeZoneOffset, setSourceLocationTimeZoneOffset] =
    useState("");
  const [openSuccessAlert, setSuccessAlertOpen] = useState(false);
  const [openFailureAlert, setFailureAlertOpen] = useState(false);
  const [failureDailogContent, setFailureDailogContent] = useState("");

  let nfmcClassSet = new Set();
  var authHeader = localStorage.getItem("authHeader");
  var username = localStorage.getItem("userName");

  useEffect(() => {
    setVendorId(localStorage.getItem("vendorId")!);

    if (purchaseOrder !== undefined) {
      setOrderBaseGid(purchaseOrder?.orderBaseGid);
      setShipFrom(purchaseOrder?.shipFrom);
      setFreightTerms(purchaseOrder?.freightTerms);

      const startOfEarlyShipPoDate: Date = new Date(purchaseOrder.earlyShipPoDate);
      startOfEarlyShipPoDate.setHours(0, 0, 0, 0);
      const startOfToday: Date = new Date();
      startOfToday.setHours(0, 0, 0, 0);
      const sixDays: number = 1000 * 60 * 60 * 24 * 8;

      const startOfEarlyShipDate: Date = new Date(purchaseOrder.earlyShipDate);
      startOfEarlyShipDate.setHours(0, 0, 0, 0);
      setEarlyShipDate(startOfEarlyShipDate);

      setLateReleaseDateOffset(purchaseOrder?.lateReleaseDateOffset);

      console.log("if ( startOfToday.getTime(): ["+startOfToday.getTime()+"] >= (startOfEarlyShipDate.getTime() - sixDays: ["+startOfEarlyShipDate.getTime()+"-"+sixDays+"]) ) {");
      var attributeNumber1 = 0;
      if(purchaseOrder.attributeNumber1 && purchaseOrder.attributeNumber1 !== 0){
        attributeNumber1 = purchaseOrder.attributeNumber1;
      } else if(purchaseOrder.leadTimeInDays && purchaseOrder.leadTimeInDays !== 0){
        attributeNumber1 = purchaseOrder.leadTimeInDays;
      }

      if (purchaseOrder.freightTerms.split(".")[1].toLowerCase()==="collect") {
        // Default Date
        var tempEarlyShipDate1: Date = new Date();
        tempEarlyShipDate1.setHours(0, 0, 0, 0);
        var attributeNumber3 = 0;
        if (purchaseOrder?.attributeNumber3 > 0){
          attributeNumber3 = purchaseOrder?.attributeNumber3;
        }
        tempEarlyShipDate1.setDate(startOfToday.getDate() + purchaseOrder?.attributeNumber3 + 1);
        if (startOfEarlyShipDate.getTime() > tempEarlyShipDate1.getTime()){
          setEarlyProductReadyDate(startOfEarlyShipDate);
          setEarlyProductReadyDateStart(startOfEarlyShipDate);
          setLateProductReadyDate(
            addBusinessDays(startOfEarlyShipDate, purchaseOrder?.lateReleaseDateOffset)
          );
        }else {
          setEarlyProductReadyDate (tempEarlyShipDate1);
          setEarlyProductReadyDateStart(tempEarlyShipDate1);
          setLateProductReadyDate(
            addBusinessDays(tempEarlyShipDate1, purchaseOrder?.lateReleaseDateOffset)
          );
        }

        tempEarlyShipDate1 = new Date(purchaseOrder.earlyShipPoDate);
        if(attributeNumber1 > -2){
          setEarlyProductReadyDateEnd(new Date(purchaseOrder.latePickupDateMax));
        } else {
          tempEarlyShipDate1.setDate(tempEarlyShipDate1.getDate() - attributeNumber1);
          setEarlyProductReadyDateEnd(tempEarlyShipDate1);
        }
      } else {
        var tempEarlyShipDate1: Date = new Date();
        tempEarlyShipDate1.setHours(0, 0, 0, 0);
        tempEarlyShipDate1.setDate(startOfToday.getDate() + 1);
        if (startOfEarlyShipDate.getTime() > tempEarlyShipDate1.getTime()){
          setEarlyProductReadyDate(startOfEarlyShipDate);
          setEarlyProductReadyDateStart(startOfEarlyShipDate);
          setLateProductReadyDate(
            addBusinessDays(startOfEarlyShipDate, purchaseOrder?.lateReleaseDateOffset)
          );
        }else {
          setEarlyProductReadyDate (tempEarlyShipDate1);
          setEarlyProductReadyDateStart(tempEarlyShipDate1);
          setLateProductReadyDate(
            addBusinessDays(tempEarlyShipDate1, purchaseOrder?.lateReleaseDateOffset)
          );
        }

        tempEarlyShipDate1 = new Date(purchaseOrder.earlyShipPoDate);
        tempEarlyShipDate1.setHours(0, 0, 0, 0);
        tempEarlyShipDate1.setDate(tempEarlyShipDate1.getDate() - attributeNumber1);
        setEarlyProductReadyDateEnd(tempEarlyShipDate1);
      }

      var tempMinDate: Date = new Date(purchaseOrder?.earlyProductReadyDate).getTime() > new Date().getTime() ? new Date() : new Date(purchaseOrder?.earlyProductReadyDate);
      setEarlyProductReadyDateMinimum(tempMinDate);
      setEarlyShipPoDate(new Date(purchaseOrder?.earlyShipPoDate));
      setShipTo(purchaseOrder?.shipTo);
      getShipTo(purchaseOrder?.shipTo);
      getShipFromOverrides(localStorage.getItem("vendorId")!);
      setPoItems(purchaseOrder?.items);
      getOrderReleaseGid(purchaseOrder?.orderBaseGid);
      setPlanToLocationGid(purchaseOrder?.planToLocationGid);

      let lateShipPoDateMaxDup = lateShipPoDateMax;
      purchaseOrder?.items.map((item: PoItem) => {
        if (lateShipPoDateMaxDup === null || lateShipPoDateMaxDup < new Date(item.latePickupDate)) {
          lateShipPoDateMaxDup = new Date(item.latePickupDate);
        }
      });
      setLateShipPoDate(lateShipPoDateMaxDup);

      let earlyShipPoDateMinDup = earlyShipPoDateDup;
      purchaseOrder?.items.map((item: PoItem) => {
        if (earlyShipPoDateMinDup === null || earlyShipPoDateMinDup > new Date(item.earlyPickupDate)) {
          earlyShipPoDateMinDup = new Date(item.earlyPickupDate);
        }
      });
      setEarlyShipPoDate(earlyShipPoDateMinDup);
    }
  }, [purchaseOrder]);

  const closeSuccessAlertDialog = () => {
    setSuccessAlertOpen(false);
    history.push("/searchPurchaseOrderRelease");
  };

  const closeFailureAlertDialog = () => {
    setFailureAlertOpen(false);
  };

  const setLateProductReadyDateWithOffset = (date: Date | null) => {
    setLateProductReadyDate(
      addBusinessDays(date ? date : new Date(), lateReleaseDateOffset)
    );
  };

  const addBusinessDays = (date: Date, days: number) => {
    date = new Date(date.getTime());
    var day = date.getDay();
    date.setDate(
      date.getDate() +
      days +
      (day === 6 ? 2 : +!day) +
      Math.floor((days - 1 + (day % 6 || 1)) / 5) * 2
    );
    return date;
  };

  const getShipFromOverrides = (vendorId: string) => {
    var shipFromOverridesUrl =
      `${process.env.REACT_APP_BACKEND_URL}/po/getShipFromOverrides?authHeader=` +
      authHeader +
      "&vendorId=" +
      vendorId;
    getJsonResult(shipFromOverridesUrl)
      .then((response) => response.json())
      .then((data) => {
        setShipFromOverrides(data);
      });
  };

  const getShipTo = (shipTo: string) => {
    var shipToUrl =
      `${process.env.REACT_APP_BACKEND_URL}/po/shipTo?authHeader=` +
      authHeader +
      "&shipTo=" +
      shipTo;
    getJsonResult(shipToUrl)
      .then((response) => response.json())
      .then((data) => setShipToAddress(data[0]));
  };

  const getLocationTimeZoneOffset = (locationGid: string) => {
    var locationTimeZoneOffsetUrl =
      `${process.env.REACT_APP_BACKEND_URL}/po/locationTimeZoneOffset?authHeader=` +
      authHeader +
      "&locationGid=" +
      locationGid;
    getJsonResult(locationTimeZoneOffsetUrl)
      .then((response) => response.json())
      .then((data) => setSourceLocationTimeZoneOffset(data[0]));
  };

  const getOrderReleaseGid = (orderBaseGid: string) => {
    var orderReleaseGidUrl =
      `${process.env.REACT_APP_BACKEND_URL}/OrderRelease/orderReleaseGid?authHeader=` +
      authHeader +
      "&orderBaseGid=" +
      orderBaseGid;
    getJsonResult(orderReleaseGidUrl)
      .then((response) => response.json())
      .then((data) => setOrderReleaseGid(data[0]));
  };

  const handleShipFromOverride = (e: any) => {
    var targetValue = e.target.value;
    setShipFromOverride(targetValue);
    var locationGId = "FD." + targetValue.split("^")[0];
    var locationUrl =
      `${process.env.REACT_APP_BACKEND_URL}/findLocation/getLocation?authHeader=` +
      authHeader +
      "&locationGId=" +
      locationGId;
    getJsonResult(locationUrl)
      .then((response) => response.json())
      .then((data: Location) => {
        setShippingOriginRemarks(data.remark);
      });
    getLocationTimeZoneOffset(locationGId);
  };

  const handleShipingOriginRemarks = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setShippingOriginRemarks(e.target.value);
  };

  const handlePickupNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPickupNumber(
      e.target.value.replace(/[^a-zA-Z0-9-,.;=+()!@#$%{}/]/g, "")
    );
  };

  const handleShipQuantityValidation = () => {
    var hasErrors = poItems?.some((item: PoItem) =>
      isInvalidShipQuantityValue(item)
    );
    setHasShipQuantityValidationErrors(hasErrors);
  };

  const handleShipQuantityOnBlur = (e: any) => {
    const itemId: string = e.target
      .getAttribute("id")
      .replace("shipQuantity", "");
    const newPoItems: PoItem[] = [...poItems];
    const item: PoItem = newPoItems.find((i) => i.itemId === itemId)!;
    item.shipQuantityWithCommas = false;
    setPoItems(newPoItems);
  };

  const handleShipQuantityOnFocus = (e: any) => {
    const itemId: string = e.target
      .getAttribute("id")
      .replace("shipQuantity", "");
    const newPoItems: PoItem[] = [...poItems];
    const item: PoItem = newPoItems.find((i) => i.itemId === itemId)!;
    item.shipQuantityWithCommas = true;
    setPoItems(newPoItems);
  };

  const handleShipQuantity = (e: any) => {
    const qty = e.target.value.replace(/[^0-9\s]/g, "");
    const itemId: string = e.target
      .getAttribute("id")
      .replace("shipQuantity", "");
    const newPoItems: PoItem[] = [...poItems];
    const item: PoItem = newPoItems.find((i) => i.itemId === itemId)!;
    item.shipQuantity = qty;
    if (qty % item.quantityPerCarton === 0)
      item.cartons = qty / item.quantityPerCarton;
    else item.cartons = Math.ceil(qty / item.quantityPerCarton);
    setPoItems(newPoItems);
    var i = 0;
    var shipUnitsArray = new Array<PoItemShipUnit>();
    var volume = 0;
    var weight = 0;
    poItems.forEach((row) => {
      if (row.shipQuantity > 0) {
        if (!nfmcClassSet.has(row.nfmc)) {
          volume = parseFloat(
            (row.shipQuantity * row.attributeNumber2).toFixed(2)
          );
          weight = parseFloat(
            (row.shipQuantity * row.attributeNumber1).toFixed(2)
          );
          shipUnitsArray.push(
            new PoItemShipUnit(
              i.toString(),
              "PALLET",
              0,
              0,
              volume,
              weight,
              row.cartons,
              row.nfmc,
              volume,
              true,
              true,
              false
            )
          );
          i++;
          nfmcClassSet.add(row.nfmc);
        } else {
          const index = shipUnitsArray.findIndex(
            (e) => e.nfmcClass === row.nfmc
          );
          volume = parseFloat(
            (
              shipUnitsArray[index].volume +
              row.shipQuantity * row.attributeNumber2
            ).toFixed(2)
          );
          weight = parseFloat(
            (
              shipUnitsArray[index].weight +
              row.shipQuantity * row.attributeNumber1
            ).toFixed(2)
          );
          shipUnitsArray[index] = new PoItemShipUnit(
            index.toString(),
            shipUnitsArray[index].loadMethod,
            0,
            0,
            volume,
            weight,
            shipUnitsArray[index].cartons + row.cartons,
            row.nfmc,
            volume,
            true,
            true,
            false
          );
        }
      }
    });
    setPoItemShipUnits(shipUnitsArray);
    handleShipQuantityValidation();
  };

  const handleNfmc = (e: any) => {
    const itemId: string = e.target.getAttribute("id").replace("nfmc", "");
    const newPoItems: PoItem[] = [...poItems];
    const item: PoItem = newPoItems.find((i) => i.itemId === itemId)!;
    item.nfmc = e.target.value;
    setPoItems(newPoItems);
    var i = 0;
    var shipUnitsArray = new Array<PoItemShipUnit>();
    var volume = 0;
    var weight = 0;
    poItems.forEach((row) => {
      if (row.shipQuantity > 0) {
        if (!nfmcClassSet.has(row.nfmc)) {
          volume = parseFloat(
            (row.shipQuantity * row.attributeNumber2).toFixed(2)
          );
          weight = parseFloat(
            (row.shipQuantity * row.attributeNumber1).toFixed(2)
          );
          shipUnitsArray.push(
            new PoItemShipUnit(
              i.toString(),
              "PALLET",
              0,
              0,
              volume,
              weight,
              row.cartons,
              row.nfmc,
              volume,
              true,
              true,
              false
            )
          );
          i++;
          nfmcClassSet.add(row.nfmc);
        } else {
          const index = shipUnitsArray.findIndex(
            (e) => e.nfmcClass === row.nfmc
          );
          volume = parseFloat(
            (
              shipUnitsArray[index].volume +
              row.shipQuantity * row.attributeNumber2
            ).toFixed(2)
          );
          weight = parseFloat(
            (
              shipUnitsArray[index].weight +
              row.shipQuantity * row.attributeNumber1
            ).toFixed(2)
          );
          shipUnitsArray[index] = new PoItemShipUnit(
            index.toString(),
            shipUnitsArray[index].loadMethod,
            0,
            0,
            volume,
            weight,
            shipUnitsArray[index].cartons + row.cartons,
            row.nfmc,
            volume,
            true,
            true,
            false
          );
        }
      }
    });
    setPoItemShipUnits(shipUnitsArray);
  };
  const handleHazmat = (e: any) => {
    const itemId: string = e.target.getAttribute("id").replace("hazmat", "");
    const newPoItems: PoItem[] = [...poItems];
    const item: PoItem = newPoItems.find((i) => i.itemId === itemId)!;
    item.hazmat = e.target.value;
    setPoItems(newPoItems);
  };

  const handleWareHouseRemarks = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setWareHouseRemarks(
      e.target.value.replace(/[^a-zA-Z0-9-,.;=+()!@#$%{}/\s]/g, "")
    );
  };

  const convertDate = (dateTime: Date, timeZone: string) => {
    let date = ("0" + dateTime.getDate()).slice(-2);
    let month = ("0" + (dateTime.getMonth() + 1)).slice(-2);
    let year = dateTime.getFullYear();
    let covertedDate = year + "-" + month + "-" + date + timeZone;
    return covertedDate;
  };

  let itemLineNumber = 0;
  const getShipUnitLines = (nfmc: string, shipUnitGid: string) => {
    let shipUnitLines: any = [];
    let shipUnitLineNumber = 0;
    poItems.forEach((row: PoItem) => {
      if (row.shipQuantity > 0 && row.nfmc === nfmc) {
        shipUnitLines.push({
          transactionCode: "IU",
          shipUnitLineBeanData: {
            shipUnitGid: shipUnitGid,
            shipUnitLineNo: shipUnitLineNumber + 1,
            orderReleaseLineGid: orderReleaseGid + "-" + itemLineNumber,
            packagedItemGid: row.itemId,
            obLineGid: row.obLineGid,
          },
        });
        shipUnitLineNumber++;
        itemLineNumber++;
      }
    });
    return shipUnitLines;
  };

  const finishOrderRelease = () => {
    itemLineNumber = 0;
    let domain = username?.substring(0, 2);
    let operation = "IU";
    let orderReleaseLines: any = [];
    let orderReleaseLineNumber = 0;
    poItems.forEach((row: PoItem) => {
      if (row.shipQuantity > 0) {
        orderReleaseLines.push({
          transactionCode: operation,
          orderReleaseLineBeanData: {
            orderReleaseLineGid: orderReleaseGid + "-" + orderReleaseLineNumber,
            orderReleaseLineXid: (
              orderReleaseGid +
              "-" +
              orderReleaseLineNumber
            ).split(".")[1],
            orderReleaseGid: orderReleaseGid,
            itemPackageCount: Number(row.shipQuantity),
            packagingUnitCount: row.quantityPerCarton,
            isHazardous: row.hazmat === "Yes" ? true : false,
            declaredValue: { value: 0, uomCode: "USD" },
            packagedItemGid: row.itemId,
            packagingUnitGid: null,
            transportHandlingUnitGid: null,
            freeAlongSide: { value: 0, uomCode: "USD" },
            hazNetExplosvContentWeight: { value: 0, uomCode: "LB" },
            pricePerUnit: { value: 0, uomCode: "USD" },
            totalBilledAmt: { value: 0, uomCode: "USD" },
            obLineGid: row.obLineGid,
            orderBaseGid: orderBaseGid,
            domainName: domain,
          },
        });
        orderReleaseLineNumber++;
      }
    });

    let shipUnits: any = [];
    poItemShipUnits.forEach((shipUnit: PoItemShipUnit) => {
      let shipUnitGid = orderReleaseGid + "-" + shipUnit.shipUnitId;
      shipUnits.push({
        transactionCode: operation,
        shipUnitBeanData: {
          shipUnitGid: shipUnitGid,
          shipUnitXid: shipUnitGid.split(".")[1],
          transportHandlingUnitGid: domain + "." + shipUnit.loadMethod,
          unitWeight: {
            value:
              shipUnit.palletSlipSheets > 0
                ? shipUnit.weight / shipUnit.palletSlipSheets
                : shipUnit.weight / shipUnit.cartons, // floor load method
            uomCode: "LB",
          },
          unitVolume: {
            value:
              shipUnit.palletSlipSheets > 0
                ? shipUnit.volume / shipUnit.palletSlipSheets
                : shipUnit.volume / shipUnit.cartons, // floor load method
            uomCode: "CUFT",
          },
          orderReleaseGid: orderReleaseGid,
          shipUnitCount:
            shipUnit.palletSlipSheets > 0
              ? Number(shipUnit.palletSlipSheets)
              : shipUnit.cartons,
          totalGrossWeight: {
            value: shipUnit.weight / 1,
            uomCode: "LB",
          },
          totalGrossVolume: {
            value: shipUnit.volume / 1,
            uomCode: "CUFT",
          },
          attributeNumber1: Number(shipUnit.palletSpaces),
          unitNetWeight: {
            value:
              shipUnit.palletSlipSheets > 0
                ? shipUnit.weight / shipUnit.palletSlipSheets
                : shipUnit.weight / shipUnit.cartons,
            uomCode: "LB",
          },
          unitNetVolume: {
            value:
              shipUnit.palletSlipSheets > 0
                ? shipUnit.volumeBkp / shipUnit.palletSlipSheets
                : shipUnit.volumeBkp / shipUnit.cartons,
            uomCode: "CUFT",
          },
          flexCommodityCode: shipUnit.nfmcClass,
          flexCommodityQualGid: "NMFC_CLASS",
          domainName: domain,
          shipUnitLine: getShipUnitLines(shipUnit.nfmcClass, shipUnitGid),
        },
      });
    });

    let orderJson = {
      Order: {
        orderReleaseGid: orderReleaseGid,
        orderBaseGid: orderBaseGid,
        orderReleaseXid: orderReleaseGid.split(".")[1],
        sourceLocationGid: domain + "." + shipFromOverride.split("^")[0],
        destLocationGid: shipTo,
        ...(planToLocationGid !== "" && {
          planToLocationGid: planToLocationGid,
        }),
        earlyPickupDate: {
          date:
            earlyProductReadyDate !== null
              ? convertDate(
                earlyProductReadyDate,
                "T01:01:00.000" + sourceLocationTimeZoneOffset
              )
              : null,
        },
        latePickupDate: {
          date:
            lateProductReadyDate !== null
              ? convertDate(
                lateProductReadyDate,
                "T22:59:00.000" + sourceLocationTimeZoneOffset
              )
              : null,
        },
        earlyDeliveryDate: {
          date:
            earlyShipPoDate !== null
              ? convertDate(
                earlyShipPoDate,
                "T01:01:00.000" +
                purchaseOrder?.destinationLocatonTimeZoneOffset
              )
              : null,
        },
        lateDeliveryDate: {
          date:
            lateShipPoDate !== null
              ? convertDate(
                lateShipPoDate,
                "T22:59:00.000" +
                purchaseOrder?.destinationLocatonTimeZoneOffset
              )
              : null,
        },
        totalWeight: {
          value: poItemShipUnits.reduce(function (prev, cur) {
            return parseInt(prev + cur.weight + "");
          }, 0),
          uomCode: "LB",
        },
        totalVolume: {
          value: poItemShipUnits.reduce(function (prev, cur) {
            return parseInt(prev + cur.volume + "");
          }, 0),
          uomCode: "CUFT",
        },
        totalItemPackageCount: poItemShipUnits.reduce(function (prev, cur) {
          return prev + cur.cartons;
        }, 0),
        releaseMethodGid: "SIMPLE",
        locAmount: {
          value: 0,
          uomCode: "USD",
        },
        totalDeclaredValue: {
          value: 0,
          uomCode: "USD",
        },
        planningGroupGid: freightTerms,
        attributeNumber1: poItemShipUnits.reduce(function (prev, cur) {
          return Number(prev) + Number(cur.palletSpaces);
        }, 0),
        domainName: domain,
        attribute1: vendorId,
        attribute9: "",
        shipUnit: shipUnits,
        orderReleaseLine: orderReleaseLines,
        ...(wareHouseRemarks &&
          wareHouseRemarks.trim() && {
          orderReleaseRemark: [
            {
              transactionCode: "I",
              orderReleaseRemarkBeanData: {
                orderReleaseGid: orderReleaseGid,
                remarkSequence: 0,
                remarkQualGid: "FD.REMARK",
                remarkText: wareHouseRemarks,
                domainName: domain,
              },
            },
          ],
        }),
        ...(pickupNumber &&
          pickupNumber.trim() && {
          orderReleaseRefnum: [
            {
              transactionCode: "I",
              orderReleaseRefnumBeanData: {
                orderReleaseGid: orderReleaseGid,
                orderReleaseRefnumQualGid: "FD.PICKUP_NUMBER",
                orderReleaseRefnumValue: pickupNumber,
                domainName: domain,
              },
            },
          ],
        }),
      },
    };
    if ( poItemShipUnits.some(shipUnit => shipUnit.oversized)) {
      orderJson.Order["attribute9"] = "OVERSIZED_PALLET";
    }
    var finishOrderReleaseUrl =
      `${process.env.REACT_APP_BACKEND_URL}/po/saveOrderRelease?authHeader=` +
      authHeader +
      "&orderReleaseGid=" +
      orderReleaseGid;
    put(finishOrderReleaseUrl, orderJson)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          return response.text();
        } else {
          throw response;
        }
      })
      .then((data) => {
        setSuccessAlertOpen(true);
      })
      .catch((error) => {
        error.text().then((errMessage: any) => {
          setFailureDailogContent(
            "Error when finishing OrderRelease:\n" + errMessage
          );
        });
        setFailureAlertOpen(true);
      });
  };

  const validationHandler = (hasErrors: boolean) => {
    setHasValidationErrors(hasErrors);
  };

  const shipComplete = () => {
    const newPoItems: PoItem[] = [...poItems];
    newPoItems.forEach((item) => {
      if (item.remainingQuantity > 0) {
        item.shipQuantity = item.remainingQuantity;
        if (item.shipQuantity % item.quantityPerCarton === 0)
          item.cartons = item.shipQuantity / item.quantityPerCarton;
        else
          item.cartons = Math.ceil(item.shipQuantity / item.quantityPerCarton);
      }
    });
    setPoItems(newPoItems);
    var i = 0;
    var shipUnitsArray = new Array<PoItemShipUnit>();
    var volume = 0;
    var weight = 0;
    poItems.forEach((row) => {
      if (row.shipQuantity > 0) {
        if (!nfmcClassSet.has(row.nfmc)) {
          volume = parseFloat(
            (row.shipQuantity * row.attributeNumber2).toFixed(2)
          );
          weight = parseFloat(
            (row.shipQuantity * row.attributeNumber1).toFixed(2)
          );
          shipUnitsArray.push(
            new PoItemShipUnit(
              i.toString(),
              "PALLET",
              0,
              0,
              volume,
              weight,
              row.cartons,
              row.nfmc,
              volume,
              true,
              true,
              false
            )
          );
          i++;
          nfmcClassSet.add(row.nfmc);
        } else {
          const index = shipUnitsArray.findIndex(
            (e) => e.nfmcClass === row.nfmc
          );
          volume = parseFloat(
            (
              shipUnitsArray[index].volume +
              row.shipQuantity * row.attributeNumber2
            ).toFixed(2)
          );
          weight = parseFloat(
            (
              shipUnitsArray[index].weight +
              row.shipQuantity * row.attributeNumber1
            ).toFixed(2)
          );
          shipUnitsArray[index] = new PoItemShipUnit(
            index.toString(),
            shipUnitsArray[index].loadMethod,
            0,
            0,
            volume,
            weight,
            shipUnitsArray[index].cartons + row.cartons,
            row.nfmc,
            volume,
            true,
            true,
            false
          );
        }
      }
    });
    setPoItemShipUnits(shipUnitsArray);
  };

  const cancel = () => {
    history.push({
      pathname: "/searchPurchaseOrderRelease",
      state: { searchPurchaseOrder: searchPurchaseOrder },
    });
  };

  const validateEarlyShipReleaseDate = (
    newValue: Date | null,
    earlyShipReleaseDateStart: Date,
    earlyShipReleaseDateEnd: Date
  ) => {
    setEarlyProductReadyDateNotValid(
      newValue !== null
        ? !(
          newValue >= earlyShipReleaseDateStart &&
          newValue <= earlyShipReleaseDateEnd
        )
        : true
    );
  };

  const isFormConflicted = () => {
    return (
      shipFromOverride.length === 0 ||
      isEarlyProductReadyDateNotValid ||
      hasValidationErrors ||
      hasShipQuantityValidationErrors ||
      poItemShipUnits.length === 0 ||
      isInvalidMaxPickupNumber(pickupNumber) ||
      isInvalidMaxWareHouseRemarks(wareHouseRemarks) ||
      hasInvalidShipUnits(poItemShipUnits) ||
      hasInvalidTotals(poItemShipUnits)
    );
  };

  const goToNextInvalidField = () => {
    if (shipFromOverride.length == 0) {
      focusOnField("shipFromOverride");
    }
    else if ( hasInvalidShipQuantities(poItems)) {
      focusOnField("shipQuantity" + poItems?.find(isInvalidShipQuantityValue)?.itemId)
    }
    else if (hasInvalidSlipSheets(poItemShipUnits) ) {
      focusOnField("palletSlipSheets" + poItemShipUnits?.find(isInvalidSlipSheetsValue)?.shipUnitId);
    }
    else if ( hasInvalidPalletSpaces(poItemShipUnits) ) {
      focusOnField("palletSpaces" + poItemShipUnits?.find(isInvalidPalletSpacesValue)?.shipUnitId);
    }
  };

  return (
    <section className="sectionHeadingPadding">
      <div
        id="purchaseOrderReleasePage"
        style={{ margin: "auto", width: "100%" }}
      >
        <div className="container">
          <div
            className="intro"
            style={{ width: "80%", marginRight: "auto", marginLeft: "auto" }}
          >
            <div
              id="purchaseOrderReleaseManager"
              className="section-heading mb-4"
            >
              <span
                className="section-heading-lower"
                style={{
                  fontSize: "2rem",
                  textAlign: "center",
                  color: "#6b3a06",
                  fontWeight: 500,
                  paddingBottom: "20px",
                }}
              >
                READY TO SHIP MANAGER
              </span>
            </div>
            <form>
              <div style={{ display: "flex" }}>
                <div style={{ width: "50%" }}>
                  <div className="form-group py-2">
                    <TextField
                      className="form-control"
                      id="vendorId"
                      value={vendorId}
                      variant="outlined"
                      label="Vendor ID"
                      disabled
                    />
                  </div>
                  <div className="form-group py-2">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        disableFuture
                        label="Early PO Ship Date"
                        views={["year", "month", "day"]}
                        value={earlyShipPoDate}
                        disableOpenPicker={true}
                        onChange={() => {}}
                        renderInput={(params : any) => (
                          <TextField
                            {...params}
                            id="earlyShipPoDate"
                            className="form-control"
                            helperText={null}
                          />
                        )}
                        disabled
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="form-group py-2">
                    <div
                      className='text-danger'
                      style={{
                        position: 'absolute',
                        width: '400px',
                        left: '-330px',
                        display: ( ( earlyProductReadyDate?.getTime() || 0 ) > (lateShipPoDate?.getTime() || 0) )?'block':'none'
                      }}
                    >Please note:The selected Early Product Ready <br />
                     Date is after the Late PO Ship Date and <br />
                     may be subject to an OTIF chargeback penalty</div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="EARLY PRODUCT READY DATE"
                        views={["year", "month", "day"]}
                        value={earlyProductReadyDate}
                        minDate={earlyProductReadyDateMinimum || new Date()}
                        onChange={(newValue : any) => {
                          setEarlyProductReadyDate(newValue);
                          setLateProductReadyDateWithOffset(newValue);
                          validateEarlyShipReleaseDate(
                            newValue,
                            earlyProductReadyDateStart,
                            earlyProductReadyDateEnd
                          );
                        }}
                        shouldDisableDate={(date: Date) =>
                          date < new Date(earlyProductReadyDateStart) ||
                          date > new Date(earlyProductReadyDateEnd)
                        }
                        renderInput={(params : any) => (
                          <TextField
                            {...params}
                            id="earlyShipReleaseDate"
                            className="form-control"
                            helperText={null}
                            onKeyDown={(e) =>{
                              e.preventDefault();
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="form-group py-2">
                    <FlagableTextField
                      className="form-control"
                      id="shipFromOverride"
                      select
                      label="Ship From Location"
                      value={shipFromOverride}
                      onChange={handleShipFromOverride}
                      SelectProps={{
                        native: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={shipFromOverride.length == 0}
                    >
                      <option key="" value=""></option>
                      {shipFromOverrides?.map((code: string) => (
                        <option key={code} value={code}>
                          {code.split("^")[1]}
                        </option>
                      ))}
                    </FlagableTextField>
                  </div>
                  <div className="form-group py-2">
                    <TextField
                      className="form-control"
                      id="shipFrom"
                      value={shipFrom.split(".")[1]}
                      variant="outlined"
                      label="Ship From"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled
                    />
                  </div>
                  <div className="form-group py-2">
                    <TextField
                      className="form-control"
                      id="pickupNumber"
                      value={pickupNumber}
                      onChange={handlePickupNumber}
                      variant="outlined"
                      label="Pickup Number"
                    />
                    <br />
                    {isInvalidMaxPickupNumber(pickupNumber) && (
                      <span
                        id={"pickupNumberError"}
                        style={{
                          fontWeight: "bold",
                          color: "red",
                        }}
                      >
                        Can not exceed 30 characters.
                      </span>
                    )}
                  </div>
                </div>
                <div style={{ width: "50%", marginLeft: "75px" }}>
                  <div className="form-group py-2">
                    <TextField
                      className="form-control"
                      id="purchaseOrderId"
                      value={orderBaseGid.split(".")[1]}
                      variant="outlined"
                      label="Purchase Order ID"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled
                    />
                  </div>
                  <div className="form-group py-2">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        disableFuture
                        label="Late PO Ship Date"
                        views={["year", "month", "day"]}
                        value={lateShipPoDate}
                        disableOpenPicker={true}
                        onChange={() => {}}
                        renderInput={(params : any) => (
                          <TextField
                            {...params}
                            id="lateShipPoDate"
                            className="form-control"
                            helperText={null}
                          />
                        )}
                        disabled
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="form-group py-2">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="LATE PRODUCT READY DATE"
                        views={["year", "month", "day"]}
                        value={lateProductReadyDate}
                        disableOpenPicker={true}
                        onChange={() => {}}
                        renderInput={(params : any) => (
                          <TextField
                            {...params}
                            id="lateShipReleaseDate"
                            className="form-control"
                            helperText={null}
                          />
                        )}
                        disabled
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="form-group py-2">
                    <Tooltip
                      title={
                        <div
                          style={{ whiteSpace: "pre-line", fontSize: "1.4em" }}
                        >
                          {
                            "Collect: Dollar Tree | Family Dollar is responsible for freight. \n\n Prepaid:  Vendor is responsible for freight. \n\n If freight terms are incorrect, please stop and contact your merchant immediately."
                          }
                        </div>
                      }
                    >
                      <TextField
                        className="form-control"
                        id="freightTerms"
                        value={freightTerms.split(".")[1]}
                        variant="outlined"
                        label="Freight Terms"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled
                      />
                    </Tooltip>
                  </div>
                  <div className="form-group py-2">
                    <TextField
                      className="form-control"
                      id="shipTo"
                      value={shipToAddress}
                      variant="outlined"
                      label="Destination"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div
          id="poItemsTable"
          style={{
            whiteSpace: "nowrap",
            margin: "auto",
            textAlign: "center",
            overflow: "auto",
          }}
        >
          <table className="table" style={{ width: "80%", margin: "auto" }}>
            <thead>
              <tr>
                <th>Item ID</th>
                <th>Item description</th>
                <th>
                  Early <br /> Pickup
                </th>
                <th>
                  Late <br /> Pickup
                </th>
                <th>
                  Early <br /> Delivery
                </th>
                <th>
                  Late <br /> Delivery
                </th>
                <th>
                  Ordered <br /> Qty
                </th>
                <th>
                  Remaining <br /> Qty
                </th>
                <th>
                  <button
                    id="shipCompleteButton"
                    type="button"
                    className="btn btn-success"
                    onClick={() => shipComplete()}
                    style={{ backgroundColor: "#006600" }}
                  >
                    Ship Complete
                  </button>{" "}
                  <br />
                  Ship Qty
                </th>
                <th>
                  Qty <br /> Per Carton
                </th>
                <th>Cartons</th>
                <th>NMFC Class</th>
                <th>Hazmat?</th>
              </tr>
            </thead>
            <tbody>
              {poItems.map((item: PoItem) => (
                <tr>
                  <td>{item.itemId.split(".")[1]}</td>
                  <td>{item.itemDescription}</td>
                  <td>{item.earlyPickupDate}</td>
                  <td>{item.latePickupDate}</td>
                  <td>{item.earlyDeliveryDate}</td>
                  <td>{item.lateDeliveryDate}</td>
                  <td>
                    {item.orderedQuantity
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                  <td>
                    {item.remainingQuantity
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                  <td>
                    <FlagableTextField
                      id={"shipQuantity" + item.itemId}
                      name="shipQuantity"
                      className="form-control"
                      value={
                        item.shipQuantityWithCommas
                          ? item.shipQuantity.toString().replace(/,/g, "")
                          : item.shipQuantity
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      onChange={handleShipQuantity}
                      onBlur={handleShipQuantityOnBlur}
                      onFocus={handleShipQuantityOnFocus}
                      disabled={item.remainingQuantity <= 0}
                    />
                    <br />
                    {isDivisibleShipQuantity(item) && (
                      <span
                        id={"shipQuantityError" + item.itemId}
                        style={{
                          fontWeight: "bold",
                          color: "red",
                        }}
                      >
                        Must be divisible <br />
                        by Qty Per Carton.
                      </span>
                    )}
                    {isDivisibleShipQuantity(item) && isLessThanRemainingQuantity(item) && (
                      <br />
                    )}
                    {isLessThanRemainingQuantity(item) && (
                      <span
                        id={"shipQuantityError" + item.itemId}
                        style={{
                          fontWeight: "bold",
                          color: "red",
                        }}
                      >
                        The Ship Qty must not be <br />
                        greater than Remaining Qty.
                      </span>
                    )}
                  </td>
                  <td>
                    {item.quantityPerCarton
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                  <td>
                    {item.cartons
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                  <td>
                    <DropDown
                      codes={nfmcClasses}
                      elementId={"nfmc" + item.itemId}
                      value={item.nfmc}
                      onChange={handleNfmc}
                      defaultValue="70.0"
                    />
                  </td>
                  <td>
                    <TextField
                      className="form-control"
                      id={"hazmat" + item.itemId}
                      name="hazmatDropDown"
                      select
                      value={item.hazmat}
                      defaultValue={"No"}
                      onChange={handleHazmat}
                      SelectProps={{
                        native: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ width: "140px" }}
                    >
                      {hazmats?.map((hazmat) => (
                        <option key={hazmat} value={hazmat}>
                          {hazmat}
                        </option>
                      ))}
                    </TextField>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <br />
        <PoItemShipUnitPage
          poItemShipUnitsProps={poItemShipUnits}
          validationHandler={validationHandler.bind(this)}
        />
      </div>
      <div className="row justify-content-center">
        <div className="col-md" style={{ paddingLeft: "250px", width: "50%" }}>
          <div className="form-group py-2">
            <div className="input-group" style={{ width: "80%" }}>
              <TextField
                id="wareHouseRemarks"
                className="form-control"
                label="Additional Order Release Remarks"
                value={wareHouseRemarks}
                onChange={handleWareHouseRemarks}
                minRows={3}
                multiline
              />
            </div>
            {isInvalidMaxWareHouseRemarks(wareHouseRemarks) && (
              <span
                id={"wareHouseRemarksError"}
                style={{
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                Can not exceed 80 characters.
              </span>
            )}
          </div>
        </div>
        <div className="col-md" style={{ paddingRight: "90px", width: "50%" }}>
          <div className="form-group py-2">
            <div className="input-group" style={{ width: "80%" }}>
              <TextField
                className="form-control"
                id="shippingOriginRemarks"
                variant="outlined"
                label="Default Shipping Origin Remarks"
                value={shippingOriginRemarks}
                onChange={handleShipingOriginRemarks}
                disabled
                minRows={3}
                multiline
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center m-xl-2">
        <button
          id="goToErrorsButton"
          type="button"
          className="btn"
          style={{
            width: "auto"
          }}
          disabled={!isFormConflicted()}
          onClick={ () => goToNextInvalidField()}
        >
          {(isFormConflicted()?"Go to errors":"No errors")}
        </button>
      </div>
      <div className="row justify-content-center m-xl-2">
        <button
          id="readyToShipFinishButton"
          type="button"
          className="btn btn-success"
          style={{
            width: "auto",
            marginRight: ".4em"
          }}
          disabled={isFormConflicted()}
          onClick={() => finishOrderRelease()}
        >
          Submit
        </button>
        <button
          id="cancelSearchPOButton"
          className="btn btn-dark"
          style={{
            width: "auto"
          }}
          onClick={() => cancel()}
        >
          Back
        </button>
      </div>
      <div className="row justify-content-center">
        <div
          className="col-md"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <br></br>
          <p>
            <strong>
              By clicking submit, I acknowledge that failure to provide accurate
              and timely information may result in future financial penalties.
            </strong>
          </p>
        </div>
      </div>
      <div id="alertDialog">
        <Dialog open={openSuccessAlert} id="statusDialog">
          <DialogTitle>Success!</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Order {orderReleaseGid.split(".")[1]} created successfully
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              id="statusDialogButton"
              onClick={closeSuccessAlertDialog}
              color="primary"
              autoFocus
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openFailureAlert} id="statusDialog">
          <DialogTitle>Error!</DialogTitle>
          <DialogContent>
            <DialogContentText>{failureDailogContent}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              id="statusDialogButton"
              onClick={closeFailureAlertDialog}
              color="primary"
              autoFocus
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </section>
  );
};

export default PurchaseOrderReleasePage;
