import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface IProps {
  updateRefNumVal(refNumVal: string): void;
  locAvailabilityFromApi: string;
}

export interface IState {
  refNumValue: string;
  startDateSunday: any;
  endDateSunday: any;
  startDateMonday: any;
  endDateMonday: any;
  startDateTuesday: any;
  endDateTuesday: any;
  startDateWednesday: any;
  endDateWednesday: any;
  startDateThursday: any;
  endDateThursday: any;
  startDateFriday: any;
  endDateFriday: any;
  startDateSaturday: any;
  endDateSaturday: any;
  defaultValuesLoaded: boolean;
}
var time: string;

export default class LocationAvailability extends React.Component<
  IProps,
  IState
> {
  constructor(props: IProps) {
    super(props);
    var locAvailability = this.props.locAvailabilityFromApi;
    var sundayTime: any;
    var mondayTime: any;
    var tuesdayTime: any;
    var wednesdayTime: any;
    var thursdayTime: any;
    var fridayTime: any;
    var saturdayTime: any;
    if (locAvailability) {
      var timeArray = locAvailability.split(",").map((day) => day.split(" "));
      for (let i = 0; i < timeArray.length; i++) {
        if (timeArray[i][0] === "Sun") {
          sundayTime = timeArray[i][1].split("-");
        } else if (timeArray[i][0] === "Mon") {
          mondayTime = timeArray[i][1].split("-");
        } else if (timeArray[i][0] === "Tue") {
          tuesdayTime = timeArray[i][1].split("-");
        } else if (timeArray[i][0] === "Wed") {
          wednesdayTime = timeArray[i][1].split("-");
        } else if (timeArray[i][0] === "Thu") {
          thursdayTime = timeArray[i][1].split("-");
        } else if (timeArray[i][0] === "Fri") {
          fridayTime = timeArray[i][1].split("-");
        } else if (timeArray[i][0] === "Sat") {
          saturdayTime = timeArray[i][1].split("-");
        }
      }
    }
    this.state = {
      defaultValuesLoaded: true,
      refNumValue: "",
      startDateSunday: sundayTime
        ? new Date().setHours(
            sundayTime[0].split(":")[0],
            sundayTime[0].split(":")[1]
          )
        : "",
      endDateSunday: sundayTime
        ? new Date().setHours(
            sundayTime[1].split(":")[0],
            sundayTime[1].split(":")[1]
          )
        : "",
      startDateMonday: mondayTime
        ? new Date().setHours(
            mondayTime[0].split(":")[0],
            mondayTime[0].split(":")[1]
          )
        : "",
      endDateMonday: mondayTime
        ? new Date().setHours(
            mondayTime[1].split(":")[0],
            mondayTime[1].split(":")[1]
          )
        : "",
      startDateTuesday: tuesdayTime
        ? new Date().setHours(
            tuesdayTime[0].split(":")[0],
            tuesdayTime[0].split(":")[1]
          )
        : "",
      endDateTuesday: tuesdayTime
        ? new Date().setHours(
            tuesdayTime[1].split(":")[0],
            tuesdayTime[1].split(":")[1]
          )
        : "",
      startDateWednesday: wednesdayTime
        ? new Date().setHours(
            wednesdayTime[0].split(":")[0],
            wednesdayTime[0].split(":")[1]
          )
        : "",
      endDateWednesday: wednesdayTime
        ? new Date().setHours(
            wednesdayTime[1].split(":")[0],
            wednesdayTime[1].split(":")[1]
          )
        : "",
      startDateThursday: thursdayTime
        ? new Date().setHours(
            thursdayTime[0].split(":")[0],
            thursdayTime[0].split(":")[1]
          )
        : "",
      endDateThursday: thursdayTime
        ? new Date().setHours(
            thursdayTime[1].split(":")[0],
            thursdayTime[1].split(":")[1]
          )
        : "",
      startDateFriday: fridayTime
        ? new Date().setHours(
            fridayTime[0].split(":")[0],
            fridayTime[0].split(":")[1]
          )
        : "",
      endDateFriday: fridayTime
        ? new Date().setHours(
            fridayTime[1].split(":")[0],
            fridayTime[1].split(":")[1]
          )
        : "",
      startDateSaturday: saturdayTime
        ? new Date().setHours(
            saturdayTime[0].split(":")[0],
            saturdayTime[0].split(":")[1]
          )
        : "",
      endDateSaturday: saturdayTime
        ? new Date().setHours(
            saturdayTime[1].split(":")[0],
            saturdayTime[1].split(":")[1]
          )
        : "",
    };
  }

  handleChange(id: string, event: any) {
    if ("startDateSunday" === id) {
      this.setState({ startDateSunday: event });
    } else if ("startDateMonday" === id) {
      this.setState({ startDateMonday: event });
    } else if ("startDateTuesday" === id) {
      this.setState({ startDateTuesday: event });
    } else if ("startDateWednesday" === id) {
      this.setState({ startDateWednesday: event });
    } else if ("startDateThursday" === id) {
      this.setState({ startDateThursday: event });
    } else if ("startDateFriday" === id) {
      this.setState({ startDateFriday: event });
    } else if ("startDateSaturday" === id) {
      this.setState({ startDateSaturday: event });
    } else if ("endDateSunday" === id) {
      this.setState({ endDateSunday: event });
    } else if ("endDateMonday" === id) {
      this.setState({ endDateMonday: event });
    } else if ("endDateTuesday" === id) {
      this.setState({ endDateTuesday: event });
    } else if ("endDateWednesday" === id) {
      this.setState({ endDateWednesday: event });
    } else if ("endDateThursday" === id) {
      this.setState({ endDateThursday: event });
    } else if ("endDateFriday" === id) {
      this.setState({ endDateFriday: event });
    } else if ("endDateSaturday" === id) {
      this.setState({ endDateSaturday: event });
    }
  }
  getTimeformat() {
    time = "";
    var startTime = this.state.startDateSunday;
    var endTime = this.state.endDateSunday;
    if (startTime && endTime) {
      time =
        (time ? time + "," : "") +
        ("Sun " +
          new Intl.DateTimeFormat("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: false,
          }).format(startTime) +
          "-" +
          new Intl.DateTimeFormat("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: false,
          }).format(endTime));
    }
    startTime = this.state.startDateMonday;
    endTime = this.state.endDateMonday;
    if (startTime && endTime) {
      time =
        (time ? time + "," : "") +
        ("Mon " +
          new Intl.DateTimeFormat("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: false,
          }).format(startTime) +
          "-" +
          new Intl.DateTimeFormat("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: false,
          }).format(endTime));
    }
    startTime = this.state.startDateTuesday;
    endTime = this.state.endDateTuesday;
    if (startTime && endTime) {
      time =
        (time ? time + "," : "") +
        ("Tue " +
          new Intl.DateTimeFormat("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: false,
          }).format(startTime) +
          "-" +
          new Intl.DateTimeFormat("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: false,
          }).format(endTime));
    }
    startTime = this.state.startDateWednesday;
    endTime = this.state.endDateWednesday;
    if (startTime && endTime) {
      time =
        (time ? time + "," : "") +
        ("Wed " +
          new Intl.DateTimeFormat("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: false,
          }).format(startTime) +
          "-" +
          new Intl.DateTimeFormat("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: false,
          }).format(endTime));
    }
    startTime = this.state.startDateThursday;
    endTime = this.state.endDateThursday;
    if (startTime && endTime) {
      time =
        (time ? time + "," : "") +
        ("Thu " +
          new Intl.DateTimeFormat("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: false,
          }).format(startTime) +
          "-" +
          new Intl.DateTimeFormat("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: false,
          }).format(endTime));
    }
    startTime = this.state.startDateFriday;
    endTime = this.state.endDateFriday;
    if (startTime && endTime) {
      time =
        (time ? time + "," : "") +
        ("Fri " +
          new Intl.DateTimeFormat("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: false,
          }).format(startTime) +
          "-" +
          new Intl.DateTimeFormat("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: false,
          }).format(endTime));
    }
    startTime = this.state.startDateSaturday;
    endTime = this.state.endDateSaturday;
    if (startTime && endTime) {
      time =
        (time ? time + "," : "") +
        ("Sat " +
          new Intl.DateTimeFormat("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: false,
          }).format(startTime) +
          "-" +
          new Intl.DateTimeFormat("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: false,
          }).format(endTime));
    }
  }
  render() {
    this.getTimeformat();
    this.props.updateRefNumVal(time);
    return (
      <>
        <div id="locationAvailability">
          <div className="MuiPaper-root MuiAccordion-root Mui-expanded MuiAccordion-rounded MuiPaper-elevation1 MuiPaper-rounded">
            <div
              className="MuiCollapse-container MuiCollapse-entered"
              style={{
                height: "auto",
                minHeight: "0px",
                transitionDuration: "327ms",
              }}
            >
              <div className="MuiCollapse-wrapper pageContent-style">
                <div className="MuiCollapse-wrapperInner">
                  <div id="pannel1a-content" role="region">
                    <div className="MuiAccordionDetails-root">
                      <div>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Location availability</th>
                              <th>Sunday</th>
                              <th>Monday</th>
                              <th>Tuesday</th>
                              <th>Wednesday</th>
                              <th>Thursday</th>
                              <th>Friday</th>
                              <th>Saturday</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th>Start time</th>
                              <td>
                                <DatePicker
                                  onChange={this.handleChange.bind(
                                    this,
                                    "startDateSunday"
                                  )}
                                  selected={this.state.startDateSunday}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeCaption="Start time"
                                  timeFormat="hh:mm aa"
                                  dateFormat="HH:mm"
                                  id="startDateSunday"
                                  className="datePickerStyle"
                                />
                              </td>
                              <td>
                                <DatePicker
                                  onChange={this.handleChange.bind(
                                    this,
                                    "startDateMonday"
                                  )}
                                  selected={this.state.startDateMonday}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeCaption="Start time"
                                  timeFormat="hh:mm aa"
                                  dateFormat="HH:mm"
                                  id="startDateMonday"
                                  className="datePickerStyle"
                                />
                              </td>
                              <td>
                                <DatePicker
                                  onChange={this.handleChange.bind(
                                    this,
                                    "startDateTuesday"
                                  )}
                                  selected={this.state.startDateTuesday}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeCaption="Start time"
                                  timeFormat="hh:mm aa"
                                  dateFormat="HH:mm"
                                  id="startDateTuesday"
                                  className="datePickerStyle"
                                />
                              </td>
                              <td>
                                <DatePicker
                                  onChange={this.handleChange.bind(
                                    this,
                                    "startDateWednesday"
                                  )}
                                  selected={this.state.startDateWednesday}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeCaption="Start time"
                                  timeFormat="hh:mm aa"
                                  dateFormat="HH:mm"
                                  id="startDateWednesday"
                                  className="datePickerStyle"
                                />
                              </td>
                              <td>
                                <DatePicker
                                  onChange={this.handleChange.bind(
                                    this,
                                    "startDateThursday"
                                  )}
                                  selected={this.state.startDateThursday}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeCaption="Start time"
                                  timeFormat="hh:mm aa"
                                  dateFormat="HH:mm"
                                  id="startDateThursday"
                                  className="datePickerStyle"
                                />
                              </td>
                              <td>
                                <DatePicker
                                  onChange={this.handleChange.bind(
                                    this,
                                    "startDateFriday"
                                  )}
                                  selected={this.state.startDateFriday}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeCaption="Start time"
                                  timeFormat="hh:mm aa"
                                  dateFormat="HH:mm"
                                  id="startDateFriday"
                                  className="datePickerStyle"
                                />
                              </td>
                              <td>
                                <DatePicker
                                  onChange={this.handleChange.bind(
                                    this,
                                    "startDateSaturday"
                                  )}
                                  selected={this.state.startDateSaturday}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeCaption="Start time"
                                  timeFormat="hh:mm aa"
                                  dateFormat="HH:mm"
                                  id="startDateSaturday"
                                  className="datePickerStyle"
                                />
                              </td>
                            </tr>
                            <tr>
                              <th>End time </th>
                              <td>
                                <DatePicker
                                  onChange={this.handleChange.bind(
                                    this,
                                    "endDateSunday"
                                  )}
                                  selected={this.state.endDateSunday}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeCaption="End time"
                                  timeFormat="hh:mm aa"
                                  dateFormat="HH:mm"
                                  id="endDateSunday"
                                  className="datePickerStyle"
                                />
                              </td>
                              <td>
                                <DatePicker
                                  onChange={this.handleChange.bind(
                                    this,
                                    "endDateMonday"
                                  )}
                                  selected={this.state.endDateMonday}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeCaption="End time"
                                  timeFormat="hh:mm aa"
                                  dateFormat="HH:mm"
                                  id="endDateMonday"
                                  className="datePickerStyle"
                                />
                              </td>
                              <td>
                                <DatePicker
                                  onChange={this.handleChange.bind(
                                    this,
                                    "endDateTuesday"
                                  )}
                                  selected={this.state.endDateTuesday}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeCaption="End time"
                                  timeFormat="hh:mm aa"
                                  dateFormat="HH:mm"
                                  id="endDateTuesday"
                                  className="datePickerStyle"
                                />
                              </td>
                              <td>
                                <DatePicker
                                  onChange={this.handleChange.bind(
                                    this,
                                    "endDateWednesday"
                                  )}
                                  selected={this.state.endDateWednesday}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeCaption="End time"
                                  timeFormat="hh:mm aa"
                                  dateFormat="HH:mm"
                                  id="endDateWednesday"
                                  className="datePickerStyle"
                                />
                              </td>
                              <td>
                                <DatePicker
                                  onChange={this.handleChange.bind(
                                    this,
                                    "endDateThursday"
                                  )}
                                  selected={this.state.endDateThursday}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeCaption="End time"
                                  timeFormat="hh:mm aa"
                                  dateFormat="HH:mm"
                                  id="endDateThursday"
                                  className="datePickerStyle"
                                />
                              </td>
                              <td>
                                <DatePicker
                                  onChange={this.handleChange.bind(
                                    this,
                                    "endDateFriday"
                                  )}
                                  selected={this.state.endDateFriday}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeCaption="End time"
                                  timeFormat="hh:mm aa"
                                  dateFormat="HH:mm"
                                  id="endDateFriday"
                                  className="datePickerStyle"
                                />
                              </td>
                              <td>
                                <DatePicker
                                  onChange={this.handleChange.bind(
                                    this,
                                    "endDateSaturday"
                                  )}
                                  selected={this.state.endDateSaturday}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeCaption="End time"
                                  timeFormat="hh:mm aa"
                                  dateFormat="HH:mm"
                                  id="endDateSaturday"
                                  className="datePickerStyle"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
