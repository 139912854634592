import React from "react";
import { CSVLink } from "react-csv";

interface IProps {
  id: string;
  data: any;
  headers: any;
  filename: string;
  title: string;
  children: any;
}

const Report = (props: IProps) => {
  return (
    <React.Fragment>
      <CSVLink
        id={props.id}
        title={props.title}
        filename={props.filename}
        data={props.data}
        headers={props.headers}
      >
        {props.children}
      </CSVLink>
    </React.Fragment>
  );
};
export default Report;
