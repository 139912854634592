import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { Link } from "react-router-dom";
import CVPUserGuide from "../../document/CVP_2.0_User_Guide.pdf";

const FaqPage: React.FC = () => {
  return (
    <div
      id="faqContainer"
      className="container"
      style={{ paddingTop: 40, paddingBottom: 40 }}
    >
      <div id="faq">
        <div>
          <h4>CVP (Custom Vendor Portal) User Guide</h4>
          <br />
          <Accordion>
            <div className="pageContent-style">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pannel1a-content"
              >
                Where can I find the CVP User Guide?
              </AccordionSummary>
            </div>
            <div>
              <AccordionDetails>
                <div>
                  You can download the CVP User Guide by clicking <a href={CVPUserGuide} target="_blank">HERE</a> or under the CVP <Link to="/tour">Take a Tour</Link> page.
                </div>
              </AccordionDetails>
            </div>
          </Accordion>
        </div>
        <br />
        <div>
          <h4>Login Issues</h4>
          <br />
          <Accordion>
            <div className="pageContent-style">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pannel1a-content"
              >
                How do I obtain a new login?
              </AccordionSummary>
            </div>
            <div>
              <AccordionDetails>
                <div>
                An existing account holder should email <a href="mailto:vendorlogin@dollartree.com">vendorlogin@dollartree.com</a> and request a new Username for the individual. 
                Provide individual's first and last name, individuals. email address, and any completed Purchase Order number.
                </div>
              </AccordionDetails>
            </div>
          </Accordion>
          <br />
          <Accordion>
            <div className="pageContent-style">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pannel1a-content"
              >
                How do I recover my Username or Password?
              </AccordionSummary>
            </div>
            <div>
              <AccordionDetails>
                <div>
                  Email <a href="mailto:vendorlogin@dollartree.com">vendorlogin@dollartree.com</a> and provide the CVP username 
                  and Purchase Order number.
                </div>
              </AccordionDetails>
            </div>
          </Accordion>
          <br />
          <Accordion>
            <div className="pageContent-style">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pannel1a-content"
              >
                I have a Login and password, but can't I get past the login screen.
              </AccordionSummary>
            </div>
            <div>
              <AccordionDetails>
                <div>
                  <div>
                  <ol type="1">
                    <li>
                      Manually type in the Username and Password at the log on screen. Do not copy and paste spaces.
                    </li>
                    <li>
                      Attempt to connect from a cellphone (not using company Wi-Fi) or computer not on your business network. If you can connect and log-into to CVP using your username and password outside of your company firewall, contact your IT department to open the port (443, 7779) for the production IP address: 208.72.183.132 for CVP.
                    </li>
                    <li>
                      Verify you are using a US version of Firefox, Chrome, or Microsoft Edge.
                    </li>
                    <li>
                      Email <a href="mailto:vendorlogin@dollartree.com">vendorlogin@dollartree.com</a> with the details of your issue, include your logon name and screen shots (if possible).
                    </li>
                  </ol>
                  </div>
                </div>
              </AccordionDetails>
            </div>
          </Accordion>
          <br />
          <Accordion>
            <div className="pageContent-style">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pannel1a-content"
              >
                I'm experiencing issue with the CVP interface, or the data looks odd.
              </AccordionSummary>
            </div>
            <div>
              <AccordionDetails>
                <div>
                Clear the browser cache and reboot the computer. If that doesn't resolve the issue, 
                Email Vendor Login <a href="mailto:vendorlogin@dollartree.com">vendorlogin@dollartree.com</a> with issue description and any applicable screenshots.
                </div>
              </AccordionDetails>
            </div>
          </Accordion>
        </div>
        <br />
        <div>
          <h4>Location Management</h4>
          <br />
          <Accordion>
            <div className="pageContent-style">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pannel1a-content"
              >
                How do I change/add a point of contact on a location?
              </AccordionSummary>
            </div>
            <div>
              <AccordionDetails>
                <div>
                Search for the location to be modified and click into it. 
                At the contacts section, add, activate/inactivate, or update name and contact information.
                </div>
              </AccordionDetails>
            </div>
          </Accordion>
        </div>
        <br />
        <div>
          <h4>Order Release Generation - Locked/Not listed POs</h4>
          <br />
          <Accordion>
            <div className="pageContent-style">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pannel1a-content"
              >
                How do I unlock a PO with a negative quantity?
              </AccordionSummary>
            </div>
            <div>
              <AccordionDetails>
                <div>
                A negative quantity occurs when the buyer modifies the quantity ordered on the PO 
                after a portion of the purchase order has already been released on an Order Release. 
                Contact the merchant buyer to modify the quantity. If the SKU that is negative is no 
                longer needing to be shipped, contact the transportation analyst to remove quantities 
                from the existing order release that is no longer accurate.
                </div>
              </AccordionDetails>
            </div>
          </Accordion>
          <br />
          <Accordion>
            <div className="pageContent-style">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pannel1a-content"
              >
                How do I unlock a PO with a zero quantity?
              </AccordionSummary>
            </div>
            <div>
              <AccordionDetails>
                <div>
                A zero quantity occurs when all the quantities of the purchase order have already been 
                released onto an Order Release. Proactively notify the transportation analyst to adjust 
                the previous Order Release's quantity.
                </div>
              </AccordionDetails>
            </div>
          </Accordion>
          <br />
          <Accordion>
            <div className="pageContent-style">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pannel1a-content"
              >
                How do I unlock a PO with shipping date issues?
              </AccordionSummary>
            </div>
            <div>
              <AccordionDetails>
                <div>
                Purchase orders are to be released 7 days prior to the start of the shipping window. 
                Contact the merchant buyer to ensure the product is still needed, and they will adjust PO dates.
                </div>
              </AccordionDetails>
            </div>
          </Accordion>
          <br />
          <Accordion>
            <div className="pageContent-style">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pannel1a-content"
              >
                I can't see my PO when logged in. What do I do?
              </AccordionSummary>
            </div>
            <div>
              <AccordionDetails>
                <div>
                This issue normally occurs when there is a mismatch between the user's permissions and the company listed on the PO. 
                Email vendor issues <a href="mailto:vendorlogin@dollartree.com">vendorlogin@dollartree.com</a> providing the Login and the PO number not visible.
                </div>
              </AccordionDetails>
            </div>
          </Accordion>
          <br />
          <Accordion>
            <div className="pageContent-style">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pannel1a-content"
              >
                I just got off the phone with my buyer. How long until my PO is visible in CVP? Then what?
              </AccordionSummary>
            </div>
            <div>
              <AccordionDetails>
                <div>
                Allow 4 hours for the new dates to reflect in CVP. Contact the merchant buyer if not properly reflected after 4 hours.
                </div>
              </AccordionDetails>
            </div>
          </Accordion>
          <br />
          <Accordion>
            <div className="pageContent-style">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pannel1a-content"
              >
                I'm outside the US and my date format isn't populating correctly. What do I do?
              </AccordionSummary>
            </div>
            <div>
              <AccordionDetails>
                <div>
                An international date browser setting is DD/MM/YYYY which is incorrect for the CVP system. 
                A US version of Firefox will resolve the isse as it uses MM/DD/YYYY format. 
                An internet search for &quot;&#10100;your browser&#10101; change date format&quot; may provide additional steps.
                </div>
              </AccordionDetails>
            </div>
          </Accordion>
          <br />
          <Accordion>
            <div className="pageContent-style">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pannel1a-content"
              >
                I have pick-up dates in the past. What do I do?
              </AccordionSummary>
            </div>
            <div>
              <AccordionDetails>
                <div>
                Notify the merchant buyer that the purchase order will be shipping late. 
                Provide the "Product Ready Date" for when the product will actually be ready to ship, note charge backs may apply.
                </div>
              </AccordionDetails>
            </div>
          </Accordion>
          <br />
          <Accordion> 
            <div className="pageContent-style">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pannel1a-content"
              >
                My pick-up dates don't support the delivery date.
              </AccordionSummary>
            </div>
            <div>
              <AccordionDetails>
                <div>
                Contact the merchant buyer with any dates that conflict.
                </div>
              </AccordionDetails>
            </div>
          </Accordion>
          <br />
          <Accordion> 
            <div className="pageContent-style">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pannel1a-content"
              >
                My PO is Less than 45,000 pounds and 30 pallets. Should I have more than one Order Release?
              </AccordionSummary>
            </div>
            <div>
              <AccordionDetails>
                <div>
                If the entire Purchase Order is originating at the same facility and is less than 45,000 pounds & 30 pallet spaces, 
                the PO should be released in one shipment or chargeback fees may apply.
                </div>
              </AccordionDetails>
            </div>
          </Accordion>
          <br />
          <Accordion>
            <div className="pageContent-style">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pannel1a-content"
              >
                My PO quantity is more than 45,000 pounds or 30 Pallets. Should it be split?
              </AccordionSummary>
            </div>
            <div>
              <AccordionDetails>
                <div>
                Best practice is to release in full truckload quantities. 
                Releases should be made to those thresholds, contact the merchant buyer or transportation analyst with questions.
                </div>
              </AccordionDetails>
            </div>
          </Accordion>
          <br />
          <Accordion>
            <div className="pageContent-style">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pannel1a-content"
              >
                What is the difference between pallet and pallet spaces?
              </AccordionSummary>
            </div>
            <div>
              <AccordionDetails>
                <div>
                The “pallet spaces” is how much space your freight takes up on the truck. 
                A truck is up to 30 pallet spaces. 
                So, in theory, if you had 60 pallets that were stackable, you could enter 60 pallets, but 30 pallet spaces.
                </div>
              </AccordionDetails>
            </div>
          </Accordion>
          <br />
          <Accordion>
            <div className="pageContent-style">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pannel1a-content"
              >
                What should I do if I want to ship pallets larger than the standard 48x40 GMA four-way pallets?
              </AccordionSummary>
            </div>
            <div>
              <AccordionDetails>
                <div>
                Ready to Ship Manager screen. Input the number of pallets and pallet spaces, 
                Toggle "Are your Pallet’s Oversized" to Yes, and adjust the default volume to 
                reflect the oversized pallet volume (floor to ceiling) being utilized. 
                Note that oversized pallets are against Dollar Tree’s Inbound Shipping Requirements 
                and may be subject to chargeback, so pre-authorization is required.
                </div>
              </AccordionDetails>
            </div>
          </Accordion>
        </div>
        <br />
        <div>
          <h4>Order Release Warehouse Planning</h4>
          <br />
          <Accordion>
            <div className="pageContent-style">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pannel1a-content"
              >
                Should I stack my Pallets?
              </AccordionSummary>
            </div>
            <div>
              <AccordionDetails>
                <div>
                All stackable products should be stacked.
                </div>
              </AccordionDetails>
            </div>
          </Accordion>
          <br />
          <Accordion>
            <div className="pageContent-style">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pannel1a-content"
              >
                When do I need to release my product?
              </AccordionSummary>
            </div>
            <div>
              <AccordionDetails>
                <div>
                  Purchase Order's should be released <b><u>at least</u></b> seven days 
                  prior to the date product is anticipated to be available for carrier 
                  pickup and allow for transit to achieve the purchase order ETA.
                </div>
              </AccordionDetails>
            </div>
          </Accordion>
          <br />
          <Accordion>
            <div className="pageContent-style">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pannel1a-content"
              >
                How do I get a Bill of Lading [BOL] or Routing Instructions?
              </AccordionSummary>
            </div>
            <div>
              <AccordionDetails>
                <div>
                  Details are received via email. If not received, contact the Transportation Analyst.
                </div>
              </AccordionDetails>
            </div>
          </Accordion>
          <br />
        </div>
        <br />
        <div>
          <h4>Order Release Generation - Management Page</h4>
          <br />
          <Accordion>
            <div className="pageContent-style">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pannel1a-content"
              >
                The freight term listed (Collect/Prepaid) doesn’t match my Purchase Order.
              </AccordionSummary>
            </div>
            <div>
              <AccordionDetails>
                <div>
                  Contact the merchant buyer if there is a mismatch between Collect and Prepaid in CVP and the purchase order. 
                  Submitting a release with the wrong term could result in delays or chargebacks.
                </div>
              </AccordionDetails>
            </div>
          </Accordion>
          <br />
          <Accordion>
            <div className="pageContent-style">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pannel1a-content"
              >
                When I select an Early Product Ready Date, I get a red warning about a possible chargeback.
              </AccordionSummary>
            </div>
            <div>
              <AccordionDetails>
                <div>
                  You are selecting an Early Product Ready Date that is after the PO Late Ship Date.  
                  If you are an OTIF vendor, you will be subject to chargeback.  
                  There is a mandatory 7-day lead time requirement for FD | DT planning and carrier selection.  
                  Proactive communications with your merchant buyer may influence a PO Pick-up dates modification.
                </div>
              </AccordionDetails>
            </div>
          </Accordion>
          <br />
          <Accordion>
            <div className="pageContent-style">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pannel1a-content"
              >
                The SKU quantities in CVP don't match my contract. What do I do?
              </AccordionSummary>
            </div>
            <div>
              <AccordionDetails>
                <div>
                  Contact the merchant buyer to adjust.
                </div>
              </AccordionDetails>
            </div>
          </Accordion>
          <br />
          <Accordion>
            <div className="pageContent-style">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pannel1a-content"
              >
                The PO has quantities shipping from different origins.
              </AccordionSummary>
            </div>
            <div>
              <AccordionDetails>
                <div>
                  Each origin will need to be released individually.
                </div>
              </AccordionDetails>
            </div>
          </Accordion>
          <br />
          <Accordion>
            <div className="pageContent-style">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pannel1a-content"
              >
                How do I determine Freight class?
              </AccordionSummary>
            </div>
            <div>
              <AccordionDetails>
                <div>
                  The National Motor Freight Traffic Association (NMFTA) is the authoritative source for 
                  National Motor Freight Classification (NMFC) of freight. Releasing with an incorrect class 
                  could result in charge backs.
                </div>
              </AccordionDetails>
            </div>
          </Accordion>
        </div>
        <br />
        <div>
          <h4>After Order Release Submission.</h4>
          <br />
          <Accordion>
            <div className="pageContent-style">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pannel1a-content"
              >
                When/How do I get my Shipment Identification (SID) number for collect term shipments?
              </AccordionSummary>
            </div>
            <div>
              <AccordionDetails>
                <div>
                  Collect shipment IDs are generated when a carrier has been assigned. 
                  This will usually occur 3-5 days prior to the Product Ready Date provided. 
                  If dates are within 2 days from Product Ready Date and have not received 
                  the BOL/SID, contact the transportation analyst.
                </div>
              
              </AccordionDetails>
            </div>
          </Accordion>
          <br />
          <Accordion>
            <div className="pageContent-style">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pannel1a-content"
              >
                When/How do I get my Shipment Identification (SID) number for prepaid term shipments?
              </AccordionSummary>
            </div>
            <div>
              <AccordionDetails>
                <div>
                  Prepaid shipment IDs should be generated within a few minutes. If it has not, contact the transportation analyst.
                </div>
              </AccordionDetails>
            </div>
          </Accordion>
          <br />
          <Accordion>
            <div className="pageContent-style">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pannel1a-content"
              >
                What is SCAC (Standard Carrier Alpha Code) PRPD?
              </AccordionSummary>
            </div>
            <div>
              <AccordionDetails>
                <div>
                  PRPD indicates the shipment freight term is prepaid.
                </div>
              </AccordionDetails>
            </div>
          </Accordion>
          <br />
          <Accordion>
            <div className="pageContent-style">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pannel1a-content"
              >
                I made a mistake on my submitted Order Release. How do I fix it?
              </AccordionSummary>
            </div>
            <div>
              <AccordionDetails>
                <div>
                  If the order release has not yet been assigned an SID, cancel the order 
                  release in the CVP Order Release Search Results and re-create it as necessary. 
                  If the order release is already assigned an SID, it cannot be canceled and must
                  contact the transportation analyst to have the order release edited/cancelled.
                </div>
              </AccordionDetails>
            </div>
          </Accordion>
          <br />
          <Accordion>
            <div className="pageContent-style">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pannel1a-content"
              >
                I have an Order Release but don't have all the product ready. What do I do?
              </AccordionSummary>
            </div>
            <div>
              <AccordionDetails>
                <div>
                  Best practice is to release a PO complete, and ship complete. 
                  If a release is now only available in part, contact the merchant
                  buyer for guidance to include possible PO adjustments.
                </div>
              </AccordionDetails>
            </div>
          </Accordion>
          <br />
          <Accordion>
            <div className="pageContent-style">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pannel1a-content"
              >
                When do I need to spin my pallets?
              </AccordionSummary>
            </div>
            <div>
              <AccordionDetails>
                <div>
                  Pallets are to always be loaded spun or "rotated" when loaded to the trailer.
                </div>
              </AccordionDetails>
            </div>
          </Accordion>
          <br />
          <Accordion>
            <div className="pageContent-style">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pannel1a-content"
              >
                I have received a freight chargeback and have questions.
              </AccordionSummary>
            </div>
            <div>
              <AccordionDetails>
                <div>
                  <ol>
                    <li>For Dollar Tree, contact your merchant buyer. 
                    </li>
                    <li>For Family Dollar, contact <a href="mailto:freightchargebacks@dollartree.com">freightchargebacks@dollartree.com</a>
                    </li>
                  </ol>
                </div>
              </AccordionDetails>
            </div>
          </Accordion>
        </div>
        <br />
        <div>
          <h4>DC (Distribution Center) Delivery Appointments (C3 Reservations)</h4>
          <br />
          <Accordion>
            <div className="pageContent-style">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pannel1a-content"
              >
                Who can I get help from regarding a DC delivery appointment?
              </AccordionSummary>
            </div>
            <div>
              <AccordionDetails>
                <div>
                  Reference the C3 appointment scheduling&nbsp;
                  <a
                    href="https://www.dollartree.com/file/general/C3_Reservations_HTML5_Reference_Guide.pdf"
                    target="_blank"
                  >
                    guide
                  </a>
                </div>
              </AccordionDetails>
            </div>
          </Accordion>
          <br />
          <Accordion>
            <div className="pageContent-style">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="pannel1a-content"
              >
                My carrier can't find the SID in the delivery appointment system.
              </AccordionSummary>
            </div>
            <div>
              <AccordionDetails>
                <div>
                  Reference the C3 appointment scheduling&nbsp;
                  <a
                    href="https://www.dollartree.com/file/general/C3_Reservations_HTML5_Reference_Guide.pdf"
                    target="_blank"
                  >
                    guide
                  </a>
                </div>
              </AccordionDetails>
            </div>
          </Accordion>
        </div>
      </div>
    </div>
  );
};
export default FaqPage;
