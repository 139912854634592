export default class PoItemShipUnit {
  shipUnitId: string;
  loadMethod: string;
  palletSlipSheets: number;
  palletSpaces: number;
  volume: number;
  weight: number;
  cartons: number;
  nfmcClass: string;
  volumeBkp: number;
  volumeWithCommas: boolean;
  weightWithCommans: boolean;
  oversized: boolean;

  constructor(
    shipUnitId: string,
    loadMethod: string,
    palletSlipSheets: number,
    palletSpaces: number,
    volume: number,
    weight: number,
    cartons: number,
    nfmcClass: string,
    volumeBkp: number,
    volumeWithCommas: boolean,
    weightWithCommans: boolean,
    oversized: boolean
  ) {
    this.shipUnitId = shipUnitId;
    this.loadMethod = loadMethod;
    this.palletSlipSheets = palletSlipSheets;
    this.palletSpaces = palletSpaces;
    this.volume = volume;
    this.weight = weight;
    this.cartons = cartons;
    this.nfmcClass = nfmcClass;
    this.volumeBkp = volumeBkp;
    this.volumeWithCommas = volumeWithCommas;
    this.weightWithCommans = weightWithCommans;
    this.oversized = oversized;
  }
}
