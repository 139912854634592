import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { getJsonResult } from "../../services/FetchServices";
import Item from "../../types/Item";
import Location from "../../types/Location";
import OrderRelease from "../../types/OrderRelease";
import SearchOrderRelease from "../../types/SearchOrderRelease";
import Shipment from "../../types/Shipment";

interface IState {
  orderRelease?: OrderRelease;
  searchOrderRelease?: SearchOrderRelease;
}

const OrderReleasePage: React.FC = () => {
  const history = useHistory();
  const orderRelease = useLocation();
  const order = (orderRelease?.state as IState)?.orderRelease;
  const searchOrderRelease = (orderRelease?.state as IState)
    ?.searchOrderRelease;
  const [vendorId, setVendorId] = useState("");
  const [orderReleaseGid, setOrderReleaseGid] = useState("");
  const [shipFrom, setShipFrom] = useState("");
  const [shipTo, setShipTo] = useState("");
  const [freightTerms, setFreightTerms] = useState("");
  const [earlyPickupDate, setEarlyPickupDate] = useState("");
  const [latePickupDate, setLatePickupDate] = useState("");
  const [earlyDeliveryDate, setEarlyDeliveryDate] = useState("");
  const [lateDeliveryDate, setLateDeliveryDate] = useState("");
  const [totalWeightValue, setTotalWeightValue] = useState("");
  const [totalVolumeValue, setTotalVolumeValue] = useState("");
  const [palletSpaces, setPalletSpaces] = useState("");
  const [totalCartons, setTotalCartons] = useState("");
  const [loadMethod, setLoadMethod] = useState("");
  const [items, setItems] = useState<Item[]>([]);
  const [shipments, setShipments] = useState<Shipment[]>([]);
  const [location, setLocation] = useState<Location>();

  const convertDate = (dateTime: string) => {
    return dateTime.substring(0, 19).replace("T", " ");
  };

  var authHeader = localStorage.getItem("authHeader");
  const getItems = (orderReleaseGid: string) => {
    var itemsUrl =
      `${process.env.REACT_APP_BACKEND_URL}/OrderRelease/items?authHeader=` +
      authHeader +
      "&orderReleaseGid=" +
      orderReleaseGid;
    getJsonResult(itemsUrl)
      .then((response) => response.json())
      .then((data) => {
        setItems(data);
      });
  };

  const getShipments = (orderReleaseGid: string) => {
    var itemsUrl =
      `${process.env.REACT_APP_BACKEND_URL}/OrderRelease/shipments?authHeader=` +
      authHeader +
      "&orderReleaseGid=" +
      orderReleaseGid;
    getJsonResult(itemsUrl)
      .then((response) => response.json())
      .then((data) => {
        setShipments(data);
      });
  };

  const getLocation = (locationGId: string) => {
    var locationUrl =
      `${process.env.REACT_APP_BACKEND_URL}/findLocation/getLocation?authHeader=` +
      authHeader +
      "&locationGId=" +
      locationGId;
    getJsonResult(locationUrl)
      .then((response) => response.json())
      .then((data) => {
        setLocation(data);
      });
  };

  const cancel = () => {
    history.push({
      pathname: "/searchOrderRelease",
      state: { searchOrderRelease: searchOrderRelease },
    });
  };

  useEffect(() => {
    if (order !== undefined) {
      setVendorId(order?.vendorId);
      setOrderReleaseGid(order?.orderReleaseGid);
      setShipFrom(order?.shipFrom);
      setShipTo(order?.shipTo);
      setFreightTerms(order?.freightTerms);
      setEarlyPickupDate(convertDate(order?.earlyPickupDate));
      setLatePickupDate(convertDate(order?.latePickupDate));
      setEarlyDeliveryDate(convertDate(order?.earlyDeliveryDate));
      setLateDeliveryDate(convertDate(order?.lateDeliveryDate));
      setTotalWeightValue(order?.totalWeightValue);
      setTotalVolumeValue(order?.totalVolumeValue);
      setPalletSpaces(order?.palletSpaces);
      setTotalCartons(order?.totalCartons);
      setLoadMethod(order?.loadMethod);
      getItems(order?.orderReleaseGid);
      getShipments(order?.orderReleaseGid);
      getLocation(order?.shipFrom);
    }
  }, [order]);

  return (
    <section className="sectionHeadingPadding">
      <div id="orderReleasePage" className="container">
        <div className="container">
          <div
            className="intro"
            style={{ width: "80%", marginRight: "auto", marginLeft: "auto" }}
          >
            <div id="orderReleaseManager" className="section-heading mb-4">
              <span
                className="section-heading-lower"
                style={{
                  fontSize: "2rem",
                  textAlign: "center",
                  color: "#6b3a06",
                  fontWeight: 500,
                  paddingBottom: "20px",
                }}
              >
                ORDER RELEASE MANAGER
              </span>
            </div>
            <form>
              <div style={{ display: "flex" }}>
                <div style={{ width: "50%" }}>
                  <div className="form-group py-2">
                    <TextField
                      className="form-control"
                      id="vendorIdValue"
                      value={vendorId}
                      variant="outlined"
                      label="Vendor ID"
                      disabled
                    />
                  </div>
                  <div className="form-group py-2">
                    <TextField
                      className="form-control"
                      id="orderReleaseGidValue"
                      value={orderReleaseGid.split(".")[1]}
                      variant="outlined"
                      label="Order Release ID"
                      disabled
                    />
                  </div>
                  <div className="form-group py-2">
                    <TextField
                      className="form-control"
                      id="earlyPickupDateValue"
                      value={earlyPickupDate}
                      variant="outlined"
                      label="Early Product Ready"
                      disabled
                    />
                  </div>
                  <div className="form-group py-2">
                    <TextField
                      className="form-control"
                      id="latePickupDateValue"
                      value={latePickupDate}
                      variant="outlined"
                      label="Late Product Ready"
                      disabled
                    />
                  </div>
                  <div className="form-group py-2">
                    <TextField
                      className="form-control"
                      id="earlyDeliveryDateValue"
                      value={earlyDeliveryDate}
                      variant="outlined"
                      label="Early Delivery"
                      disabled
                    />
                  </div>
                  <div className="form-group py-2">
                    <TextField
                      className="form-control"
                      id="lateDeliveryDateValue"
                      value={lateDeliveryDate}
                      variant="outlined"
                      label="Late Delivery"
                      disabled
                    />
                  </div>
                  <div className="form-group py-2">
                    <TextField
                      className="form-control"
                      id="shipFromValue"
                      value={
                        !!location?.locationName
                          ? shipFrom.split(".")[1] +
                            " - " +
                            location?.locationName
                          : shipFrom.split(".")[1]
                      }
                      variant="outlined"
                      label="Ship From"
                      disabled
                    />
                  </div>
                </div>
                <div style={{ width: "50%", marginLeft: "75px" }}>
                  <div className="form-group py-2">
                    <TextField
                      className="form-control"
                      id="shipToValue"
                      value={shipTo.split(".")[1]}
                      variant="outlined"
                      label="Ship To"
                      disabled
                    />
                  </div>
                  <div className="form-group py-2">
                    <TextField
                      className="form-control"
                      id="freightTermsValue"
                      value={freightTerms.split(".")[1]}
                      variant="outlined"
                      label="Freight Terms"
                      disabled
                    />
                  </div>
                  <div className="form-group py-2">
                    <TextField
                      className="form-control"
                      id="totalWeightValue"
                      value={totalWeightValue}
                      variant="outlined"
                      label="Total Weight"
                      disabled
                    />{" "}
                  </div>
                  <div className="form-group py-2">
                    <TextField
                      className="form-control"
                      id="totalVolumeValue"
                      value={totalVolumeValue}
                      variant="outlined"
                      label="Total volume"
                      disabled
                    />{" "}
                  </div>
                  <div className="form-group py-2">
                    <TextField
                      className="form-control"
                      id="palletSpacesValue"
                      value={palletSpaces}
                      variant="outlined"
                      label="Pallet Spaces"
                      disabled
                    />
                  </div>
                  <div className="form-group py-2">
                    <TextField
                      className="form-control"
                      id="totalCartonsValue"
                      value={totalCartons}
                      variant="outlined"
                      label="Total Cartons"
                      disabled
                    />
                  </div>
                  <div className="form-group py-2">
                    <TextField
                      className="form-control"
                      id="loadMethodValue"
                      value={loadMethod.split(".")[1]}
                      variant="outlined"
                      label="Load Method"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div id="itemsTable">
          <table className="table">
            <thead>
              <tr>
                <th>Item ID</th>
                <th>Item description</th>
                <th>Ship quantity</th>
                <th>Cartons</th>
                <th>Hazmat ?</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item: Item) => (
                <tr>
                  <td>{item.itemId.split(".")[1]}</td>
                  <td>{item.itemDescription}</td>
                  <td>{item.shipQuantity}</td>
                  <td>{item.cartons}</td>
                  <td>{item.hazmat}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div id="shipmentsTable">
          <table className="table">
            <thead>
              <tr>
                <th>Shipment ID</th>
                <th>Assigned Carrier</th>
                <th>Shipment Status</th>
              </tr>
            </thead>
            <tbody>
              {shipments.map((shipment: Shipment) => (
                <tr>
                  <td>{shipment.shipmentId.split(".")[1]}</td>
                  <td>{shipment.assignedCarrier.split(".")[1]}</td>
                  <td>{shipment.shipmentStatus.split(".")[1]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row" style={{ textAlign: "center" }}>
        <div className="col-md">
          <button
            id="cancelORButton"
            className="btn btn-dark"
            onClick={() => cancel()}
          >
            Back
          </button>
        </div>
      </div>
    </section>
  );
};

export default OrderReleasePage;
