export default class SelectedAddressDetails {
  streetAddress: string;
  city: string;
  provinceCode: string;
  postalCode: string;

  constructor(
    streetAddress: string,
    city: string,
    provinceCode: string,
    postalCode: string
  ) {
    this.streetAddress = streetAddress;
    this.city = city;
    this.provinceCode = provinceCode;
    this.postalCode = postalCode;
  }
}
