import { TextField } from "@mui/material";
import React from "react";

interface DropDownRequiredProps {
  codes: string[];
  elementId: string;
  value: string;
}

interface DropDownOptionalProps {
  defaultValue?: string;
  disable?: boolean;
  label?: string;
  name?: string;
  onChange?: any;
  className?: string;
  defaultOption?: string;
}

interface DropDownProps extends DropDownRequiredProps, DropDownOptionalProps {}

const defaultProps: DropDownOptionalProps = {
  defaultValue: "",
  disable: false,
  label: "",
  name: "",
  onChange: "",
  className: "form-control",
  defaultOption: "",
};

export const DropDown: React.FC<DropDownProps> = ({
  codes,
  defaultValue,
  elementId,
  disable,
  label,
  className,
  name,
  onChange,
  value,
  defaultOption,
}) => {
  return (
    <>
      <TextField
        className={className}
        id={elementId}
        label={label}
        name={name}
        select
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        SelectProps={{
          native: true,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={disable}
      >
        {defaultOption !== "" ? (
          <option key={""} value={""}>
            {defaultOption}
          </option>
        ) : (
          ""
        )}
        {codes?.map((code) => (
          <option key={code} value={code}>
            {code}
          </option>
        ))}
      </TextField>
    </>
  );
};

DropDown.defaultProps = defaultProps;
