import React from "react";
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark py-lg-4" id="mainNav">
        <div className="container">
          <Link
            to="/home"
            className="navbar-brand text-uppercase fw-bold d-lg-none"
          >
            CVP
          </Link>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mx-auto">
              <li class="nav-item px-lg-4">
                <Link to="/home" className="nav-link text-uppercase">
                  HOME
                </Link>
              </li>
              <li class="nav-item dropdown px-lg-4">
                <a
                  class="nav-link dropdown-toggle text-uppercase"
                  id="navbarDropdownLocations"
                  href="/#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Locations
                </a>
                <ul
                  class="dropdown-menu dropdown-menu-end"
                  aria-labelledby="navbarDropdownBlog"
                >
                  <li>
                    <Link
                      to="/newLocation"
                      className="dropdown-item"
                      id="newLocation"
                    >
                      Create Location
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/findLocation"
                      className="dropdown-item"
                      id="findLocation"
                    >
                      Search Location
                    </Link>
                  </li>
                </ul>
              </li>
              <li class="nav-item dropdown px-lg-4">
                <a
                  class="nav-link dropdown-toggle text-uppercase"
                  id="navbarDropdownOrders"
                  href="/#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Order Release
                </a>
                <ul
                  class="dropdown-menu dropdown-menu-end"
                  aria-labelledby="navbarDropdownOrders"
                >
                  <li>
                    <Link
                      to="/searchPurchaseOrderRelease"
                      className="dropdown-item"
                      id="searchPurchaseOrderRelease"
                    >
                      Create Order Release From Purchase Order
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/searchOrderRelease"
                      className="dropdown-item"
                      id="searchOrderRelease"
                    >
                      Search Existing Order Releases
                    </Link>
                  </li>
                </ul>
              </li>
              <li class="nav-item px-lg-4">
                  <Link
                      to="/faq"
                      className="nav-link text-uppercase"
                      id="faq"
                      role="button"
                  >
                      FAQ
                  </Link>                   
                  </li>
              <li class="nav-item dropdown px-lg-4">
                <a
                  class="nav-link dropdown-toggle text-uppercase"
                  id="navbarDropdownContactUs"
                  href="/#" 
                  data-bs-toggle="dropdown" 
                  aria-expanded="false"
                  role="button"
                >
                  Contact Us
                </a>
                <ul
                  class="dropdown-menu dropdown-menu-end"
                  aria-labelledby="navbarContactUs"
                >
                  <li>                   
                    <address className="dropdown-item">
                      <a href="mailto:vendorlogin@dollartree.com">vendorlogin@dollartree.com</a>
                    </address>                   
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <button
            id="logoutButton"
            type="button"
            className="btn btn-success"
            style={{
              backgroundColor: "red",
              marginRight: "5px",
              color: "white",
              "font-weight": "800",
            }}
            onClick={() => {
              localStorage.clear();
              window.location.href = "/";
            }}
          >
            Logout
          </button>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
