export default class AdditionalContactDetails {
  contactXid: string;
  contactName: string;
  jobTitle: string;
  emailAddress: string;
  phone1: string;
  operation: string;
  active: boolean;

  constructor(
    contactXid: string,
    contactName: string,
    jobTitle: string,
    emailAddress: string,
    phone1: string,
    operation: string,
    active: boolean
  ) {
    this.contactXid = contactXid;
    this.contactName = contactName;
    this.jobTitle = jobTitle;
    this.emailAddress = emailAddress;
    this.phone1 = phone1;
    this.operation = operation;
    this.active = active;
  }
}
