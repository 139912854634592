import React from "react";
import LogoDTFD from "../../images/DT-FDO-Logos-RGB.jpg";

const Header: React.FC = () => {
  return (
    <header>
      <h1 className="site-heading text-center text-faded d-none d-lg-block">
        <div id="companyLogo">
          <img
            className="companyLogoStyle intro-img img-fluid mb-3 mb-lg-0 rounded"
            src={LogoDTFD}
            alt="..."
          />
        </div>
      </h1>
    </header>
  );
};

export default Header;
