import React, { useEffect, useRef, useState } from "react";
import { TextField, TextFieldProps } from "@mui/material";

export const FlagableTextField: React.FunctionComponent<
    TextFieldProps
> = (props) => {
    const eventListenerRef = useRef<HTMLDivElement>(null);
    const textFieldRef = useRef<HTMLDivElement>(null);
    const [showNotice, setShowNotice] = useState(false);
    const [disableErrorMessage, setDisableErrorMessage] = useState(false);

    useEffect(() => {
        const eventListenerElement: Element | null = eventListenerRef.current;
        if (eventListenerElement) {
            eventListenerElement.addEventListener("showNotice", () => {
                setShowNotice(true);
            });
            eventListenerElement.addEventListener("disableErrorMessage", () => {
                setDisableErrorMessage(true);
            });
        }
        const textFieldElement: Element | null = textFieldRef.current;
        if (textFieldElement) {
            textFieldElement.addEventListener("click", () => {
                setShowNotice(false);
                setDisableErrorMessage(false);
            });
            textFieldElement.addEventListener("keydown", () => {
                setShowNotice(false);
                setDisableErrorMessage(false);
            });
        }
    }, []);
    return (
        <div id="THING" className="position-relative d-flex flex-column align-items-stretch">
            <div id={props.id + "-event-listener"} ref={eventListenerRef} />
            <TextField
                {...props}
                error={showNotice}
                ref={textFieldRef}
            >
                {props.children}
            </TextField>
            {showNotice && !disableErrorMessage && (
                <div className="position-relative d-flex align-items-stretch user-select-none">
                    <div
                        className="position-absolute d-flex align-items-center h-100"
                        style={{
                            zIndex: 1000,
                        }}
                    >
                        <div className="bg-white bg-gradient p-2 rounded-3 border-danger fw-bolder text-nowrap text-danger shadow ms-2">
                            Please enter required field.
                        </div>
                    </div>
                </div>
            )}
            {showNotice && disableErrorMessage &&  (
                <div className="position-relative d-flex align-items-stretch user-select-none">
                    <div
                        className="position-absolute d-flex align-items-center h-100"
                        style={{
                            zIndex: 1000,
                        }}
                    >
                    </div>
                </div>
            )}
        </div>
    );
};

export const focusOnField = (fieldId: string, showError = false) => {
    const field = document.getElementById(fieldId);
    if (field) {
        field.scrollTo({behavior: "smooth"});
        field.focus();
        const fieldEventListener = document.getElementById(
            fieldId + "-event-listener"
        );
        if(showError){
            fieldEventListener &&
            fieldEventListener.dispatchEvent(new Event("showNotice")) && 
            fieldEventListener.dispatchEvent(new Event("disableErrorMessage"));
        }else{
            fieldEventListener &&
            fieldEventListener.dispatchEvent(new Event("showNotice"));
        }
    }
};
