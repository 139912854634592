export default class SearchPurchaseOrderRelease {
  purchaseOrderXId: string;
  destinationDc: string;
  earlyPickupDate: Date | null;
  earlyPickupDateBefore: Date | null;
  earlyDeliveryDate: Date | null;
  earlyDeliveryDateBefore: Date | null;
  latePickupDate: Date | null;
  latePickupDateBefore: Date | null;
  lateDeliveryDate: Date | null;
  lateDeliveryDateBefore: Date | null;
  poCreationDate: Date | null;
  poCreationDateBefore: Date | null;
  checkedExculdeCanPo: boolean;
  checkedExcludePoNoQty: boolean;
  constructor(
    purchaseOrderXId: string,
    destinationDc: string,
    earlyPickupDate: Date | null,
    earlyPickupDateBefore: Date | null,
    earlyDeliveryDate: Date | null,
    earlyDeliveryDateBefore: Date | null,
    latePickupDate: Date | null,
    latePickupDateBefore: Date | null,
    lateDeliveryDate: Date | null,
    lateDeliveryDateBefore: Date | null,
    poCreationDate: Date | null,
    poCreationDateBefore: Date | null,
    checkedExculdeCanPo: boolean,
    checkedExcludePoNoQty: boolean
  ) {
    this.purchaseOrderXId = purchaseOrderXId;
    this.destinationDc = destinationDc;
    this.earlyPickupDate = earlyPickupDate;
    this.earlyPickupDateBefore = earlyPickupDateBefore;
    this.earlyDeliveryDate = earlyDeliveryDate;
    this.earlyDeliveryDateBefore = earlyDeliveryDateBefore;
    this.latePickupDate = latePickupDate;
    this.latePickupDateBefore = latePickupDateBefore;
    this.lateDeliveryDate = lateDeliveryDate;
    this.lateDeliveryDateBefore = lateDeliveryDateBefore;
    this.poCreationDate = poCreationDate;
    this.poCreationDateBefore = poCreationDateBefore;
    this.checkedExculdeCanPo = checkedExculdeCanPo;
    this.checkedExcludePoNoQty = checkedExcludePoNoQty;
  }
}
