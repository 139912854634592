import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Refreshable } from "react-router-refreshable";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FaqPage from "../faq/FaqPage";
import FindLocation from "../findLocation/FindLocationPage";
import NavBarHomePage from "../navBarHomePage/NavBarHomePage";
import Navbar from "../navBarMenu/Navbar";
import NewLocation from "../newLocation/NewLocationPage";
import OrderReleasePage from "../orderRelease/OrderReleasePage";
import PurchaseOrderReleasePage from "../purchaseOrderRelease/PurchaseOrderReleasePage";
import SearchOrderRelease from "../searchOrderRelease/SearchOrderReleasePage";
import SearchPurchaseOrderRelease from "../searchPurchaseOrderForOrderRelease/SearchPurchaseOrderForOrderReleasePage";
import TourPage from "./TourPage";

interface HomaPageProps {}
const PageNewLoc = () => <NewLocation />;
const PageFindLoc = () => <FindLocation />;
const PageHome = () => <NavBarHomePage />;
const PageSearchOrderRelease = () => <SearchOrderRelease />;
const PageSearchPurchaseOrderRelease = () => <SearchPurchaseOrderRelease />;
const PageOrderRelease = () => <OrderReleasePage />;
const PagePurchaseOrderRelease = () => <PurchaseOrderReleasePage />;
const PageFaq = () => <FaqPage />;
const PageTour = () => <TourPage />;

const HomePage: React.FC<HomaPageProps> = () => {
  return (
    <>
      <div className="pageContent-style">
        <Router>
          <Navbar />
          <Refreshable>
            <Switch>
              <Route path="/home" component={PageHome} />
              <Route path="/newLocation" component={PageNewLoc} />
              <Route path="/findLocation" component={PageFindLoc} />
              <Route
                path="/searchOrderRelease"
                component={PageSearchOrderRelease}
              />
              <Route
                path="/searchPurchaseOrderRelease"
                component={PageSearchPurchaseOrderRelease}
              />
              <Route path="/orderRelease" component={PageOrderRelease} />
              <Route
                path="/purchaseOrderRelease"
                component={PagePurchaseOrderRelease}
              />
              <Route path="/faq" component={PageFaq} />
              <Route path="/tour" component={PageTour} />
            </Switch>
          </Refreshable>
          <ToastContainer containerId="toast-container" autoClose={5000} />
        </Router>
      </div>
    </>
  );
};

export default HomePage;
