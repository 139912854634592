import { Box, Button, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import fetch from "unfetch";
import { getJsonResult, getResult } from "../../services/FetchServices";

const LoginInPage: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [downTimeMessage, setDownTimeMessage] = useState("");
  const history = useHistory();

  const encryptPassword = (password: string) => {
    let asciiArr = password.split("");
    let encryASCIIArr: any = [];
    asciiArr.forEach((element) => {
      let asciiChar = element.charCodeAt(0) + 3;
      let asciiCharMulBy3 = asciiChar * 3;
      encryASCIIArr.push(asciiCharMulBy3);
    });
    let encryPwd = "";
    encryASCIIArr.forEach((element: any) => {
      encryPwd = encryPwd + "*" + element;
    });
    return encryPwd;
  };

  const handleLogIn = async () => {
    var usrdata = username + ":" + password;
    var authHeader = "Basic " + btoa(usrdata);
    localStorage.setItem("userName", username);
    localStorage.setItem("authHeader", authHeader);
    var vendorIdurl =
      `${process.env.REACT_APP_BACKEND_URL}/newLocation/vendorId?authHeader=` +
      authHeader +
      "&userName=" +
      username;
    await fetch(
      `${
        process.env.REACT_APP_BACKEND_URL
      }/login/auth?username=${username}&password=${encryptPassword(password)}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    )
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          getResult(vendorIdurl)
            .then((vendorResponse) => vendorResponse.json())
            .then((vendorData) => {
              localStorage.setItem("vendorId", vendorData[0]);
            });
          return response;
        } else {
          throw response;
        }
      })
      .then(() => history.push("/home"))
      .catch((error) => {
        error.text().then((errMessage: any) => {
          if (errMessage.indexOf("401") > -1) {
            setErrorMessage("Password or User ID are wrong");
          } else {
            setErrorMessage("A problem occurred, please try again later.");
          }
        });
      });
  };

  const handleUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      handleLogIn();
    }
  };

  useEffect(() => {
    var url = `${process.env.REACT_APP_BACKEND_URL}/login/getMessage`;
    getJsonResult(url)
      .then((response) => response.json())
      .then((data: any) => {
        setDownTimeMessage(data[0]);
      });
  }, []);

  return (
    <>
      <div className="body-login">
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            margin: "auto",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <TextField
            id="username"
            label="Username"
            variant="outlined"
            style={{ width: 300, margin: 10 }}
            onChange={handleUsername}
            onKeyPress={handleKeyPress}
          />
          <TextField
            id="password"
            label="Password"
            type="password"
            variant="outlined"
            onChange={handlePassword}
            onKeyPress={handleKeyPress}
            style={{ width: 300, margin: 10 }}
          />
          <Button
            id="loginButton"
            variant="contained"
            color="primary"
            onClick={handleLogIn}
            style={{
              width: 300,
              marginBottom: "10px",
            }}
          >
            Log in
          </Button>
          {errorMessage && (
            <label
              id="loginErrorMessage"
              style={{ color: "red", textAlign: "center" }}
            >
              {errorMessage}
            </label>
          )}
          {downTimeMessage && (
            <label
              id="loginDownTimeMessage"
              style={{ color: "red", textAlign: "center", fontSize: "25px" }}
            >
              Note : {downTimeMessage}
            </label>
          )}
        </Box>
      </div>
    </>
  );
};

export default LoginInPage;
