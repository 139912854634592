import OrderRelease from "../types/OrderRelease";

const planningStatusMap = new Map([
  ["PLANNING_NEW", "Not Yet Planned"],
  ["PLANNING_UNSCHEDULED", "Shipment Planning in Process"],
  ["PLANNING_PLANNED - FAILED", "Shipment Planning in Process"],
  ["PLANNING_PLANNED - FINAL", "Carrier Selection in Process"],
  ["PLANNING_EXECUTED - FINAL", "Tendered to Carrier"],
  ["PLANNING_EXECUTED - PARTIAL", "Tendered to Carrier"],
]);

const cancelledStatusMap = new Map([
  ["CANCELLED_CANCELLED", "Cancelled"]
])

export function isValidFloorLoad(loadMethod: string) {
  if (loadMethod == "FLOOR_LOAD") {
    return true;
  } else {
    return false;
  }
}

export function isInvalidMaxPickupNumber(pickupNumber: string) {
  return pickupNumber.length > 30;
}

export function isInvalidMaxWareHouseRemarks(wareHouseRemarks: string) {
  return wareHouseRemarks.length > 80;
}

export function getOrderStatusMessageFor(orderRelease:OrderRelease) {
  return cancelledStatusMap.get(orderRelease.cancelledStatus)??planningStatusMap.get(orderRelease.planningStatus);
}

export function getStatusFor(orderRelease:OrderRelease) {
  return (orderRelease.planningStatus === "PLANNING_EXECUTED - FINAL" ||
  orderRelease.planningStatus === "PLANNING_EXECUTED - PARTIAL") &&
    !orderRelease.shipmentId
    ? "Carrier Selection in Process"
    : getOrderStatusMessageFor(orderRelease);
}