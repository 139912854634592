export default class SearchOrderRelease {
  orderReleaseGid: string;
  earlyPickupDate: Date | null;
  earlyPickupDateBefore: Date | null;
  latePickupDate: Date | null;
  latePickupDateBefore: Date | null;
  shipTo: string;
  freightTerms: string;
  shipmentId: string;
  poCreationDate: Date | null;
  poCreationDateBefore: Date | null;
  earlyDeliveryDate: Date | null;
  earlyDeliveryDateBefore: Date | null;
  lateDeliveryDate: Date | null;
  lateDeliveryDateBefore: Date | null;
  appointmentId: string;

  constructor(
    orderReleaseGid: string,
    earlyPickupDate: Date | null,
    earlyPickupDateBefore: Date | null,
    latePickupDate: Date | null,
    latePickupDateBefore: Date | null,
    shipTo: string,
    freightTerms: string,
    shipmentId: string,
    poCreationDate: Date | null,
    poCreationDateBefore: Date | null,
    earlyDeliveryDate: Date | null,
    earlyDeliveryDateBefore: Date | null,
    lateDeliveryDate: Date | null,
    lateDeliveryDateBefore: Date | null,
    appointmentId: string
  ) {
    this.orderReleaseGid = orderReleaseGid;
    this.earlyPickupDate = earlyPickupDate;
    this.earlyPickupDateBefore = earlyPickupDateBefore;
    this.latePickupDate = latePickupDate;
    this.latePickupDateBefore = latePickupDateBefore;
    this.shipTo = shipTo;
    this.freightTerms = freightTerms;
    this.shipmentId = shipmentId;
    this.poCreationDate = poCreationDate;
    this.poCreationDateBefore = poCreationDateBefore;
    this.earlyDeliveryDate = earlyDeliveryDate;
    this.earlyDeliveryDateBefore = earlyDeliveryDateBefore;
    this.lateDeliveryDate = lateDeliveryDate;
    this.lateDeliveryDateBefore = lateDeliveryDateBefore;
    this.appointmentId = appointmentId;
  }
}
