import React from "react";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import HomePageLogo from "../../images/HomePage.jpeg";
import planningOne from "../../images/image001.jpeg";
import planningTwo from "../../images/image002.jpeg";
import planningThree from "../../images/image003.jpeg";
import planningFour from "../../images/image004.jpeg";
import ReleaseNotes from "../../images/release_notes.png";

const NavBarHomePage: React.FC = () => {
  return (
    <>
      <body>
        <div id="navBarHomePage" className="pageContent-style">
          <section className="page-section clearfix">
            <div className="container">
              <div className="intro">
                <img
                  className="intro-img img-fluid mb-3 mb-lg-0 rounded"
                  src={HomePageLogo}
                  alt="..."
                />
                <div className="intro-text left-0 text-center bg-faded p-5 rounded">
                  <h2 className="section-heading mb-4">
                    <span className="section-heading-lower">
                      WELCOME TO CVP
                    </span>
                  </h2>
                  <p className="mb-3">
                    Domestic Business Partners, welcome to our Custom Vendor
                    Portal (CVP), a critical component to the Purchase Order
                    fulfillment process for Dollar Tree & Family Dollar. Your
                    timely, accurate and complete entry of the required
                    information as you create order releases is the foundation
                    of an efficient supply chain process to get your products to
                    market. The CVP toolset, supporting guides and tutorials
                    will enable you to successfully complete all needed
                    information. Your compliance is essential and appreciated.
                  </p>
                  <div className="intro-button mx-auto">
                    <a className="btn btn-primary btn-xl" href="#">
                      <Link
                        to="/tour"
                        id="tour"
                        className="nav-link"
                        role="button"
                      >
                        Take a tour!
                      </Link>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="page-section cta">
            <div className="container">
              <div className="row">
                <div className="top-content">
                  <div className="container-fluid">
                    <div
                      id="demo"
                      className="carousel slide carousel-fade"
                      data-ride="carousel"
                    >
                      <div className="container carousel-inner no-padding">
                        <Carousel nextLabel="" prevLabel="">
                          <Carousel.Item className="carousel-item">
                            <div className="col-xs-3 col-sm-3 col-md-3">
                              <img src={planningOne} alt="..." />
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3">
                              <img src={planningTwo} alt="..." />
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3">
                              <img src={planningThree} alt="..." />
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3">
                              <img src={planningFour} alt="..." />
                            </div>
                          </Carousel.Item>
                          <Carousel.Item className="carousel-item ">
                            <div className="col-xs-3 col-sm-3 col-md-3">
                              <img src={planningTwo} alt="..." />
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3">
                              <img src={planningThree} alt="..." />
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3">
                              <img src={planningFour} alt="..." />
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3">
                              <img src={planningOne} alt="..." />
                            </div>
                          </Carousel.Item>
                          <Carousel.Item className="carousel-item ">
                            <div className="col-xs-3 col-sm-3 col-md-3">
                              <img src={planningThree} alt="..." />
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3">
                              <img src={planningFour} alt="..." />
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3">
                              <img src={planningOne} alt="..." />
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3">
                              <img src={planningTwo} alt="..." />
                            </div>
                          </Carousel.Item>
                          <Carousel.Item className="carousel-item">
                            <div className="col-xs-3 col-sm-3 col-md-3">
                              <img src={planningFour} alt="..." />
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3">
                              <img src={planningOne} alt="..." />
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3">
                              <img src={planningTwo} alt="..." />
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3">
                              <img src={planningThree} alt="..." />
                            </div>
                          </Carousel.Item>
                        </Carousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </body>
    </>
  );
};

export default NavBarHomePage;
