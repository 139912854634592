import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Checkbox, FormControlLabel, Link } from "@mui/material";
import TextField from "@mui/material/TextField";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { getJsonResult, post } from "../../services/FetchServices";
import PurchaseOrderRelease from "../../types/PurchaseOrderRelease";
import SearchPurchaseOrderRelease from "../../types/SearchPurchaseOrderRelease";
import { DropDown } from "../dropDown/DropDown";

interface CreatePurchaseOrderProps {
  searchPurchaseOrder: SearchPurchaseOrderRelease;
}

const SearchPurchaseOrderForOrderReleasePage: React.FC = () => {
  const location = useLocation();
  let searchPurchaseOrder = (location?.state as CreatePurchaseOrderProps)
    ?.searchPurchaseOrder;
  const [purchaseOrderId, setPurchaseOrderId] = useState("");
  const [earlyPickupOnOrAfter, setEarlyPickupOnOrAfter] = useState<Date | null>(
    null
  );
  const [latePickupOnOrAfter, setLatePickupOnOrAfter] = useState<Date | null>(
    null
  );
  const [earlyDeliveryOnOrAfter, setEarlyDeliveryOnOrAfter] =
    useState<Date | null>(null);
  const [lateDeliveryOnOrAfter, setLateDeliveryOnOrAfter] =
    useState<Date | null>(null);
  const [poCreationOnOrAfter, setPoCreationOnOrAfter] = useState<Date | null>(
    null
  );
  const [earlyPickupOnOrBefore, setEarlyPickupOnOrBefore] =
    useState<Date | null>(null);
  const [latePickupOnOrBefore, setLatePickupOnOrBefore] = useState<Date | null>(
    null
  );
  const [earlyDeliveryOnOrBefore, setEarlyDeliveryOnOrBefore] =
    useState<Date | null>(null);
  const [lateDeliveryOnOrBefore, setLateDeliveryOnOrBefore] =
    useState<Date | null>(null);
  const [poCreationOnOrBefore, setPoCreationOnOrBefore] = useState<Date | null>(
    null
  );
  const [destinationDc, setDestinationDc] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [checkedExculdeCanPo, setCheckedExculdeCanPo] = useState(true);
  const [checkedExcludePoNoQty, setCheckedExcludePoNoQty] = useState(true);
  const [purchaseOrderReleases, setPurchaseOrderReleases] = useState<
    PurchaseOrderRelease[]
  >([]);
  const [open, setOpen] = useState(false);
  const [openAlert, setAlertOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");
  const [cityValues, setCityValues] = useState([]);
  const handleClickToOpen = () => {
    setOpen(true);
  };
  const handleToClose = () => {
    setOpen(false);
  };
  const closeAlertDialog = () => {
    setAlertOpen(false);
  };

  var authHeader = localStorage.getItem("authHeader");
  var username = localStorage.getItem("userName");
  const convertDate = (dateTime: Date | null) => {
    let covertedDate = null;
    if (dateTime !== null) {
      let date = ("0" + dateTime.getDate()).slice(-2);
      let month = ("0" + (dateTime.getMonth() + 1)).slice(-2);
      let year = dateTime.getFullYear();
      let hours = dateTime.getHours();
      let minutes = dateTime.getMinutes();
      let seconds = dateTime.getSeconds();
      covertedDate =
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;
    }
    return covertedDate;
  };

  const findPurchaseOrderRelease = () => {
    setIsLoading(true);

    var findPurchaseOrderReleaseUrl =
      `${process.env.REACT_APP_BACKEND_URL}/po/findPurchaseOrders?authHeader=` +
      authHeader +
      "&vendorId=" +
      localStorage.getItem("vendorId");
    post(findPurchaseOrderReleaseUrl, searchPoJson)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          return response.json();
        } else {
          throw response;
        }
      })
      .then((data) => {
        if (data == null || data.length === 0) {
          setAlertOpen(true);
          setDialogContent("No results returned from the search criteria.");
          setDialogTitle("Warning!");
        }
        setPurchaseOrderReleases(data);
        setIsLoading(false);
        makeScrollableTable("#purchaseOrderReleaseTable", 650);
      })
      .catch((error) => {
        error.text().then((errMessage: any) => {
          if (errMessage.indexOf("500") > -1) {
            setAlertOpen(true);
            setDialogContent("You can not view this Purchase Order.");
            setDialogTitle("Error!");
            setIsLoading(false);
          }
        });
      });
  };

  let searchPoJson = {
    purchaseOrderXId: purchaseOrderId,
    earlyPickupDate: convertDate(earlyPickupOnOrAfter),
    latePickupDate: convertDate(latePickupOnOrAfter),
    poCreationDate: convertDate(poCreationOnOrAfter),
    earlyDeliveryDate: convertDate(earlyDeliveryOnOrAfter),
    lateDeliveryDate: convertDate(lateDeliveryOnOrAfter),
    earlyPickupDateBefore: convertDate(earlyPickupOnOrBefore),
    latePickupDateBefore: convertDate(latePickupOnOrBefore),
    poCreationDateBefore: convertDate(poCreationOnOrBefore),
    earlyDeliveryDateBefore: convertDate(earlyDeliveryOnOrBefore),
    lateDeliveryDateBefore: convertDate(lateDeliveryOnOrBefore),
    shipTo: destinationDc.split(" ")[0],
    excludeCancelPo: checkedExculdeCanPo,
    excludePoNoQuantity: checkedExcludePoNoQty,
  };

  var getDcCityValuesUrl =
    `${process.env.REACT_APP_BACKEND_URL}/po/dcCity?authHeader=` + authHeader;
  useEffect(() => {
    getJsonResult(getDcCityValuesUrl)
      .then((response) => response.json())
      .then((data) => setCityValues(data));

    if (searchPurchaseOrder !== undefined) {
      searchPoJson = {
        purchaseOrderXId: searchPurchaseOrder.purchaseOrderXId,
        earlyPickupDate: convertDate(searchPurchaseOrder.earlyPickupDate),
        latePickupDate: convertDate(searchPurchaseOrder.latePickupDate),
        poCreationDate: convertDate(searchPurchaseOrder.poCreationDate),
        earlyDeliveryDate: convertDate(searchPurchaseOrder.earlyDeliveryDate),
        lateDeliveryDate: convertDate(searchPurchaseOrder.lateDeliveryDate),
        earlyPickupDateBefore: convertDate(
          searchPurchaseOrder.earlyPickupDateBefore
        ),
        latePickupDateBefore: convertDate(
          searchPurchaseOrder.latePickupDateBefore
        ),
        poCreationDateBefore: convertDate(
          searchPurchaseOrder.poCreationDateBefore
        ),
        earlyDeliveryDateBefore: convertDate(
          searchPurchaseOrder.earlyDeliveryDateBefore
        ),
        lateDeliveryDateBefore: convertDate(
          searchPurchaseOrder.lateDeliveryDateBefore
        ),
        shipTo: searchPurchaseOrder.destinationDc.split(" ")[0],
        excludeCancelPo: searchPurchaseOrder.checkedExculdeCanPo,
        excludePoNoQuantity: searchPurchaseOrder.checkedExcludePoNoQty,
      };

      setPurchaseOrderId(searchPurchaseOrder.purchaseOrderXId);
      setDestinationDc(searchPurchaseOrder.destinationDc);
      setPoCreationOnOrBefore(searchPurchaseOrder.poCreationDateBefore);
      setPoCreationOnOrAfter(searchPurchaseOrder.poCreationDate);
      setLatePickupOnOrBefore(searchPurchaseOrder.latePickupDateBefore);
      setLatePickupOnOrAfter(searchPurchaseOrder.latePickupDate);
      setLateDeliveryOnOrBefore(searchPurchaseOrder.lateDeliveryDateBefore);
      setLateDeliveryOnOrAfter(searchPurchaseOrder.lateDeliveryDate);
      setEarlyPickupOnOrBefore(searchPurchaseOrder.earlyPickupDateBefore);
      setEarlyPickupOnOrAfter(searchPurchaseOrder.earlyPickupDate);
      setEarlyDeliveryOnOrBefore(searchPurchaseOrder.earlyDeliveryDateBefore);
      setEarlyDeliveryOnOrAfter(searchPurchaseOrder.earlyDeliveryDate);
      setCheckedExculdeCanPo(searchPurchaseOrder.checkedExculdeCanPo);
      setCheckedExcludePoNoQty(searchPurchaseOrder.checkedExcludePoNoQty);

      findPurchaseOrderRelease();
    }
  }, []);

  const history = useHistory();

  const handlePurchaseOrderId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPurchaseOrderId(e.target.value);
  };

  const handleDestinationDc = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDestinationDc(e.target.value);
  };

  const handleChangeExcludeCanPo = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckedExculdeCanPo(event.target.checked);
  };

  const handleChangeExcludePoNoQty = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckedExcludePoNoQty(event.target.checked);
  };

  const getLockedUnlockedStatus = (purchaseOrderRelease: PurchaseOrderRelease) => {//earlyShipDate: string, lockedUnlockedStatus: string, freightTerms: string, leadTimeInDays: number, attributeNumber1: number) => {
    if(purchaseOrderRelease.lockedUnlockedStatus !== "LOCKED" && purchaseOrderRelease.freightTerms.split(".")[1].toLowerCase()==="collect") {
      var earlyPickupDateTemp = new Date(purchaseOrderRelease.earlyPickupDateMax);
      var attributeNumber3 = 0;
      if(purchaseOrderRelease.attributeNumber3){
        attributeNumber3 = purchaseOrderRelease.attributeNumber3;
      }

      if(purchaseOrderRelease.attributeNumber1 && purchaseOrderRelease.attributeNumber1 !== 0){
        earlyPickupDateTemp.setDate(earlyPickupDateTemp.getDate() + Math.abs(purchaseOrderRelease.attributeNumber1) - attributeNumber3);
      } else if(purchaseOrderRelease.leadTimeInDays && purchaseOrderRelease.leadTimeInDays !== 0){
        earlyPickupDateTemp.setDate(earlyPickupDateTemp.getDate() + Math.abs(purchaseOrderRelease.leadTimeInDays) - attributeNumber3);
      } else{
        earlyPickupDateTemp.setDate(earlyPickupDateTemp.getDate() - attributeNumber3);
      }

      if (new Date() > earlyPickupDateTemp){
        return "LOCKED";
      } else {
        return "UNLOCKED";
      }
    } else {
      if (purchaseOrderRelease.lockedUnlockedStatus === "NOT LOCKED"){
        return "UNLOCKED";
      }else{
        return purchaseOrderRelease.lockedUnlockedStatus;
      }
    }
  }
  
  const getLockedReason = (purchaseOrderRelease: PurchaseOrderRelease) => {
    const lockedStatus = purchaseOrderRelease.poStatus;
    const reason = new Map(
    [
      ["LOCKED", "The PO dates exceed the time range established for allowing order release"],
      ["COMPLETED", "No remaining line item quantities remain for release"],
      ["CANCELED", "CANCELED PO"],
      ["UNLOCKED", ""]
    ]);
    return lockedStatus!=="Active"? reason.get(lockedStatus) : reason.get(getLockedUnlockedStatus(purchaseOrderRelease));
  }

  const routeToPurchaseOrderManager = (
    purchaseOrderXId: string,
    lockedUnlockedStatus: string
  ) => {
    if (lockedUnlockedStatus === "LOCKED") {
      handleClickToOpen();
    } else {
      var orderReleaseUrl =
        `${process.env.REACT_APP_BACKEND_URL}/po/getPurchaseOrder?authHeader=` +
        authHeader +
        "&orderBaseGid=" +
        purchaseOrderXId +
        "&vendorId=" +
        localStorage.getItem("vendorId");
      searchPurchaseOrder = new SearchPurchaseOrderRelease(
        purchaseOrderId,
        destinationDc,
        earlyPickupOnOrAfter,
        earlyPickupOnOrBefore,
        earlyDeliveryOnOrAfter,
        earlyDeliveryOnOrBefore,
        latePickupOnOrAfter,
        latePickupOnOrBefore,
        lateDeliveryOnOrAfter,
        lateDeliveryOnOrBefore,
        poCreationOnOrAfter,
        poCreationOnOrBefore,
        checkedExculdeCanPo,
        checkedExcludePoNoQty
      );

      getJsonResult(orderReleaseUrl)
        .then((response) => response.json())
        .then((data: PurchaseOrderRelease) => {
          history.push({
            pathname: "/purchaseOrderRelease",
            state: {
              purchaseOrderRelease: data,
              searchPurchaseOrder: searchPurchaseOrder,
            },
          });
        });
    }
  };

  const onKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      findPurchaseOrderRelease();
      event.preventDefault();
    }
  };

  const makeScrollableTable = function (
    tableSelector: string,
    tbodyHeight: number
  ) {
    let $table = $(tableSelector);
    let $bodyCells = $table.find("tbody tr:first").children();
    let $headCells = $table.find("thead tr:first").children();

    let headColWidth = $headCells
      .map(function () {
        return $(this).outerWidth();
      })
      .get();
    let bodyColWidth = $bodyCells
      .map(function () {
        return $(this).outerWidth();
      })
      .get();

    $table
      .find("thead tr")
      .children()
      .each(function (i, v) {
        $(v).css("width", headColWidth[i] + "px");
        $(v).css("min-width", headColWidth[i] + "px");
        $(v).css("max-width", headColWidth[i] + "px");
      });
    $table
      .find("tbody tr")
      .children()
      .each(function (i, v) {
        $(v).css("width", bodyColWidth[i] + "px");
        $(v).css("min-width", bodyColWidth[i] + "px");
        $(v).css("max-width", bodyColWidth[i] + "px");
      });

    $table.find("thead").css("display", "block");
    $table.find("tbody").css("display", "block");

    $table.find("tbody").css("height", tbodyHeight + "px");
    $table.find("tbody").css("overflow-y", "auto");
    $table.find("tbody").css("overflow-x", "hidden");
  };

  return (
    <section
      className="sectionHeadingPadding"
      onKeyDown={onKeyDown}
      tabIndex={-1}
      style={{ outline: "none" }}
    >
      <div id="searchPurchaseOrderReleasePage" className="container">
        <div id="searchPurchOrderReleaseIntro" className="intro">
          <div
            id="findPurchaseOrderReleaseHeader"
            className="section-heading mb-4"
          >
            <span
              className="section-heading-lower"
              style={{
                fontSize: "1.9rem",
                color: "#6b3a06",
                textAlign: "center",
              }}
            >
              Purchase Order Finder
            </span>
          </div>
          <div className="row mb-5">
            <div className="col-lg-11 mx-auto">
              <div
                className="bg-grey p-5 rounded "
                style={{ boxShadow: " 0px 5px 10px #91d475" }}
              >
                <div className="row">
                  <div className="col-md">
                    <div className="form-group py-2">
                      <TextField
                        id="purchaseOrderId"
                        label="Purchase Order ID"
                        variant="outlined"
                        className="form-control"
                        value={purchaseOrderId}
                        onChange={handlePurchaseOrderId}
                      />
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-group py-2">
                      <DropDown
                        codes={cityValues}
                        elementId="destinationDc"
                        value={destinationDc}
                        onChange={handleDestinationDc}
                        label="DC# CITY STATE"
                        defaultOption="ALL"
                      />
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-group py-2">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          disableFuture
                          label="PO Creation On or After"
                          views={["year", "month", "day"]}
                          value={poCreationOnOrAfter}
                          onChange={(newValue) => {
                            setPoCreationOnOrAfter(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id="poCreationOnOrAfter"
                              className="form-control"
                              helperText={null}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-group py-2">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          disableFuture
                          label="PO Creation On or Before"
                          views={["year", "month", "day"]}
                          value={poCreationOnOrBefore}
                          onChange={(newValue : any) => {
                            setPoCreationOnOrBefore(newValue);
                          }}
                          renderInput={(params : any) => (
                            <TextField
                              {...params}
                              id="poCreationOnOrBefore"
                              className="form-control"
                              helperText={null}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md">
                    <div className="form-group py-2">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Early Pickup On or After"
                          views={["year", "month", "day"]}
                          value={earlyPickupOnOrAfter}
                          onChange={(newValue : any) => {
                            setEarlyPickupOnOrAfter(newValue);
                          }}
                          renderInput={(params : any) => (
                            <TextField
                              id="earlyPickupOnOrAfter"
                              className="form-control"
                              {...params}
                              helperText={null}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-group py-2">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Early Pickup On or Before"
                          views={["year", "month", "day"]}
                          value={earlyPickupOnOrBefore}
                          onChange={(newValue : any) => {
                            setEarlyPickupOnOrBefore(newValue);
                          }}
                          renderInput={(params : any) => (
                            <TextField
                              id="earlyPickupOnOrBefore"
                              className="form-control"
                              {...params}
                              helperText={null}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-group py-2">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Late Pickup On or After"
                          views={["year", "month", "day"]}
                          value={latePickupOnOrAfter}
                          onChange={(newValue : any) => {
                            setLatePickupOnOrAfter(newValue);
                          }}
                          renderInput={(params : any) => (
                            <TextField
                              {...params}
                              id="latePickupOnOrAfter"
                              className="form-control"
                              helperText={null}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-group py-2">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Late Pickup On or Before"
                          views={["year", "month", "day"]}
                          value={latePickupOnOrBefore}
                          onChange={(newValue : any) => {
                            setLatePickupOnOrBefore(newValue);
                          }}
                          renderInput={(params : any) => (
                            <TextField
                              {...params}
                              id="latePickupOnOrBefore"
                              className="form-control"
                              helperText={null}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md">
                    <div className="form-group py-2">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Early Delivery On or After"
                          value={earlyDeliveryOnOrAfter}
                          views={["year", "month", "day"]}
                          onChange={(newValue : any) => {
                            setEarlyDeliveryOnOrAfter(newValue);
                          }}
                          renderInput={(params : any) => (
                            <TextField
                              {...params}
                              id="earlyDeliveryOnOrAfter"
                              className="form-control"
                              helperText={null}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-group py-2">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Early Delivery On or Before"
                          value={earlyDeliveryOnOrBefore}
                          views={["year", "month", "day"]}
                          onChange={(newValue : any) => {
                            setEarlyDeliveryOnOrBefore(newValue);
                          }}
                          renderInput={(params : any) => (
                            <TextField
                              {...params}
                              id="earlyDeliveryOnOrBefore"
                              className="form-control"
                              helperText={null}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-group py-2">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Late Delivery On or After"
                          value={lateDeliveryOnOrAfter}
                          views={["year", "month", "day"]}
                          onChange={(newValue : any) => {
                            setLateDeliveryOnOrAfter(newValue);
                          }}
                          renderInput={(params : any) => (
                            <TextField
                              {...params}
                              id="lateDeliveryOnOrAfter"
                              className="form-control"
                              helperText={null}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-group py-2">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Late Delivery On or Before"
                          value={lateDeliveryOnOrBefore}
                          views={["year", "month", "day"]}
                          onChange={(newValue : any) => {
                            setLateDeliveryOnOrBefore(newValue);
                          }}
                          renderInput={(params : any) => (
                            <TextField
                              {...params}
                              id="lateDeliveryOnOrBefore"
                              className="form-control"
                              helperText={null}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
                <div className="row"></div>
                <div className="row">
                  <div className="col-md">
                    <div className="input-group">
                      <FormControlLabel
                        label="Exclude PO's with No Remaining Quantity to Release"
                        id="checkedExcludePoNoQtyId"
                        control={
                          <Checkbox
                            checked={checkedExcludePoNoQty}
                            onChange={handleChangeExcludePoNoQty}
                            inputProps={{ "aria-label": "controlled" }}
                            id="checkedExcludePoNoQtyId"
                          />
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="input-group">
                      <FormControlLabel
                        label="Exclude Canceled POs"
                        id="checkedExcludeCanPoId"
                        control={
                          <Checkbox
                            checked={checkedExculdeCanPo}
                            onChange={handleChangeExcludeCanPo}
                            inputProps={{ "aria-label": "controlled" }}
                            id="checkedExcludeCanPoId"
                          />
                        }
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div style={{ textAlign: "center", paddingTop: "40px" }}>
                    <span>
                      <button
                        type="button"
                        className="btn btn-success"
                        id="searchPurchaseOrderReleaseButton"
                        onClick={findPurchaseOrderRelease}
                        style={{
                          width: 100,
                          marginBottom: "10px",
                        }}
                      >
                        Find
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {open ? (
            <Dialog open={open} onClose={handleToClose} id="lockedDialog">
              <DialogContent>
                <DialogContentText>
                  The Purchase Order you are about to view is locked. You can no
                  longer create an Order Release for this Purchase Order because
                  pickup dates are in the past or no quantity remains for
                  release. Please contact your merchant.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleToClose} color="primary" autoFocus>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          ) : (
            <React.Fragment />
          )}
        </div>
        <div id="purchaseOrderReleaseDiv">
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <div
                className="spinner-border text-primary"
                id="purchaseOrderSpinner"
                role="status"
              ></div>
            </div>
          ) : (
            <table className="table" id="purchaseOrderReleaseTable">
              <thead>
                <tr>
                  <th>Purchase Order ID</th>
                  <th>Ship To</th>
                  <th>Remaining Qty</th>
                  <th>Locked/Unlocked Status</th>
                  <th>Locked Reason</th>
                  <th>Early Ship Date</th>
                  <th>Late Ship Date</th>
                  <th>PO Status</th>
                </tr>
              </thead>
              <tbody>
                {purchaseOrderReleases.map(
                  (purchaseOrderRelease: PurchaseOrderRelease) => (
                    <tr>
                      <td>
                        <Link
                          onClick={() =>
                            routeToPurchaseOrderManager(
                              purchaseOrderRelease.purchaseOrderXId,
                              getLockedUnlockedStatus(purchaseOrderRelease)
                            )
                          }
                        >
                          {purchaseOrderRelease.purchaseOrderXId.startsWith(
                            "FD."
                          )
                            ? purchaseOrderRelease.purchaseOrderXId.slice(3)
                            : purchaseOrderRelease.purchaseOrderXId}
                        </Link>
                      </td>
                      <td>{purchaseOrderRelease.shipTo}</td>
                      <td>
                        {purchaseOrderRelease.remainingQty
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </td>
                      <td>{getLockedUnlockedStatus(purchaseOrderRelease)}</td>
                      <td>{getLockedReason(purchaseOrderRelease)}</td>
                      <td>{purchaseOrderRelease.earlyShipDate}</td>
                      <td>{purchaseOrderRelease.lateShipDate}</td>
                      <td>{purchaseOrderRelease.poStatus}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div id="alertDialog">
        <Dialog open={openAlert}>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>{dialogContent}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              id="Button"
              onClick={closeAlertDialog}
              color="primary"
              autoFocus
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </section>
  );
};

export default SearchPurchaseOrderForOrderReleasePage;
