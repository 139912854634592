import React from "react";

interface IProps {
  validatedAddress: string;
  togglingPrompt(): void;
  onSelectedAddress(): void;
}

const AddressPrompt = (props: IProps) => {
  const modalWidth = { maxWidth: "70%" };

  return (
    <React.Fragment>
      <div className="modal-relative d-block" id="addressPromptModal">
        <div
          className="modal-dialog modal-lg"
          style={modalWidth}
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                id="addressPromptClose"
                onClick={props.togglingPrompt}
              ></button>
            </div>
            <div className="modal-body">
              <div className="container">
                <div className="row">
                  <div className="col">
                    <div className="list-group">
                      <button
                        type="button"
                        id="suggestedAddress"
                        className="list-group-item list-group-item-action"
                        onClick={props.onSelectedAddress}
                      >
                        {props.validatedAddress}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddressPrompt;
