import React from "react";

const Footer: React.FC = () => {
  return (
    <>
      <footer className="footer text-faded text-center py-5">
        <div className="container" id="footer">
          <p className="small">Copyright &copy; 2022 Dollar Tree, Inc.</p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
