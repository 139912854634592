import AdditionalContactDetails from "../types/AdditionalContactDetails";

export const emailregex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;

export function isValidName(name: string): boolean {
    return name !== "";
}

export function isValidEmail(email: string): boolean {
    return email.trim().length > 0 && emailregex.test(email);
}

export function isValidPhone(phoneNumber: string): boolean {
    return phoneNumber.trim().length === 10;
}

export function hasInvalidName(
    contactDetails: AdditionalContactDetails
): boolean {
    return !isValidName(contactDetails.contactName);
}

export function hasInvalidEmail(
    contactDetails: AdditionalContactDetails
): boolean {
    return !isValidEmail(contactDetails.emailAddress);
}

export function hasInvalidPhone(
    contactDetails: AdditionalContactDetails
): boolean {
    return !isValidPhone(contactDetails.phone1);
}

export function hasValidFieldsInAdditionalContacts(
    additionalContacts: Array<AdditionalContactDetails>
): boolean {
    return !hasInvalidFieldsInAdditionalContacts(additionalContacts);
}

export function hasInvalidFieldsInAdditionalContacts(
    additionalContacts: Array<AdditionalContactDetails>
): boolean {
    return additionalContacts
        .filter((additionalContact => additionalContact.operation !== "CD"))
        .some((additionalContact) =>
            !isValidName(additionalContact.contactName) ||
            !isValidEmail(additionalContact.emailAddress) ||
            !isValidPhone(additionalContact.phone1)
    ) || hasTooManyActiveWarehouseContacts(additionalContacts);
}

export function isActiveWarehouseContact( additionalContact: AdditionalContactDetails ) {
    return additionalContact.jobTitle === "WAREHOUSE" && additionalContact.active;
}
export function hasTooManyActiveWarehouseContacts( additionalContacts: Array<AdditionalContactDetails>) :boolean {
    return additionalContacts
        .filter((additionalContact => additionalContact.operation !== "CD"))
        .filter(isActiveWarehouseContact).length > 3;
}